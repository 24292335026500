import { Component } from "@angular/core";
import { AlertController, LoadingController, ModalController } from "@ionic/angular";
import { AppConfiguration } from "src/app/app.configuration";
import { Capacitor } from "@capacitor/core";
import { BonusProvider } from "src/app/services/bonus/bonus";
import { ContextProvider } from "src/app/services/context/context";
import { LanguageProvider } from "src/app/services/language/language.provider";
import { PaymentProvider } from "src/app/services/payment/payment";
import { UserProvider, UserPaymentSource, User } from "src/app/services/user/user";
import { CurrencyPipe } from "src/app/pipes/currency.pipe";

@Component({
    selector: "app-select-payment-option",
    templateUrl: "selectPaymentOption.component.html",
    styleUrls: ["selectPaymentOption.component.scss"]
})
export class SelectPaymentOptionComponent {
    paymentOptions: any = [];
    contextPaymentOptions = {
        swish: { enabled: false },
        card: { enabled: true },
        customerTab: { enabled: false },
        vipps: { enabled: false },
        invoice: {
            enabled: false,
            allowUserToAddOrg: false
        },
        mobileWallet: {
            enabled: false,
            allowUserToAddMoney: false,
            allowUserToAddAnyAmount: false,
            amounts: [],
            minimumAmount: 0,
            voucher: []
        }
    };
    user: User;
    selectedPaymentOption: any;
    constructor(private modalController: ModalController, private paymentService: PaymentProvider, private userService: UserProvider,
        private contextService: ContextProvider, public languageService: LanguageProvider, private loadingController: LoadingController) {

    }
    async ngOnInit() {
        this.user = await this.userService.currentUser.value;
        this.addPaymentOptions(this.user);
    }
    select(paymentMethod) {
        this.modalController.dismiss(paymentMethod);
    }
    async addPaymentOptions(user: User) {
        let loading = await this.loadingController.create({
            spinner: "circular",
            duration: 15000,
        });
        loading.present();
        this.paymentService.paymentProvider = AppConfiguration.paymentProvider;
        const contextOptions = await this.paymentService.getPaymentOptions();
        let paymentOptions = [];
        const routeName = this.contextService.getClient().routeName;
        paymentOptions = await this.addMobilePay(paymentOptions, contextOptions);
        paymentOptions = await this.addApplePay(paymentOptions, contextOptions);
        paymentOptions = await this.addGooglePay(paymentOptions, contextOptions);
        paymentOptions = await this.addSwish(paymentOptions, contextOptions);
        paymentOptions = await this.addVipps(paymentOptions, contextOptions);
        paymentOptions = await this.addCardPayment(paymentOptions, user, contextOptions);

        this.paymentOptions = paymentOptions;
        loading.dismiss();
    }
    async addMobilePay(paymentOptions: any[], contextOptions) {
        if (contextOptions.mobilepay && contextOptions.mobilepay.enabled) {
            let data = {
                name: "Mobile Pay", icon: "assets/icons/mobilepay.svg", id: "mobilepay",
                data: {
                    type: 'newcard',
                    nickname: 'Mobile Pay',
                    recurringReference: "mobilepay"
                }
            }
            paymentOptions.push(data);

        }
        return paymentOptions;
    }
    async addApplePay(paymentOptions: any[], contextOptions) {
        let platform = Capacitor.getPlatform();

        if (platform === "ios" && AppConfiguration.paymentProvider === "reepay" && contextOptions.card && contextOptions.card.enabled) {
            let data = {
                name: "Apple Pay", icon: "assets/icons/applepay.svg", id: "applepay",
                data: {
                    type: 'newcard',
                    nickname: 'Apple Pay',
                    recurringReference: "applepay"
                }
            }
            paymentOptions.push(data);
        }
        return paymentOptions;
    }
    async addGooglePay(paymentOptions: any[], contextOptions) {
        let platform = Capacitor.getPlatform();

        if (platform === "android" && contextOptions.card && contextOptions.card.enabled) {
            let data = {
                name: "Google Pay", icon: "assets/icons/googlepay.svg", id: "googlepay",
                data: {
                    type: 'newcard',
                    nickname: 'Google Pay',
                    recurringReference: "googlepay"
                }
            }
            paymentOptions.push(data);
        }
        return paymentOptions;
    }

    async addCardPayment(paymentOptions, user: User, contextOptions) {
        if (contextOptions.card && contextOptions.card.enabled) {
            let userPaymentSources = await this.userService.getUserPaymentSourcesV2Async(user.id);
            for (const key in userPaymentSources) {
                if (Object.prototype.hasOwnProperty.call(userPaymentSources, key)) {
                    const element = userPaymentSources[key];
                    let data = {
                        name: element.data.nickname,
                        icon: element.data.type === "visa" ? "assets/icons/visa.svg" : "assets/icons/genericcard.svg",
                        id: "card",
                        data: element.data
                    }
                    paymentOptions.push(data);
                }
            }
            var cardIntent: UserPaymentSource = {
                nickname: "newcard this should not be needed lets remove this in the future, ok?",
                recurringReference: null,
                type: "newcard",
            };
            let data = {
                name: this.languageService.getString("PAYMENT_ADD_CARD"),
                icon: "assets/icons/genericcard.svg",
                id: "card",
                data: cardIntent
            }
            paymentOptions.push(data);
        }
        return paymentOptions;
    }
    async addSwish(paymentOptions, contextOptions) {
        if (contextOptions.swish && contextOptions.swish.enabled) {
            var swishPaymentRef: UserPaymentSource = {
                nickname: "swish",
                recurringReference: null,
                type: "swish",
            };
            let data = {
                name: "Swish",
                icon: "assets/icons/swish.svg",
                id: "swish",
                data: swishPaymentRef
            }
            paymentOptions.push(data)
        }
        return paymentOptions;
    }
    async addVipps(paymentOptions, contextOptions) {
        if (contextOptions.vipps && contextOptions.vipps.enabled) {
            var vippsPaymentRef: UserPaymentSource = {
                nickname: "vipps",
                recurringReference: null,
                type: "vipps",
            };
            let data = {
                name: "Vipps",
                icon: "assets/icons/vipps2.svg",
                id: "vipps",
                data: vippsPaymentRef
            }
            paymentOptions.push(data);
        }
        return paymentOptions;
    }
}