export const lang = {
    get: (string, parameters?) => {
        switch (string) {
            case 'BONUS_USE': return `Utnyttja ${parameters[0]}​​​ bonuspoäng`;
            case 'STOCK_ALERT': return `Tyvärr saknades ${parameters[0]}​​​ i lager, din varukorg har justerats efter vad som fanns tillgängligt så du kan fortsätta handla.`;
            case 'BOOKING_NO_AVAILABLE_TIMES_1': return `Tyvärr finns det inga lediga tider för ${parameters[0]} personer`;
            case 'BOOKING_SMS_TEXT': return `Hej det gäller bokningen ${parameters[0]} \n kl. ${parameters[1]}\n ${parameters[2]} pers. `;
            case 'OFFERS_ACTIVATED': return `Aktiverade ${parameters[0]}`;
            case 'CART_ITEMS_REMOVE': return `Vill du ta bort  ${parameters[0]}?`;
            case 'CART_ALLERGY_CONTACT': return `, mellan kl ${parameters[0]}-${parameters[1]}, så svarar vi gärna på dina frågor om innehåll`;
            case 'EMAIL_CORRECT': return `Är ${parameters[0]} korrekt?`;
            case 'LOGIN_SEND_SMS': return `Skicka sms till ${parameters[0]}?`;
            case 'LOGIN_ERROR': return `Oj något gick fel. Vänligen testa ett annat sätt att logga in.\n ${parameters[0]}`;
            case 'OPEN_SWISH_APP_WITH_PHONENUMBER': return `Visas ingen betalning i Swish, kontrollera att det är rätt nummer (${parameters[0]}).`;
            case 'SINGLE_ORDER': return `Du har ${parameters[0]} pågående order`;
            case 'MULTIPLE_ORDER': return `Du har ${parameters[0]} pågående ordrar`;
        }
    },
    ABOUT_US: 'Om oss',
    ACCOUNT: 'Konto',
    ACCOUNT_REMOVE_CARD: 'Ta bort kort?',
    ACCOUNT_REMOVE_CARD_2: 'Vill du ta bort kortet ',
    ACTIVATE: 'Aktivera',
    ACTIVE_ORDER_CLOSE_WINDOW: "Detta fönster försvinner om du väljer 'Ja'",
    ACTIVE_ORDER_DEL_CUSTOMER: 'Din order är placerad i köket och du får en notifikation när ordern körs ut till dig',
    ACTIVE_ORDER_DRINK_READY: 'Din dryck är redo',
    ACTIVE_ORDER_EATIN_COMPLETE: 'Visa upp den här skärmen vid disken',
    ACTIVE_ORDER_FOOD_READY: 'Din mat är redo',
    ACTIVE_ORDER_HOME_DELIVERY_OTW: 'Den lämnar restaurangen nu och är påväg mot dig!',
    ACTIVE_ORDER_ONGOING: 'Pågående ordrar',
    ACTIVE_ORDER_READY: 'Din order är redo',
    ACTIVE_ORDER_RECIEVED_Q: 'Har du fått din order?',
    ACTIVE_ORDER_SHOW_SCREEN: 'Visa upp den här skärmen vid disken',
    ACTIVE_ORDER_TABLE: 'Tack! Vi är på gång med beställningen och kommer ut med den till dig så snart vi kan!',
    ACTIVE_ORDER_TABLE_DELIVERY_OTW: 'Den levereras strax till ditt bord',
    ACTIVE_ORDER_TAKEAWAY: 'Din order är placerad i köket och du får strax en uppskattad tid när du kan hämta upp den',
    ACTIVE_ORDER_TAKEAWAY_COMPLETE: 'Visa upp den här skärmen när du plockar upp din order',
    ACTIVE_ORDER_TICKET: 'Här är din biljett',
    ACTIVE_ORDER_WAITING: 'Tack! Din order är påbörjad och du får en notifikation så fort den är färdig.',
    ACCEPT_USER_TERMS: 'Jag godkänner användarvillkoren',
    ADD: 'Lägg till',
    ADD_CARD: 'Lägg till kort',
    ADD_CARD_ADDING: 'Betala med kort',
    ADD_CARD_ERROR_E1: 'Något gick fel. Prova igen!',
    ADD_CARD_ERROR_E10: 'Fel kortnummer. Dubbelkolla att du har slagit in rätt',
    ADD_CARD_ERROR_E11: 'Fel månad/år. Dubbelkolla att du har slagit in rätt',
    ADD_CARD_ERROR_E12: 'Fel namn på kort. Kontakta personalen',
    ADD_CARD_ERROR_E13: 'Restaurangen har fel inställningar. Kontakta personalen',
    ADD_CARD_ERROR_E14: 'Fel CVV-kod. Dubellkolla att du slagit in rätt',
    ADD_CARD_ERROR_E3: 'Restaurangen kan inte lägga till kort. Kontakta personal!',
    ADD_CARD_ERROR_E4: 'Invalid reference details',
    ADD_CARD_ERROR_E5: 'Fel korttyp!',
    ADD_CARD_ERROR_E6: 'Fel terminal-id! Kontakta personalen',
    ADD_CARD_ERROR_E7: 'Ej tillåtet! Kontakta personalen',
    ADD_CARD_ERROR_E8: 'Fel merchant-id! Kontakta personalen',
    ADD_CARD_ERROR_E9: 'Fel datumstämpel! Kontakta personalen',
    ADD_CARD_NEW_CARD: 'Nytt kort',
    ADD_CARD_NICK_MSG: 'Kortet krypteras på ett säkert sätt och sparas för framtida användning',
    ADD_CARD_NICK_PLACEHOLDER: 'Kortets smeknamn',
    ADD_CARD_NICK_TITLE: 'Vad vill du kalla ditt kort?',
    ADD_CARD_SECURITY_INFO: 'Vi behandlar din kortinformation på ett tryggt och säkert sätt. All känslig information sparas hos en certifierad betalväxel',
    ADD_ITEM_COMMENT: 'Lägg till en kommentar...',
    ADD_MORE_PRODUCTS: 'Lägg till fler produkter',
    ADD_TO_CART: 'Lägg till i varukorg',
    BAB_ADD: 'LÄGG TILL',
    BAB_BURGER_NAME: 'Vad ska din burgare heta?',
    BAB_DRESSING: 'Dressing',
    BAB_EXTRAS: 'Välj tillbehör',
    BAB_MANY: 'Välj så många du vill',
    BAB_NAME: 'Vad ska din rätt heta?',
    BAB_SIDEORDER: 'Välj side order',
    BAB_TOPPINGS: 'Toppings',
    BAB_TYPE_SIZE: 'Välj typ & storlek',
    BACK: 'Tillbaka',
    BONUS_POINTS: 'bonuspoäng',
    BONUSPOINTS: 'Bonuspoäng',
    BOOK: 'Boka',
    BOOKING_ALLOW_SMS: 'Tillåtelsen låter appen öppna ett förifyllt sms till restaurangen åt dig',
    BOOKING_APPCODE: 'Din appkod är',
    BOOKING_CANCEL: 'Är du säker på att du vill avboka?',
    BOOKING_CANCELED: 'Ditt bord har avbokats',
    BOOKING_CONFIRM: 'Bekräftelse',
    BOOKING_CONFIRM_CANCEL: 'Bekräfta avbokning',
    BOOKING_CONTACT_US_1: 'Kontakta oss på',
    BOOKING_CONTACT_US_2: 'eller tryck på knappen för att skicka ett SMS',
    BOOKING_ICONS: 'Beställ med hjälp av ikonerna nedan',
    BOOKING_LARGER_GROUPS: 'Bordsbokning större grupper',
    BOOKING_LARGER_GROUPS_CONTACT: 'Om du vill boka bord för fler personer vänligen hör av dig på',
    BOOKING_NO_AVAILABLE_TIMES_1: 'Inga lediga tider',
    BOOKING_NUMBER_GUESTS: 'Antal gäster',
    BOOKING_QUESTIONS: 'Har du frågor om bokningen?',
    BOOKING_THANKS_1: 'Tack för ditt besök!',
    BOOKING_THANKS_2: 'Vi ses snart igen',
    BOOKING_TITE: 'Boka bord',
    CANCEL: 'Avbryt',
    CANCEL_ORDER:'Avbryt beställning',
    CARD_NO: 'Kortnummer',
    CART: 'Varukorg',
    CART_ALLERGIC_CONTACT: 'Allergisk? Kontakta restaurangen på',
    CART_BOOK_TABLE: 'Boka bord',
    CART_CHECK_CARD: 'Se så ditt kort är öppet för internetköp och att tillräcklig summa finns tillgänglig',
    CART_CREATING_ORDER: 'Skapar order och lägger till på notan',
    CART_GO_TO_MENY: 'Gå till menyn och gör din första beställning',
    CART_ITEM_18Y: '18+ år och giltig identifikation krävs vid köp av alkohol',
    CART_ITEM_ALCOHOL: 'Alkohol rabatteras ej',
    CART_ITEM_COMMENT: 'Skriv din kommentar här',
    CART_ITEM_COMMENT_EXAMPLE: "T.ex 'ingen koriander'",
    CART_ITEM_OFFER_NOT_VALID: 'Rabattkoden är inte giltig',
    CART_ITEM_REMOVE_CODE: 'Ta bort koden?',
    CART_ITEMS_APPLYING_OFFER: 'Lägger på rabattkod',
    CART_MY_BOOKING: 'Min bokning',
    CART_NO_ACTIVE_TAB: 'Du har ingen aktiv bordsnota',
    CART_ORDER_TAKEWAY: 'Beställ takeaway',
    CART_PAYMENT_ABORTED: 'Betalningen avbröts',
    CART_PERSONNEL_CARD: 'Personalkort',
    CART_PREPARNG_SWISH: 'Förbereder Swish-betalning',
    CART_SERVE_NOW: "Servera direkt",
    CART_SOME_ERROR: 'Något gick fel',
    CART_WEEK_MENU: 'Veckans meny',
    CART_WELCOME_OPENING_HOURS: 'Öppettider & kontaktinformation',
    CART_WELCOME_READ: 'Läs mer',
    CHANGE_UNIT: 'Byt enhet',
    CHARGE_ERROR: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    CHARGE_SWISH_INIT: 'Frågar Swish om betalning',
    CHARGE_TAB_TOAST: 'Din nota är betald och ett kvitto har skapats under ditt konto',
    CHARGE_VERIFY: 'Verifierar och stämmer av',
    CHARGE_WAIT: 'Ett ögonblick ... ',
    CHOOSE_DELIVERY_OPTION: 'Välj leveranssätt',
    CHOOSE_RESTAURANT: 'Välj restaurang',
    CLIENT_OPEN_HOURS: 'Öppettider',
    CLOSE: "Stäng",
    CLOSED: 'Stängt',
    COMMENT: 'Kommentar',
    CONFIRM: 'Ok',
    CREATE_LOGIN: 'Skapa konto / logga in',
    CURRENT: 'Aktuellt',
    CUSTOM_TICKET_VALID: 'Giltigt till',
    CUSTOMER_CODE_TYPE_4_DIGITS: 'Skriv 4 siffror',
    CUSTOMER_TAB: 'Bordsnota',
    CUSTOMER_TAB_KEY_KOD: 'Bordskod',
    CUSTOMER_TAB_KEY_SAME_CODE: 'Samma kod igen? Tryck här',
    CUSTOMER_TAB_KEY_WRONG_CODE: 'Fel bordskod',
    CUSTOMER_TABS_ATTENTION_MSG: 'Vi kommer strax och hjälper dig',
    CUSTOMER_TABS_CALL_WAITER: 'Tillkalla kypare',
    CUSTOMER_TABS_CUSTOM_AM1: 'Valfri summa...',
    CUSTOMER_TABS_CUSTOM_AM2: 'Valfri summa',
    CUSTOMER_TABS_PAY_IN_APP: 'Betala i appen',
    CUSTOMER_TABS_TIPS: 'Vill du ge något extra till vår personal?',
    DATE: 'Datum',
    DATE_TIME: 'Datum & tid',
    DELIVERY_ALCOHOL_DELIVERY: 'Du kan inte beställa hem alkohol...',
    DELIVERY_ALCOHOL_TAKE_AWAY: 'Du kan inte beställa alkohol som take away',
    DELIVERY_FETCH_BAR: 'Hämta i baren',
    DELIVERY_HOME_DELIVERY: 'Utkörning',
    DELIVERY_MY_TABLE: 'Servera till mitt bord',
    DELIVERY_INFORMATION: 'Leveransinformation',
    DELIVERY_OFFER_ERROR: 'Rabattkoden är inte giltig för detta serveringssätt',
    DELIVERY_QUESTION: 'Hur vill du få din beställning?',
    DELIVERY_SERVING: 'Servering',
    DELIVERY_OPTION: 'Leveranssätt',
    DELIVERY_TO_GO: 'Ta med',
    DELIVERY_CHANGE_OPTION: 'Byt leveranssätt',
    DISCOUNT: 'Rabatt',
    DONE: 'Klar',
    EMAIL: 'Email',
    EMAIL_FOLLOW_INSTRUCTIONS: 'Öppna din inkorg och följ instruktionerna',
    EMAIL_SENT: 'Mail skickat',
    ENTER_CARD_NUMBER_AT_START_OF_PAYMENT: 'Kortnummer fylls i vid start av betalning.',
    ERROR: 'Fel',
    FORWARD: 'Vidare',
    FREE_CHARGE: 'Ingen betalning behöver genomföras för detta köp.',
    FRI: 'Fre',
    FRIDAY: 'Fredag',
    GO_TO_PAYMENT_PAY: 'Gå till kassan',
    GO_TO_CART: 'Gå till varukorg',
    HANDLE_NOTIFICATIONS: 'Hantera notiser',
    HOUR: 'Timme',
    HOW_TO_PAY:'Hur vill du betala??',
    HOW_TO_PAY_SWISH:'Hur vill du betala med Swish?',
    INIT_SWISH_APP: 'Vi förbereder din Swish-betalning, ett ögonblick...',
    ITEM_DESCRIPTION_OUT_OF_STOCK: 'Slut',
    ITEM_DESCRIPTION_SCROLL: 'Scrolla ned',
    ITEM_SPECIAL_INSTRUCTION: 'Särskilda instruktioner',
    LOCATION_ADRESS: 'Din adress',
    LOCATION_CHOSE: 'Välj plats',
    LOCATION_COMMENT: 'Kommentar (våning, portkod etc.)',
    LOCATION_FEE: 'Avgift',
    LOCATION_OTHER_ADRESS: 'Prova en annan adress',
    LOCATION_QUESTION: 'Leverera hit?',
    LOCATION_UNFORTUNATELY_OTHER_ADRESS: 'Tyvärr levererar vi inte hit ännu. Prova en annan adress',
    LOGGING_IN: 'Du loggas in',
    LOGGING_IN_2: 'Loggar in',
    LOGIN: 'Logga in',
    LOGIN_APPLE: 'Logga in med Apple',
    LOGIN_CONTACTING_FACEBOOK: 'Kontaktar Facebook...',
    LOGIN_CREATE: 'Skapa',
    LOGIN_EMAIL: 'Logga in med e-post',
    LOGIN_EMAIL_FORMAT: 'Din email verkar vara felformaterad. Dubbekolla och försök igen',
    LOGIN_EMAIL_IN_USE: 'Denna email används redan',
    LOGIN_FACEBOOK: 'Logga in med Facebook',
    LOGIN_FINALIZING: 'Gör klart det sista',
    LOGIN_HI: 'Hej',
    LOGIN_NAME_NEEDED: 'Ditt namn behövs för att para ihop dig med dina ordrar',
    LOGIN_NEW_USER: 'Ny användare',
    LOGIN_NO_ACCOUNT_Q: 'Inget konto ännu?',
    LOGIN_PHONE: 'Logga in med telefonnummer',
    LOGIN_PW_6: 'Ditt lösenord måste innehålla minst 6 tecken',
    LOGIN_RESET: 'Återställ',
    LOGIN_TERMS: 'Våra villkor',
    LOGIN_VERIFYI: 'Verifiera',
    LOGIN_WAITING_CODE: 'Väntar på kod...',
    LOGIN_WRITE_EMAIL: 'Skriv in din mail',
    LOGOUT: 'Logga ut',
    ME: 'Jag',
    MENU_DRINKS_CLOSED: 'Det tas inte emot några dryckesbeställningar för tillfället',
    MENU_NO_PRODUCTS: 'Det finns inga produkter att visa',
    MENU_NOT_AVAILABLE: 'Inte tillgänglig',
    MENU_NOT_UPDATED: 'Det verkar som om att restaurangen inte uppdaterat sin meny än',
    MENU_ORDER_FULL: 'Du kan inte beställa mer på samma order',
    MINUTE: 'Minut',
    MON: 'Mån',
    MONDAY: 'Måndag',
    MOVE:'Gå vidare',
    MY_BOOKING: 'Min bokning',
    MY_RECIEPTS: 'Mina kvitton',
    NAME: 'Namn på order',
    NEW_ORDER: 'Ny beställning',
    NO: 'Nej',
    NO_DELIVERY_OPTIONS_SELECTED: 'Du måste välja ett leveranssätt',
    OFFERS: 'Erbjudanden',
    OFFERS_NONE: 'Det finns inga erbjudanden att visa',
    OK: 'OK',
    OPEN: "Öppet nu",
    OPT_OUT: 'Nej tack',
    OPENING_HOURS: 'Öppettider',
    ORDER: 'Beställ',
    OR:'eller',
    ORDER_CONFIRMED: "Din beställning är genomförd",
    ORDER_READY_ESTIMATION: 'Din order beräknas vara klar om:',
    ORDER_TICKET: 'Orderbiljett',
    PASSWORD: "Lösenord",
    PAY: 'Betala',
    PAY_NOW:'Betala nu',
    PAY_WITH_CARD: 'Betala med kort',
    PAYED: 'BETALD',
    PAYMENT_ADD_CARD: 'Lägg till kort',
    PAYMENT_APPLEPAY: 'Betala med Äpple Pay',
    PAYMENT_BONUS: 'Utnyttja bonuspoäng',
    PAYMENT_DELIVERY: 'Utkörningsavgift',
    PAYMENT_DEVICE_NOT_SUPPORTED: 'Enhet stöds ej!',
    PAYMENT_DISCOUNT: 'Rabatt',
    PAYMENT_ERROR: 'Se så ditt kort är öppet för internetköp och att tillräcklig summa finns tillgänglig',
    PAYMENT_ERROR_MAINTENANCE: 'Just nu pågår en uppdatering och ditt köp kan inte genomföras',
    PAYMENT_EXTRA: 'Extra',
    PAYMENT_FIRST_DISCOUNT: 'på vägen mot din första rabatt!',
    PAYMENT_INIT:'Förbereder betalning',
    PAYMENT_OFFER_ERROR_HEADER: 'Dessa erbjudanden kunde inte aktiveras',
    PAYMENT_OFFER_ERROR_TEXT: 'De gäller inte för det serveringssätt du valt',
    PAYMENT_PAY: 'Betala',
    PAYMENT_PAYMENTS: 'Betalsätt',
    PAYMENT_SAVED_CARDS: 'Sparade kort',
    PAYMENT_SCROLL: 'Scrolla ned för att betala',
    PAYMENT_SWISH: 'Betala med Swish',
    PAYMENT_SWISH_COMPLETE: "Slutför din betalning i Swish-appen",
    PAYMENT_SWISH_OPEN_APP: "Öppna Swish",
    PAYMENT_SWISH_NO_APP: "Vi kan inte hitta Swish på denna enheten. <br>Vänligen byt till att betala på annan enhet.",
    PAYMENT_TABLETAB: 'Lägg till på bordsnota',
    PAYMENT_TITLE: 'Betala för',
    PAYMENT_TOTAL: 'Totalt pris (inkl. moms)',
    PAYMENT_VIPPS: 'Betala med Vipps',
    PAYMENT_VIPPS_START: "Starta betalningen med Vipps?",
    PAYMENT_VIPPS_COMPLETE: "Slutför din betalning i Vipps-appen",
    PAYMENT_VIPPS_OPEN_APP: "Öppna Vipps",
    PAYMENT_VIPPS_SHOW_ORDER: "Klicka på visa order.",
    PAYMENT_VIPPS_SHOW_ORDER_WHEN_PAYED: "Klicka på visa order efter slutförd betalning.",
    PAYMENT_VIPPS_NO_APP: "Vi kan inte hitta Vipps på denna enheten. <br>Vänligen byt till att betala på annan enhet.",
    PAYMENT_SWISH_INIT: "Förbereder Swish",
    PAYMENT_VERYFING: "Verifierar...",
    PAYMENT_GO_TO_ORDER: "Vidare till order",
    PAYMENT_RETRY: "Försök igen",
    PAYMENT_OTHER_DEVICE: "Annan enhet",
    PAYMENT_THIS_DEVICE: "Denna enhet",
    PAYMENT_PLEASE_WAIT: "Vänligen vänta...",
    PAYMENT_VIPPS_INIT: "Förbereder Vipps",
    PAYMENT_VIPPS_NO_DATA: "Saknar data för att kunna göra en Vipps betalning.",
    PAYMENT_DONE: "Klart!",
    PAYMENT_ERROR_HEADER: "Betalningsfel",
    PAYMENT_WAIT_CONFIRM: "Väntar på bekräftelse",
    PAYMENT_CONFIRMED: "Din betalning är registrerad",
    PAYMENT_NOT_CONFIRMED: "Betalningen för din order har ännu inte registrerats.",
    PHONE: 'Telefon',
    PLEASE_WAIT:'Vänligen vänta...',
    PURCHASE_HISTORY_Brutto: 'Brutto',
    PURCHASE_HISTORY_BUY_AT: 'Köp hos',
    PURCHASE_HISTORY_COLLECTED_DISCOUNT: 'Samlad rabatt',
    PURCHASE_HISTORY_CONTROL_UNIT: 'K.enhet',
    PURCHASE_HISTORY_DISCOUNT: 'rabatt, summa',
    PURCHASE_HISTORY_FILL_MAIL: 'Fyll i din mailadress',
    PURCHASE_HISTORY_MAIL_RECIEPT: 'Maila kvitto',
    PURCHASE_HISTORY_NETTO: 'Netto',
    PURCHASE_HISTORY_ORDER: 'Order',
    PURCHASE_HISTORY_PHONE: 'Telefon',
    PURCHASE_HISTORY_RECIEPT_NO: 'Kvittonr',
    PURCHASE_HISTORY_ROUNDING: 'Öresavrundning',
    PURCHASE_HISTORY_SENDING_RECIEPT: 'Skickar kvitto till',
    PURCHASE_HISTORY_SENDING_SENT: 'Kvittot mailades till',
    PURCHASE_HISTORY_SUM: 'Sum',
    PURCHASE_HISTORY_TOTAL: 'Totalt',
    PURCHASE_HISTORY_VAT: 'Moms',
    PURCHASE_HISTORY_VATID: 'VatId',
    PURCHASE_COMPLETE:'Du genomförde ett köp kl',
    PURCHASE_CANCEL:'Du avbröt ett köp kl',
    PUSH_ALERT_BTN: 'Tryck här',
    PUSH_ALERT_TXT: 'För att se status på din order behöver du tillåta pushnotifkationer',
    PUSH_CHANGE_SELECTION: 'Du kan alltid ändra ditt val under Konto => Pushnotifikationer',
    PUSH_INFO: 'Välj de restauranger du vill få erbjudanden från. Vi lovar att inte missbruka ditt förtroende',
    PUSH_PERMISSION: 'Du måste öppna telefonens inställningar och tillåta pushnotiser för appen innan du kan göra ändringar här',
    PUSH_TITLE: 'Notifikationer',
    ORDER_NOT_VALID: 'Orderbeloppet är ogiltigt',
    QUANTITY: "Antal",
    RECIEPTS: 'Kvitton',
    RESTAURANT_INFO: 'Restauranginformation',
    SAT: 'Lör',
    SATURDAY: 'Lördag',
    SCAN_QR_WITH_SWISH:'Scanna QR-koden med Swish-appen',
    SAVED_CARDS: 'Sparade kort',
    SEARCH: 'Sök...',
    SELECT_LANGUAGE: 'Välj språk',
    SEND_SMS_CODE: 'Skicka smskod',
    SENT: 'Skickat',
    STOCK_TITLE: 'Slut i lager!',
    SUM: 'Summa',
    SUN: 'Sön',
    SUNDAY: 'Söndag',
    TABLENUMBER: 'Bordsnummer',
    THU: 'Tor',
    THURSDAY: 'Torsdag',
    TIPS: 'Dricks',
    TODAY: 'Idag',
    TUE: 'Tis',
    TUESDAY: 'Tisdag',
    WED: 'Ons',
    WEDNESDAY: 'Onsdag',
    WEEK: 'Vecka',
    WEEKLY_LUNCH: 'Veckans lunch',
    WELCOME: 'Välkommen!',
    WRITE_TABLENUMBER: 'Skriv in ditt bordsnummer',
    YES: 'Ja',
    YOUR_CART: 'Din varukorg',
    HOME: 'Hem',
    DRINKS: 'Dryck',
    FOOD: 'Mat',
    DISCOUNT_CODE: 'Rabattkod',
    FOLLOW: 'Följ oss',
    SAVE_CARD_QUESTION: 'Lägg till kort för framtida köp.',
    SAVE_CARD_QUESTION_HEADER: 'Spara ditt kort?',
    YOUR_ORDER: 'Din beställning',
    // Reepay charge errors
    ACQUIRER_ERROR: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    INSUFFICIENT_FOUNDS: 'Misslyckades på grund av otillräckliga medel.',
    PAYMENT_NOT_AUTHORIZED: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    CREDIT_CARD_EXPIRED: 'Ditt kort har gått ut. Vänligen prova ett annat',
    DECLINED_BY_ACQUIRER: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    CREDIT_CARD_LOST_OR_STOLEN: 'Detta kort är markerat som stulet. Ditt ip har loggats',
    CREDIT_CARD_SUSPECTED_FRAUD: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    REFOUND_AMOUNT_TOO_HIGH: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    AUTHORIZATION_EXPIRED: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    AUTHORIZATION_AMOUNT_EXCEEDED: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    AUTHORIZATION_VOIDED: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    SCA_REQUIRED: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    RISK_FILTER_BLOCK: 'Något gick fel med ditt köp. Vänligen kontakta restaurangen',
    PAYMENT_AND_ORDER_IS_PLACED: "Din order är betald och beställd",
    DONT_CLOSE_WEB_BROWSER: "OBS! Stäng inte webbläsaren!",
    ORDER_INFORMATION: "<p>När ordern är klar kommer en dialog visas i webbläsaren.</p> <p>Ert kvitto kommer finnas under <b>Mina kvitton</b> och är möjligt att maila till önskad e-postadress.</p> <p>Din order visas under aktiva order och du kommer automatiskt skickas dit när du kvitterar denna dialog.</p>",

    // VIPPS
    VIPPS_ERROR: "Vipps nekade din betalning.",
    VIPPS_CANCELLED: "Användare avbröt betalningen",
    VIPPS_PAYMENT_41 : "The user does not have a valid card",
    VIPPS_PAYMENT_42 : "Refused by issuer bank",
    VIPPS_PAYMENT_43 : "Refused by issuer bank because of invalid a amount",
    VIPPS_PAYMENT_44 : "Refused by issuer because of expired card",
    VIPPS_PAYMENT_45 : "Reservation failed. Either because the user cancelled, did not act, or for some unknown reason.",
    VIPPS_PAYMENT_51 : "Cannot cancel an already captured order",
    VIPPS_PAYMENT_52 : "Cancellation failed",
    VIPPS_PAYMENT_53 : "Cannot cancel an order that is not reserved",
    VIPPS_PAYMENT_61 : "Captured amount exceeds the reserved amount",
    VIPPS_PAYMENT_62 : "The amount you tried to capture is not reserved",
    VIPPS_PAYMENT_63 : "Capture failed for some unknown reason, please use /details endpoint to know the exact status",
    VIPPS_PAYMENT_71 : "Cannot refund more than captured amount",
    VIPPS_PAYMENT_72 : "Cannot refund a reserved order (only captured orders), please use the /cancel endpoint",
    VIPPS_PAYMENT_73 : "Cannot refund a cancelled order",
    VIPPS_PAYMENT_93 : "Captured amount must be the same in an Idempotent retry",
    VIPPS_PAYMENT_95 : "Payments can only be refunded up to 365 days after reservation",
    VIPPS_PAYMENT_96 : "Payments can only be captured up to 180 days after reservation",
    VIPPS_PAYMENT_1501 : "This person can not pay to companies. Used for Vipps users under the age of 15.",
    VIPPS_PAYMENT_1082 : "This person is not BankID verified. Only applies for test users.",
    VIPPS_VIPPSERROR_91: "Transaction is not allowed",
    VIPPS_VIPPSERROR_92: "Transaction already processed",
    VIPPS_VIPPSERROR_94: "Order locked and is already processing. This can occur if a bank has problems, and Vipps needs to wait and/or clean up.",
    VIPPS_VIPPSERROR_98: "Too many concurrent requests. Used only to prevent incorrect API use.",
    VIPPS_VIPPSERROR_99: "Description of the internal error",
    VIPPS_USER_81: "User not registered with Vipps",
    VIPPS_USER_82: "User app version not supported",
    VIPPS_MERCHANT_31: "Merchant is blocked because of",
    VIPPS_MERCHANT_32: "Receiving limit of merchant is exceeded",
    VIPPS_MERCHANT_33: "Number of payment requests has been exceeded",
    VIPPS_MERCHANT_34: "Unique constraint violation of the orderId",
    VIPPS_MERCHANT_35: "Registered order not found",
    VIPPS_MERCHANT_36: "Merchant agreement not signed",
    VIPPS_MERCHANT_37: "Merchant not available, deactivated or blocked. See the FAQ.",
    VIPPS_MERCHANT_38: "Sale unit is not allowed to skip the landing page",
    VIPPS_MERCHANT_21: "Reference orderId is not valid",
    VIPPS_MERCHANT_22: "Reference orderId is not in valid state",
    VIPPS_MERCHANT_97: "The merchant is not approved by Vipps to receive payments",

    // Swish
    // ' <p>Ursäkta :(</p> <p>Swish nekade din betalning.</p>'
    SWISH_ERROR_FF08: 'Swish nekade din betalning.', // PaymentReference is invalid.
    SWISH_ERROR_RP03: 'Swish nekade din betalning.', // Callback URL is missing or does not use HTTPS.
    SWISH_ERROR_BE18: 'Swish nekade ditt nummer, kontrollera att du angivet rätt nummer.', // Payer alias is invalid.
    SWISH_ERROR_RP01: 'Swish nekade din betalning.', // Missing Merchant Swish Number.
    SWISH_ERROR_PA02: 'Swish nekade betalningen, p.g.a. att beloppet är inte giltigt.', // Amount value is missing or not a valid number.
    SWISH_ERROR_AM02: 'Swish nekade betalningen, p.g.a att beloppet är för stort.', // Amount value is too large..
    SWISH_ERROR_AM03: 'Swish nekade betalningen, p.g.a att valutan saknas eller inte stöds.', // Invalid or missing Currency.
    SWISH_ERROR_AM06: 'Swish nekade betalningen, p.g.a att beloppet är mindre än överenskommen gräns.', // Specified transaction amount is less than agreed minimum.
    SWISH_ERROR_AM21: 'Swish nekade betalningen, p.g.a att beloppet är större en vad din bank tillåter.', // Transaction amount exceeds Swish limit agreed between bank and payer for given period. Please inform the payer to contact their bank for more information on how to adjust the Swish limits.
    SWISH_ERROR_RP02: 'Swish nekade din betalning.', // Wrong formatted message..
    SWISH_ERROR_RP06: 'Swish nekade betalningen, p.g.a att redan har en annan aktiv betalning, avsluta den och prova igen.', // A payment request already exists for that payer. Only applicable for Swish e-commerce.

    SWISH_ERROR_RF07: 'Swish nekade betalningen, kontrollera att du har pengar tillgängliga för Swish.', // Transaction declined.
    SWISH_ERROR_ACMT03: 'Swish nekade betalningen, p.g.a. att ditt nummer inte verkar vara aktiverat för Swish.', // Payer not Enrolled.
    SWISH_ERROR_ACMT01: 'Swish nekade betalningen.', // Counterpart is not activated.
    SWISH_ERROR_ACMT07: 'Swish nekade betalningen, p.g.a. att ditt nummer inte verkar vara aktiverat för Swish.', // Payee not Enrolled.
    SWISH_ERROR_VR01: 'Swish nekade betalningen, p.g.a. att detta Swish konto inte möter ålderskravet för detta köp.', // Payer does not meet age limit.
    SWISH_ERROR_VR02: 'Swish nekade betalningen', // The payer alias in the request is not enrolled in swish with the supplied ssn.
    SWISH_ERROR_TM01: 'Swish nekade betalningen, p.g.a att du inte genomfört betalningen i Swish innom satt tidsgräns.',// Swish timed out before the payment was started
    SWISH_ERROR_BANKIDCL: 'Swish nekade betalningen, p.g.a. att du avbröt BankID-signeringen.', // Payer cancelled BankID signing
    SWISH_ERROR_BANKIDONGOING: 'Swish nekade betalningen, p.g.a. att du redan har en annan BankID-signering aktiv. Avsluta den och försök igen.', // BankID already in use.
    SWISH_ERROR_BANKIDUNKN: 'Swish nekade betalningen, p.g.a. att BankID inte kunde auktorisera din betalning.', // BankID is not able to authorize the payment..

    SWISH_ERROR_CONNECTION: 'Det uppstod problem i kontakten under betalningen. Verifiera att din order är bekräftad, annars beställ på nytt.',

    SWISH_CANCELLED: 'Betalningen avbröts.', // Unhandled error in PubQ backend
    SWISH_DECLINED: 'Betalningen avbröts.', // Unhandled error in PubQ backend

    SWISH_ERROR: 'Swish nekade din betalning.', // Unhandled error in PubQ backend

    SWISH_DEMAND_NUMBER: '<p>Ni måste ange ett giltigt nummer för att kunna betala.</p> <p>Er order meddelas på SMS när den är klar.</p>',

    WAITING_ON_SWISH_PAYMENT: 'Betala din beställning i Swish appen"',

    SHOW_ORDERS:'Visa order',
    VERIFY_TIMEOUT: '<p>Din betalning avbröts p.g.a att du inte genomfört betalningen innom satt tidsgräns.</p>',

    CONTACT_INFORMATION: 'Kontaktuppgifter',
    MENU_TOO_MUCH_ALCOHOL: 'Du kan inte lägga till fler alkoholprodukter på denna beställning.',

    //NOT ACTIVATED COMPONENT
    UNIT_NOT_ACTIVATED: 'Denna enhet är ännu inte aktiverad för webbbeställning!',
    CONTACT_ADMIN_UNIT:'Kontakta administratören för att aktivera enheten.',
    NO_CLIENT_DATA:'Inga klientdata eller definierade enheter för klienten.',

   ABLE_ORDER_ONLINE:"Kan beställas online.",
   ALLOW_LOCATION:"Tillåt platsåtkomst och uppdatera sidan!",
   CONTACT_SUPPORT_SWISH:"Saknas data för att kunna göra en Swish-betalning, kontakta support.",
   CANCEL_SWISH_VERIFY:'Avbryt inte om du genomfört betalningen. Vill du avbryta?',
   CONTACT_SUPPORT_PAYMENT:"Saknar data för att kunna göra en betalning, kontakta supporten.",
   CONTACT_ADMONISTRATOR:"Kontakta administratören för att aktivera enheten.",
   INSERT_CARD_:"Lägga in kort på nytt",
   LOGIN_MOBILE_NEEDED:'Ditt nummer behövs i fall vi behöver kontakta dig',
   NEED_TO_INSERT_CARD_AGAIN:"Du behöver lägga in kort på nytt för att fortsätta betalningen",
   NOT_ACTICATED_FOR_WEB_ORDERING:"Denna enhet är ännu inte aktiverad för webbbeställning!",
   NOTE:"Notera",
   ORDERING_NOT_POSSIBLE:'Går inte att beställa nu',
   PENDING_ORDER:' Pågående ordrar',
   TO_HOMEPAGE:'Hemsida',
   UNABLE_TO_ORDER:"Kan inte beställa online -",
   OUR_POLICY:'Vår policy',
   PAYMENT_PLEASE_SELECT_PAYMENT_METHOD: "Välj betalningsmetod"
};
