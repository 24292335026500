export const lang = {
    get: (string, parameters?) => {
        switch (string) {
            case 'BONUS_USE': return `Use ${parameters[0]}​​​ bonus points`;
            case 'STOCK_ALERT': return `Unfortunately, ${parameters[0]}​​​ is out of stock. Your cart has been adjusted to what was available so you can continue shopping.`;
            case 'BOOKING_NO_AVAILABLE_TIMES_1': return `Unfortunately there is no available timeslot for ${parameters[0]} persons.`;
            case 'BOOKING_SMS_TEXT': return `Hello, this is regarding your reservation ${parameters[0]} \n ${parameters[1]}\n ${parameters[2]} persons.`;
            case 'OFFERS_ACTIVATED': return `Activated ${parameters[0]}`;
            case 'CART_ITEMS_REMOVE': return `Do you want to remove ${parameters[0]}?`;
            case 'CART_ALLERGY_CONTACT': return `, between ${parameters[0]}-${parameters[1]}, we will happily answer your questions about content`;
            case 'EMAIL_CORRECT': return `Is ${parameters[0]} correct?`;
            case 'LOGIN_SEND_SMS': return `Send text to ${parameters[0]}?`;
            case 'LOGIN_ERROR': return `Oops! Something went wrong. Please try another way to sign in.\n ${parameters[0]}`;
            case 'OPEN_SWISH_APP_WITH_PHONENUMBER': return `Visas ingen betalning i Swish, kontrollera att det är rätt nummer (${parameters[0]}).`;
            case 'SINGLE_ORDER': return `You have ${parameters[0]} order in progress`;
            case 'MULTIPLE_ORDER': return `You have ${parameters[0]} orders in progress`;
        }
    },
    ABOUT_US: 'About us',
    ACCOUNT: 'Account',
    ACCOUNT_REMOVE_CARD: 'Remove card?',
    ACCOUNT_REMOVE_CARD_2: 'Do you want to delete the card',
    ACTIVATE: 'Activate',
    ACTIVE_ORDER_CLOSE_WINDOW: "This window disappears if you select 'Yes'",
    ACTIVE_ORDER_DEL_CUSTOMER: 'Your order is placed in the kitchen and you will receive a notification when the order is delivered to you',
    ACTIVE_ORDER_DRINK_READY: 'Your drink is ready',
    ACTIVE_ORDER_EATIN_COMPLETE: 'Display this screen at disk',
    ACTIVE_ORDER_FOOD_READY: 'Your food is ready',
    ACTIVE_ORDER_HOME_DELIVERY_OTW: "It's leaving the restaurant now and is on its way to you!",
    ACTIVE_ORDER_ONGOING: 'Ongoing orders',
    ACTIVE_ORDER_READY: 'Your order is ready',
    ACTIVE_ORDER_RECIEVED_Q: 'Have you received your order?',
    ACTIVE_ORDER_SHOW_SCREEN: 'Display this screen at disk',
    ACTIVE_ORDER_TABLE: 'Thanks! We are in the process of ordering and will bring it out to you as soon as we can! ',
    ACTIVE_ORDER_TABLE_DELIVERY_OTW: 'It will be delivered to your table soon',
    ACTIVE_ORDER_TAKEAWAY: 'Your order is placed in the kitchen and you will soon have an estimated time when you can pick it up',
    ACTIVE_ORDER_TAKEAWAY_COMPLETE: 'Show this screen when you pick up your order',
    ACTIVE_ORDER_TICKET: "Here's your ticket",
    ACTIVE_ORDER_WAITING: 'Thanks! Your order has started and you will receive a notification as soon as it is ready. ',
    ACCEPT_USER_TERMS: 'I agree to the Terms of Use',
    ADD: 'Add',
    ADD_CARD: 'Add card',
    ADD_CARD_ADDING: 'Pay by card',
    ADD_CARD_ERROR_E1: 'Something went wrong. Try again!',
    ADD_CARD_ERROR_E10: 'Wrong card number. Double check that you have entered correctly ',
    ADD_CARD_ERROR_E11: 'Wrong month / year. Double check that you have entered correctly ',
    ADD_CARD_ERROR_E12: 'Wrong name on card. Contact the staff ',
    ADD_CARD_ERROR_E13: 'The restaurant has the wrong settings. Contact the staff ',
    ADD_CARD_ERROR_E14: 'Wrong CVV code. Double check that you typed correctly ',
    ADD_CARD_ERROR_E3: 'The restaurant cannot add cards. Contact staff! ',
    ADD_CARD_ERROR_E4: 'Invalid reference details',
    ADD_CARD_ERROR_E5: 'Wrong card type!',
    ADD_CARD_ERROR_E6: 'Wrong terminal ID! Contact the staff ',
    ADD_CARD_ERROR_E7: 'Not allowed! Contact the staff ',
    ADD_CARD_ERROR_E8: 'Merchant ID! Contact the staff ',
    ADD_CARD_ERROR_E9: 'Wrong date stamp! Contact the staff ',
    ADD_CARD_NEW_CARD: 'New card',
    ADD_CARD_NICK_MSG: 'The card is securely encrypted and saved for future use',
    ADD_CARD_NICK_PLACEHOLDER: 'Card nickname',
    ADD_CARD_NICK_TITLE: 'What do you want to call your card?',
    ADD_CARD_SECURITY_INFO: 'We process your card information in a safe and secure manner. All sensitive information is stored with a certified bill of exchange ',
    ADD_TO_CART: 'Add to cart',
    BAB_ADD: 'ADD',
    BAB_BURGER_NAME: 'What should your burger be called?',
    BAB_DRESSING: 'Dressing',
    BAB_EXTRAS: 'Select accessories',
    BAB_MANY: 'Choose as many as you want',
    BAB_NAME: 'What should your right be called?',
    BAB_SIDEORDER: 'Select side order',
    BAB_TOPPINGS: 'Toppings',
    BAB_TYPE_SIZE: 'Select type & size',
    BACK: 'Back',
    BONUS_POINTS: 'bonus points',
    BONUSPOINTS: 'Bonus points',
    BOOK: 'Reserve',
    BOOKING_ALLOW_SMS: 'The permission allows the app to open a pre-filled SMS to the restaurant for you',
    BOOKING_APPCODE: 'Your app code is',
    BOOKING_CANCEL: 'Are you sure you want to cancel?',
    BOOKING_CANCELED: 'Your table has been canceled',
    BOOKING_CONFIRM: 'Confirmation',
    BOOKING_CONFIRM_CANCEL: 'Confirm cancellation',
    BOOKING_CONTACT_US_1: 'Contact us at',
    BOOKING_CONTACT_US_2: 'or press the button to send an SMS',
    BOOKING_ICONS: 'Order using the icons below',
    BOOKING_LARGER_GROUPS: 'Table booking larger groups',
    BOOKING_LARGER_GROUPS_CONTACT: 'If you want to book a table for more people please get in touch',
    BOOKING_NO_AVAILABLE_TIMES_1: 'No free time',
    BOOKING_NUMBER_GUESTS: 'Number of guests',
    BOOKING_QUESTIONS: 'Do you have questions about the booking?',
    BOOKING_THANKS_1: 'Thank you for your visit!',
    BOOKING_THANKS_2: 'See you again soon',
    BOOKING_TITE: 'Reserve a table',
    CANCEL: 'Cancel',
    CANCEL_ORDER: 'Do you want to cancel your order and clear the shopping cart?',
    CARD_NO: 'Card Number',
    CART: 'Shopping cart',
    CART_ALLERGIC_CONTACT: 'Allergic? Contact the restaurant at ',
    CART_BOOK_TABLE: 'Reserve a table',
    CART_CHECK_CARD: 'Make sure your card is open for online purchases and that a sufficient amount is available',
    CART_CREATING_ORDER: 'Creates orders and adds to the bill',
    CART_GO_TO_MENY: 'Go to the menu and place your first order',
    CART_ITEM_18Y: '18 + years and valid identification required when buying alcohol ',
    CART_ITEM_ALCOHOL: 'Alcohol is not discounted',
    CART_ITEM_COMMENT: 'Write your comment here',
    CART_ITEM_COMMENT_EXAMPLE: "Eg 'no coriander'",
    CART_ITEM_OFFER_NOT_VALID: 'The discount code is not valid',
    CART_ITEM_REMOVE_CODE: 'Delete code?',
    CART_ITEMS_APPLYING_OFFER: 'Add discount code',
    CART_MY_BOOKING: 'My booking',
    CART_NO_ACTIVE_TAB: 'You have no active table note',
    CART_ORDER_TAKEWAY: 'Order takeaway',
    CART_PAYMENT_ABORTED: 'Payment suspended',
    CART_PERSONNEL_CARD: 'Staff card',
    CART_PREPARNG_SWISH: 'Preparing for Swish payment',
    CART_SERVE_NOW: "Serve directly",
    CART_SOME_ERROR: 'Something went wrong',
    CART_WEEK_MENU: 'Menu of the week',
    CART_WELCOME_OPENING_HOURS: 'Opening hours & contact information',
    CART_WELCOME_READ: 'Read more',
    CHANGE_UNIT: 'Change unit',
    CHARGE_ERROR: 'Something went wrong with your purchase. Please contact the restaurant ',
    CHARGE_SWISH_INIT: 'Swish asks for payment',
    CHARGE_TAB_TOAST: 'Your bill has been paid and a receipt has been created under your account',
    CHARGE_VERIFY: 'Verifies and reconciles',
    CHARGE_WAIT: 'A moment ... ',
    CHOOSE_RESTAURANT: 'Choose restaurant',
    CHOOSE_DELIVERY_OPTION: 'Choose delivery method',
    CLIENT_OPEN_HOURS: 'Opening hours in the app',
    CLOSE: "Close",
    CLOSED: 'Closed',
    COMMENT: 'Comment',
    CONFIRM: 'Ok',
    CONTINUE_SHOPPING: 'Add more products',
    CONTACT_INFORMATION: 'Contact information',
    CREATE_LOGIN: 'Create account / login',
    CURRENT: 'Current',
    CUSTOM_TICKET_VALID: 'Valid through',
    CUSTOMER_CODE_TYPE_4_DIGITS: 'Write 4 digits',
    CUSTOMER_TAB: 'Table note',
    CUSTOMER_TAB_KEY_KOD: 'Table code',
    CUSTOMER_TAB_KEY_SAME_CODE: 'Same code again? Push here',
    CUSTOMER_TAB_KEY_WRONG_CODE: 'Wrong table code',
    CUSTOMER_TABS_ATTENTION_MSG: 'We will come soon and help you',
    CUSTOMER_TABS_CALL_WAITER: 'Call for waiters',
    CUSTOMER_TABS_CUSTOM_AM1: 'Any amount ...',
    CUSTOMER_TABS_CUSTOM_AM2: 'Optional Sum',
    CUSTOMER_TABS_PAY_IN_APP: 'Pay in app',
    CUSTOMER_TABS_TIPS: 'Do you want to give something extra to our staff?',
    DATE: 'Date',
    DATE_TIME: 'Date & Time',
    DELIVERY_ALCOHOL_DELIVERY: 'You can not order alcohol at home ...',
    DELIVERY_ALCOHOL_TAKE_AWAY: 'You can not order alcohol as take away',
    DELIVERY_FETCH_BAR: 'Pick up at the bar',
    DELIVERY_HOME_DELIVERY: 'Exit',
    DELIVERY_INFORMATION: 'Delivery information',
    DELIVERY_MY_TABLE: 'Serve to my table',
    DELIVERY_OFFER_ERROR: 'The discount code is not valid for this serving method',
    DELIVERY_QUESTION: 'How do you want to get your order?',
    DELIVERY_SERVING: 'Serving',
    DELIVERY_OPTION: 'Delivery method',
    DELIVERY_TO_GO: 'Bring',
    DELIVERY_CHANGE_OPTION: 'Change delivery method',
    DISCOUNT: 'Discount',
    DONE: 'Clear',
    EMAIL: 'Email',
    EMAIL_FOLLOW_INSTRUCTIONS: 'Open your inbox and follow the instructions',
    EMAIL_SENT: 'Mail Sent',
    ENTER_CARD_NUMBER_AT_START_OF_PAYMENT: 'Card number is filled in at the start of payment.',
    ERROR: 'Wrong',
    FORWARD: 'Further',
    FREE_CHARGE: 'No payment needs to be made for this purchase.',
    FRI: 'Fri',
    FRIDAY: 'Friday',
    GO_TO_CART: 'Go to cart',
    GO_TO_PAYMENT_PAY: 'Go to payment',
    HANDLE_NOTIFICATIONS: 'Manage notifications',
    HOUR: 'Hour',
    HOW_TO_PAY: 'how do you want to pay?',
    HOW_TO_PAY_SWISH: 'How do you want to pay with Swish?',
    INIT_SWISH_APP: 'We are preparing your Swish payment, in a moment ...',
    ITEM_DESCRIPTION_OUT_OF_STOCK: 'Final',
    ITEM_DESCRIPTION_SCROLL: 'Scroll down',
    LOCATION_ADRESS: 'Your address',
    LOCATION_CHOSE: 'Choose location',
    LOCATION_COMMENT: 'Comment (floor, door code, etc.)',
    LOCATION_FEE: 'Charge',
    LOCATION_OTHER_ADRESS: 'Try another address',
    LOCATION_QUESTION: 'Deliver here?',
    LOCATION_UNFORTUNATELY_OTHER_ADRESS: 'Unfortunately we do not deliver here yet. Try another address',
    LOGGING_IN: 'You are logged in',
    LOGGING_IN_2: 'Logging in',
    LOGIN: 'Sign in',
    LOGIN_APPLE: 'Sign in with Apple',
    LOGIN_CONTACTING_FACEBOOK: 'Contacting Facebook ...',
    LOGIN_CREATE: 'Create',
    LOGIN_EMAIL: 'Login by email',
    LOGIN_EMAIL_FORMAT: 'Your email appears to be incorrectly formatted. Double check and try again ',
    LOGIN_EMAIL_IN_USE: 'This email is already in use',
    LOGIN_FACEBOOK: 'Log in with Facebook',
    LOGIN_FINALIZING: 'Finish the last',
    LOGIN_HI: 'Hello',
    LOGIN_NAME_NEEDED: 'Your name is needed to match you with your orders',
    LOGIN_NEW_USER: 'New user',
    LOGIN_NO_ACCOUNT_Q: 'No account yet?',
    LOGIN_PHONE: 'Log in with phone number',
    LOGIN_PW_6: 'Your password must be at least 6 characters long',
    LOGIN_RESET: 'Reset',
    LOGIN_TERMS: 'Our terms',
    LOGIN_VERIFYI: 'Verify',
    LOGIN_WAITING_CODE: 'Waiting for code ...',
    LOGIN_WRITE_EMAIL: 'Enter your email',
    LOGOUT: 'Log out',
    ME: 'My Account',
    MENU_DRINKS_CLOSED: 'No drink orders are currently accepted',
    MENU_NO_PRODUCTS: 'There are no products to display',
    MENU_NOT_AVAILABLE: 'not available at the moment',
    MENU_NOT_UPDATED: 'It seems that the restaurant has not updated its menu yet',
    MENU_ORDER_FULL: 'You can not order more on the same order',
    MINUTE: 'Minute',
    MON: 'Moon',
    MONDAY: 'Monday',
    MOVE: 'Move on',
    MY_BOOKING: 'My booking',
    MY_RECIEPTS: 'My receipts',
    NAME: 'Name on order',
    NEW_ORDER: 'New order',
    NO: 'No',
    NO_DELIVERY_OPTIONS_SELECTED: 'You have to choose a delivery method',
    OFFERS: 'Offers',
    OFFERS_NONE: 'There are no offers to show',
    OK: 'OK',
    OPEN: "Open",
    OPT_OUT: 'No thanks',
    OPENING_HOURS: 'Opening hours',
    ORDER: 'Order',
    OR: 'Or',
    ORDER_CONFIRMED: "Your order has been completed",
    ORDER_READY_ESTIMATION: 'Your order is estimated to be ready in:',
    ORDER_TICKET: 'Order ticket',
    PASSWORD: "Password",
    PAY: 'Pay',
    PAY_NOW: 'Pay now',
    PAY_WITH_CARD: 'Pay with card',
    PAYED: 'PAID',
    PAYMENT_ADD_CARD: 'Add cards',
    PAYMENT_APPLEPAY: 'Pay with Apple Pay',
    PAYMENT_BONUS: 'Take advantage of bonus points',
    PAYMENT_DELIVERY: 'Exit fee',
    PAYMENT_DEVICE_NOT_SUPPORTED: 'Device not supported!',
    PAYMENT_DISCOUNT: 'Discount',
    PAYMENT_ERROR: 'Make sure your card is open for online purchases and that a sufficient amount is available',
    PAYMENT_ERROR_MAINTENANCE: 'An update is currently in progress and your purchase cannot be completed',
    PAYMENT_EXTRA: 'Extra',
    PAYMENT_FIRST_DISCOUNT: 'on the way to your first discount!',
    PAYMENT_INIT: 'Preparing Payment',
    PAYMENT_OFFER_ERROR_HEADER: 'These offers could not be activated',
    PAYMENT_OFFER_ERROR_TEXT: 'They do not apply to the serving method you have chosen',
    PAYMENT_PAY: 'Pay',
    PAYMENT_PAYMENTS: 'Payment method',
    PAYMENT_SAVED_CARDS: 'Saved cards',
    PAYMENT_SCROLL: 'Scroll down to pay',
    PAYMENT_SWISH: 'Pay with Swish',
    PAYMENT_SWISH_COMPLETE: "Complete your payment in the Swish app",
    PAYMENT_SWISH_OPEN_APP: "Open Swish",
    PAYMENT_SWISH_NO_APP: "We can not find Swish on this device. <br> Please switch to paying on another device.",
    PAYMENT_SWISH_INIT: "Preparing Swish",
    PAYMENT_TABLETAB: 'Add to table note',
    PAYMENT_TITLE: 'Pay for',
    PAYMENT_TOTAL: 'Total',
    PAYMENT_VIPPS: 'Pay with Vipps',
    PAYMENT_VIPPS_START: "Start payment with Vipps?",
    PAYMENT_VIPPS_COMPLETE: "Complete your payment in the Vipps app",
    PAYMENT_VIPPS_OPEN_APP: "Open Vipps",
    PAYMENT_VIPPS_SHOW_ORDER: "Click on show order.",
    PAYMENT_VIPPS_SHOW_ORDER_WHEN_PAYED: "Click on show order after completed payment.",
    PAYMENT_VIPPS_NO_APP: "We can not find Vipps on this device. <br> Please switch to paying on another device.",
    PAYMENT_VERYFING: "Verifying ...",
    PAYMENT_GO_TO_ORDER: "On to orders",
    PAYMENT_RETRY: "Try again",
    PAYMENT_OTHER_DEVICE: "Other device",
    PAYMENT_THIS_DEVICE: "This device",
    PAYMENT_VIPPS_INIT: "Preparing Vipps",
    PAYMENT_VIPPS_NO_DATA: "Lacks data to be able to make a Vipps payment.",
    PAYMENT_DONE: "Done!",
    PAYMENT_ERROR_HEADER: "Payment error",
    PAYMENT_WAIT_CONFIRM: "Awaiting confirmation",
    PAYMENT_CONFIRMED: "Your payment is registered",
    PAYMENT_NOT_CONFIRMED: "The payment for your order has not yet been registered.",
    PHONE: 'Phone',
    PLEASE_WAIT: 'Please wait...',
    PURCHASE_HISTORY_Brutto: 'Gross',
    PURCHASE_HISTORY_BUY_AT: 'Buy at',
    PURCHASE_HISTORY_COLLECTED_DISCOUNT: 'Total discount',
    PURCHASE_HISTORY_CONTROL_UNIT: 'K.unit',
    PURCHASE_HISTORY_DISCOUNT: 'discount, sum',
    PURCHASE_HISTORY_FILL_MAIL: 'Fill in your email address',
    PURCHASE_HISTORY_MAIL_RECIEPT: 'Mail receipt',
    PURCHASE_HISTORY_NETTO: 'Net',
    PURCHASE_HISTORY_ORDER: 'Order',
    PURCHASE_HISTORY_PHONE: 'Phone',
    PURCHASE_HISTORY_RECIEPT_NO: 'Receipt No.',
    PURCHASE_HISTORY_ROUNDING: 'Ear rounding',
    PURCHASE_HISTORY_SENDING_RECIEPT: 'Sending receipt to',
    PURCHASE_HISTORY_SENDING_SENT: 'Receipt emailed to',
    PURCHASE_HISTORY_SUM: 'Sum',
    PURCHASE_HISTORY_TOTAL: 'Total',
    PURCHASE_HISTORY_VAT: 'VAT',
    PURCHASE_HISTORY_VATID: 'VatId',
    PURCHASE_COMPLETE: 'You completed a purchase at',
    PURCHASE_CANCEL: 'You canceled a purchase at',
    PUSH_ALERT_BTN: 'Push here',
    PUSH_ALERT_TXT: 'To see the status of your order, you need to allow push notifications',
    PUSH_CHANGE_SELECTION: 'You can always change your selection under Account => Push notifications',
    PUSH_INFO: 'Choose the restaurants you want to get offers from. We promise not to abuse your trust ',
    PUSH_PERMISSION: 'You must open the phone settings and allow push notifications for the app before you can make changes here',
    PUSH_TITLE: 'Notifications',
    RECEIVED_ORDER: 'I have received my order',
    ORDER_NOT_VALID: 'Order amount is invalid',
    QUANTITY: "Quantity",
    RECIEPTS: 'Receipts',
    RESTAURANT_INFO: 'Restaurant information',
    SAT: 'Sat',
    SATURDAY: 'Saturday',
    SAVED_CARDS: 'Saved cards',
    SCAN_QR_WITH_SWISH: 'Scan the QR code with the Swish app',
    SEARCH: 'Search...',
    SELECT_LANGUAGE: 'Select your language',
    SEND_SMS_CODE: 'Send sms code',
    SENT: 'Sent',
    STOCK_TITLE: 'Out of stock!',
    SUM: 'Amount',
    SUN: 'Sun',
    SUNDAY: 'Sunday',
    TABLENUMBER: 'Table number',
    THU: 'Tor',
    THURSDAY: 'Thursday',
    TIPS: 'Tip',
    TODAY: 'Today',
    TUE: 'Tis',
    TUESDAY: 'Tuesday',
    WED: 'We',
    WEDNESDAY: 'Wednesday',
    WEEK: 'Week',
    WEEKLY_LUNCH: 'Lunch of the week',
    WELCOME: 'Welcome!',
    WRITE_TABLENUMBER: 'Enter your table number',
    YES: 'Yes',
    YOUR_CART: 'Your cart',
    HOME: 'Home',
    DRINKS: 'Drink',
    FOOD: 'Food',
    DISCOUNT_CODE: 'Discount Code',
    FOLLOW: 'Follow us',
    SAVE_CARD_QUESTION: 'Add cards for future purchases.',
    SAVE_CARD_QUESTION_HEADER: 'Save your card?',
    YOUR_ORDER: 'Your order',
    // Reepay charge errors:
    ACQUIRER_ERROR: 'Something went wrong with your purchase. Please contact the restaurant ',
    INSUFFICIENT_FOUNDS: 'Failed due to insufficient funds.',
    PAYMENT_NOT_AUTHORIZED: 'Something went wrong with your purchase. Please contact the restaurant ',
    CREDIT_CARD_EXPIRED: 'Your card has expired. Please try another ',
    DECLINED_BY_ACQUIRER: 'Something went wrong with your purchase. Please contact the restaurant ',
    CREDIT_CARD_LOST_OR_STOLEN: 'This card is marked as stolen. Your ip has been logged ',
    CREDIT_CARD_SUSPECTED_FRAUD: 'Something went wrong with your purchase. Please contact the restaurant ',
    REFOUND_AMOUNT_TOO_HIGH: 'Something went wrong with your purchase. Please contact the restaurant ',
    AUTHORIZATION_EXPIRED: 'Something went wrong with your purchase. Please contact the restaurant ',
    AUTHORIZATION_AMOUNT_EXCEEDED: 'Something went wrong with your purchase. Please contact the restaurant ',
    AUTHORIZATION_VOIDED: 'Something went wrong with your purchase. Please contact the restaurant ',
    SCA_REQUIRED: 'Something went wrong with your purchase. Please contact the restaurant ',
    RISK_FILTER_BLOCK: 'Something went wrong with your purchase. Please contact the restaurant ',
    PAYMENT_AND_ORDER_IS_PLACED: "Your order is paid and ordered",
    DONT_CLOSE_WEB_BROWSER: "NOTE! Do not close the browser!",
    ORDER_INFORMATION: "<p> When the order is complete, a dialog will appear in the browser. </p> <p> Your receipt will be located under <b> My Receipts </b> and it is possible to email to the desired email address. </ p > <p> Your order is displayed under active orders and you will be automatically sent there when you acknowledge this dialogue. </p> ",


    // VIPPS
    VIPPS_ERROR: "Vipps nekade din betalning.",
    VIPPS_CANCELLED: "Användare avbröt betalningen",
    VIPPS_PAYMENT_41: "The user does not have a valid card",
    VIPPS_PAYMENT_42: "Refused by issuer bank",
    VIPPS_PAYMENT_43: "Refused by issuer bank because of invalid a amount",
    VIPPS_PAYMENT_44: "Refused by issuer because of expired card",
    VIPPS_PAYMENT_45: "Reservation failed. Either because the user cancelled, did not act, or for some unknown reason.",
    VIPPS_PAYMENT_51: "Cannot cancel an already captured order",
    VIPPS_PAYMENT_52: "Cancellation failed",
    VIPPS_PAYMENT_53: "Cannot cancel an order that is not reserved",
    VIPPS_PAYMENT_61: "Captured amount exceeds the reserved amount",
    VIPPS_PAYMENT_62: "The amount you tried to capture is not reserved",
    VIPPS_PAYMENT_63: "Capture failed for some unknown reason, please use /details endpoint to know the exact status",
    VIPPS_PAYMENT_71: "Cannot refund more than captured amount",
    VIPPS_PAYMENT_72: "Cannot refund a reserved order (only captured orders), please use the /cancel endpoint",
    VIPPS_PAYMENT_73: "Cannot refund a cancelled order",
    VIPPS_PAYMENT_93: "Captured amount must be the same in an Idempotent retry",
    VIPPS_PAYMENT_95: "Payments can only be refunded up to 365 days after reservation",
    VIPPS_PAYMENT_96: "Payments can only be captured up to 180 days after reservation",
    VIPPS_PAYMENT_1501: "This person can not pay to companies. Used for Vipps users under the age of 15.",
    VIPPS_PAYMENT_1082: "This person is not BankID verified. Only applies for test users.",
    VIPPS_VIPPSERROR_91: "Transaction is not allowed",
    VIPPS_VIPPSERROR_92: "Transaction already processed",
    VIPPS_VIPPSERROR_94: "Order locked and is already processing. This can occur if a bank has problems, and Vipps needs to wait and/or clean up.",
    VIPPS_VIPPSERROR_98: "Too many concurrent requests. Used only to prevent incorrect API use.",
    VIPPS_VIPPSERROR_99: "Description of the internal error",
    VIPPS_USER_81: "User not registered with Vipps",
    VIPPS_USER_82: "User app version not supported",
    VIPPS_MERCHANT_31: "Merchant is blocked because of",
    VIPPS_MERCHANT_32: "Receiving limit of merchant is exceeded",
    VIPPS_MERCHANT_33: "Number of payment requests has been exceeded",
    VIPPS_MERCHANT_34: "Unique constraint violation of the orderId",
    VIPPS_MERCHANT_35: "Registered order not found",
    VIPPS_MERCHANT_36: "Merchant agreement not signed",
    VIPPS_MERCHANT_37: "Merchant not available, deactivated or blocked. See the FAQ.",
    VIPPS_MERCHANT_38: "Sale unit is not allowed to skip the landing page",
    VIPPS_MERCHANT_21: "Reference orderId is not valid",
    VIPPS_MERCHANT_22: "Reference orderId is not in valid state",
    VIPPS_MERCHANT_97: "The merchant is not approved by Vipps to receive payments",

    // Swish
    SWISH_ERROR_FF08: 'Swish denied your payment.', // PaymentReference is invalid.
    SWISH_ERROR_RP03: 'Swish denied your payment.', // Callback URL is missing or does not use HTTPS.
    SWISH_ERROR_BE18: 'Swish denied your number, make sure you entered the correct number.', // Payer alias is invalid.
    SWISH_ERROR_RP01: 'Swish denied your payment.', // Missing Merchant Swish Number.
    SWISH_ERROR_PA02: 'Swish refused the payment because the amount is not valid.', // Amount value is missing or not a valid number.
    SWISH_ERROR_AM02: 'Swish refused the payment because the amount is too large.', // Amount value is too large..
    SWISH_ERROR_AM03: 'Swish declined payment due to missing or unsupported currency.', // Invalid or missing Currency.
    SWISH_ERROR_AM06: 'Swish refused the payment, because the amount is less than the agreed limit.', // Specified transaction amount is less than agreed minimum.
    SWISH_ERROR_AM21: 'Swish refused the payment, because the amount is greater than what your bank allows.', // Transaction amount exceeds Swish limit agreed between bank and payer for given period. Please inform the payer to contact their bank for more information on how to adjust the Swish limits.
    SWISH_ERROR_RP02: 'Swish denied your payment.', // Wrong formatted message..
    SWISH_ERROR_RP06: 'Swish declined the payment because it already has another active payment, please cancel and try again.', // A payment request already exists for that payer. Only applicable for Swish e-commerce.

    SWISH_ERROR_RF07: 'Swish refused the payment, make sure you have money available for Swish.', // Transaction declined.
    SWISH_ERROR_ACMT03: 'Swish declined the payment because your number does not appear to be activated for Swish. ', // Payer not Enrolled.
    SWISH_ERROR_ACMT01: 'Swish denied the payment.', // Counterpart is not activated.
    SWISH_ERROR_ACMT07: 'Swish declined the payment because your number does not appear to be activated for Swish.', // Payee not Enrolled.
    SWISH_ERROR_VR01: 'Swish refused the payment, because this Swish account does not meet the age requirement for this purchase.', // Payer does not meet age limit.
    SWISH_ERROR_VR02: 'Swish denied payment', // The payer alias in the request is not enrolled in swish with the supplied ssn.
    SWISH_ERROR_TM01: 'Swish refused the payment, because you did not complete the payment in Swish within the set time limit.',// Swish timed out before the payment was started
    SWISH_ERROR_BANKIDCL: 'Swish refused the payment because you canceled the BankID signing.', // Payer cancelled BankID signing
    SWISH_ERROR_BANKIDONGOING: 'Swish refused the payment, because you already have another BankID signing active, end it and try again ..', // BankID already in use.
    SWISH_ERROR_BANKIDUNKN: 'Swish refused the payment, because BankID could not authorize your payment.', // BankID is not able to authorize the payment..

    SWISH_ERROR_CONNECTION: 'There were problems in the contact during payment. Verify that your order is confirmed, otherwise order again.',

    SWISH_CANCELLED: 'Payment was canceled.', // Unhandled error in PubQ backend
    SWISH_DECLINED: 'Payment was canceled.', // Unhandled error in PubQ backend

    SWISH_ERROR: 'Swish denied your payment.', // Unhandled error in PubQ backend

    SWISH_DEMAND_NUMBER: '<p> You must enter a valid number to be able to pay. </p> <p> Your order will be notified by SMS when it is ready. </p>',

    WAITING_ON_SWISH_PAYMENT: 'Pay for your order in the Swish app"',


    SHOW_ORDERS: 'Show orders',
    VERIFY_TIMEOUT: '<p> Your payment was canceled because you did not complete the payment within the set time limit. </p>',
    SHOW_CART: 'Place order',
    CLEARE: 'Clear',
    SHOW_YOUR_ORDERS: 'Show your orders',
    NO_ACTIVE_ORDERS: 'No active orders',
    PREVIOUS_PURCHASES: 'See previous purchases',
    DELIVERY_OPTIONS: 'Delivery options',


    //NOT ACTIVATED COMPONENT
    UNIT_NOT_ACTIVATED: 'This unit is not yet activated for web ordering!',
    CONTACT_ADMIN_UNIT: 'Please contact administrator to activate the unit.',
    NO_CLIENT_DATA: 'No client data or defined units for client.',

    ABLE_ORDER_ONLINE: "Can be ordered online.",
    ALLOW_LOCATION: "Please allow Location access and refresh the page!",
    CONFIRMED: "Confirm",
    CONTACT_SUPPORT_SWISH: "Missing data to be able to make a Swish payment, contact support.",
    CONTACT_ADMONISTRATOR: "Please contact administrator to activate the unit.",
    CONTACT_SUPPORT_PAYMENT: "Lack of data to be able to make a payment, contact support",
    CANCEL_SWISH_VERIFY: 'Do not cancel if you have completed the payment. Do you want to cancel?',
    INSERT_CARD_: "Insert card again",
    LOGIN_MOBILE_NEEDED: 'Your number is needed in case we need to contact you',
    NOTE: "Note",
    NOT_ACTICATED_FOR_WEB_ORDERING: "This unit is not yet activated for web ordering!",
    NEED_TO_INSERT_CARD_AGAIN: "You need to insert the card again to continue the payment",
    ORDERING_NOT_POSSIBLE: 'Unable to order now',
    PENDING_ORDER: 'Pending orders',
    TO_HOMEPAGE: 'Homepage',
    UNABLE_TO_ORDER: "Unable to order online -",
    OUR_POLICY: 'Our policy',
    PAYMENT_PLEASE_SELECT_PAYMENT_METHOD: 'Select payment method'
}
