import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { PubqConfig, PubqConfigProvider } from "../pubq-config/pubq-config";
import { PaymentV2Response } from "./payment";


@Injectable({
    providedIn: "root"
})
export class PaymentUtils {

    private retryingEnabled: boolean = true;

    constructor(
        private http: HttpClient,
        private config: PubqConfigProvider
    ) {

    }

    async initiatePaymentWithRetry(body: any, config?: PubqConfig, http?: HttpClient) {
        this.retryingEnabled = true;
        let resp: PaymentV2Response = undefined;
        let finished = false;
        let retry = 0;
        let error = null;
        let url = `${environment.apiUrl}/v1/idempotency/initiate`;

        console.log("Initiating...")
        while (!finished && this.retryingEnabled) {
            try {
                resp = await this.http.post<PaymentV2Response>(url, body).toPromise();

                error = null;
                if (resp.success || resp.error) {
                    console.log("Initiate done.")
                    finished = true;
                }
            } catch (err) {
                error = err;
                console.log(err)
            }

            retry++;
            if (retry > 120 || finished) { // Wait max for 2 minutes
                finished = true;
            } else {
                console.log("Retry in 1sec (" + retry + "/120)...")
                await this.delay(1000);
            }
        }
        if (error) {
            resp = new PaymentV2Response;
            resp.error = {
                showResponseText: false,
                languageCode: 'SWISH_ERROR_CONNECTION',
                responseText: error.message,
                resetOrder: true
            }
        }
        // Handling if the retry count exceeds 120 retries
        else if (retry > 120) {
            resp = new PaymentV2Response;
            resp.error = {
                showResponseText: false,
                languageCode: 'SWISH_CANCELLED',
                responseText: '',
            }
        }

        return resp;
    }

    async chargePaymentWithRetry(body: any) {
        this.retryingEnabled = true;
        let resp: PaymentV2Response = undefined;
        let finished = false;
        let retry = 0;
        let error = null;
        let url = `${environment.apiUrl}/v1/idempotency/charge`;

        console.log("Charging...")
        while (!finished && this.retryingEnabled) {
            try {
                resp = await this.http.post<PaymentV2Response>(url, body).toPromise();

                error = null;
                if (resp.success || resp.error) {
                    console.log("Charge done.")
                    finished = true;
                }
            } catch (err) {
                error = err;
                console.log(err)
            }

            retry++;
            if (retry > 120 || finished) { // Wait max for 2 minutes
                finished = true;
            } else {
                console.log("Retry in 1sec (" + retry + "/120)...")
                await this.delay(1000);
            }
        }

        if (error) {
            resp = new PaymentV2Response;
            resp.error = {
                showResponseText: false,
                languageCode: 'SWISH_ERROR_CONNECTION',
                responseText: error.message,
                resetOrder: true
            }
        }
        // Handling if the retry count exceeds 120 retries
        else if (retry > 120) {
            resp = new PaymentV2Response;
            resp.error = {
                showResponseText: false,
                languageCode: 'SWISH_CANCELLED',
                responseText: '',
            }
        }

        return resp;
    }

    async verifyPaymentWithRetry(idempotencyKey: string, routeName: string, config: PubqConfig, http: HttpClient, retryMaxCount?: number): Promise<PaymentV2Response> {
        this.retryingEnabled = true;
        let resp: PaymentV2Response = undefined;
        let finished = false;
        let _retryMaxCount: number = retryMaxCount >= 0 ? retryMaxCount : 120;
        let retry = 0;
        let error = null;
        const body = {
            idempotencyKey: idempotencyKey,
            routeName: routeName,
        };
        let url = `${environment.apiUrl}/v1/idempotency/verify/v2`;
        console.log("Verifying...")
        while (!finished && this.retryingEnabled) {
            try {

                resp = await http.post<PaymentV2Response>(url, body).toPromise();

                error = null;
                if (resp.success || resp.error) {
                    console.log("Verify done.")
                    finished = true;
                }
            } catch (e) {
                error = e;
            }
            retry++;
            if (retry > _retryMaxCount || finished) { // Wait max for 2 minutes
                finished = true;
            } else {
                console.log("Retry in 1sec (" + retry + `/${_retryMaxCount})...`)
                await this.delay(1000);
            }
        }
        //this.retryingEnabled = false;
        if (error) {
            resp = new PaymentV2Response;
            resp.error = {
                showResponseText: false,
                languageCode: 'SWISH_ERROR_CONNECTION',
                responseText: error.message,
                resetOrder: true
            }
        }
        // Handling if the retry count exceeds 120 retries
        else if (retry > _retryMaxCount && _retryMaxCount !== 0) {
            resp = new PaymentV2Response;
            resp.error = {
                showResponseText: false,
                languageCode: 'SWISH_CANCELLED',
                responseText: '',
            }
        }
        return resp;
    }

    cancelRetrying() {
        this.retryingEnabled = false;
    }

    private delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}