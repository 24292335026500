import { Injectable } from '@angular/core';
import { Observable, pipe } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Order, ActiveOrder } from '../order/order';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { ContextProvider, ClientInfo } from '../context/context';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AlertController, LoadingController, NavController, Platform, ToastController } from '@ionic/angular';
import { CartItem } from 'src/app/cart-items/cart-items';
import { AppConfiguration } from 'src/app/app.configuration';
import { BonusProvider } from '../bonus/bonus';
import { Storage } from '@ionic/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { UnitProvider } from '../unitProvider';
import { LanguageProvider } from '../language/language.provider';
import * as moment from 'moment';
import { LanguagePipe } from '../../pipes/language.pipe';
import { AnalyticsService } from '../analytics/analytics';
declare var cordova;
@Injectable({
  providedIn: 'root',
})

export class UserProvider {
  private deviceToken = 'NOT_SET';
  private currentUserValue = new BehaviorSubject<User>(null);
  public activeOrdersValue = new BehaviorSubject(null);
  private activeTableBookingValue = new BehaviorSubject(null);
  private bonusPoolValue = new BehaviorSubject(null);
  private tableBookingSubscription: Subscription;
  private activeOrdersSubscription: Subscription;
  private subscription = new Subscription();
  ordersNotifications = new Set();
  userOrigin = AppConfiguration.userOrigin;

  ordersToast = null;

  constructor(
    // private firebaseX: FirebaseX,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private platform: Platform,
    private contextProvider: ContextProvider,
    private storage: Storage,
    private bonusProvider: BonusProvider,
    private nav: NavController,
    private unitProvider: UnitProvider,
    private loadingController: LoadingController,
    private alertCtrl: AlertController,
    private languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    private analyticsService: AnalyticsService,
    public toastController: ToastController
  ) {
    this.platform.ready().then(async () => {
      await firebase.auth().setPersistence('local');
      this.registerAuthState();
      this.subscription.add(
        this.contextProvider.clientContext.currentClient.subscribe(
          (x: ClientInfo) => {
            if (!x) {
              this.activeOrdersValue.next(null);
              this.activeTableBookingValue.next(null);
              this.bonusPoolValue.next(null);
              return;
            }
            this.setupActiveOrders();
            this.setupTableBooking();
            this.setupLanguage();

            console.log(this.currentUser.value)

          }
        ));


    });

    this.subscription.add(
      this.contextProvider.clientContext.appInfo.subscribe((appInfo) => {
        if (appInfo && appInfo.Context.paymentProvider) {
          if (!AppConfiguration.originalPaymentProvider)
            AppConfiguration.originalPaymentProvider = AppConfiguration.paymentProvider;
          AppConfiguration.paymentProvider = appInfo.Context.paymentProvider;
        }
      })
    )
  }

  ngOnDestroy() {

  }

  get activeTableBooking() {
    return this.activeTableBookingValue;
  }
  get currentUser() {
    return this.currentUserValue;
  }
  get activeOrders() {
    return this.activeOrdersValue;
  }
  get bonusPool() {
    return this.bonusPoolValue;
  }
  setupLanguage() {
    const user = this.currentUserValue.getValue();
    if (user && user.preferedLanguage) {
      this.languageProvider.setLanguage(user.preferedLanguage);
    }
  }
  setupActiveOrders() {
    if (this.activeOrdersSubscription) {
      this.activeOrdersSubscription.unsubscribe();
    }
    const user = this.currentUserValue.getValue();
    if (!user) {
      this.activeOrdersValue.next(null);
      return;
    }
    this.activeOrdersSubscription = this.db
      .list<any>('Users/' + user.id + '/activeOrders')
      .snapshotChanges()
      .pipe(
        map((x) => {
          return x
            .filter((o) => {
              const payload = o.payload.val();
              return (
                payload && payload.order && payload.order.client && payload.order.client.routeName
              );
            })
            .map((c) => ({ key: c.payload.key, ...c.payload.val() }));
        })
      )
      .subscribe((mapped) => {
        this.activeOrdersValue.next(mapped);
        // this.showNotification(mapped);
      });
  }

  async showNotification(orders: ActiveOrder[]) {
    // let path = `active-orders`;
    const currentPathName = window.location.pathname;
    // const pathParts = currentPathName.split("/");
    // if(pathParts.length >= 5){
    //   const client = pathParts[2];
    //   const city = pathParts[3];
    //   const routeName = pathParts[4];
    //   path = `u/${routeName}?showActiveOrder=true`
    // }
    if (!this.contextProvider || !this.contextProvider.client || !this.contextProvider.client.routeName) {
      return;
    }

    const completedOrders = orders.filter(order => order.completed === 'completed' || order.completed === 'completedAndAccepted');
    const completedDrinks = orders.filter(order => order.drinksCompleted === 'completed' || order.drinksCompleted === 'completedAndAccepted');
    const completedFood = orders.filter(order => order.foodCompleted === 'completed' || order.foodCompleted === 'completedAndAccepted');

    console.dir(completedOrders);
    console.dir(orders);
    if (completedOrders.length > 0 || completedDrinks.length > 0 || completedFood.length > 0) {

      if (!this.ordersToast) {
        this.ordersToast = await this.toastController.create({
          header: completedOrders.length.toString()
          ,
          message: 'Mat klart',
          position: 'top',
          buttons: [
            {
              side: 'start',
              icon: 'close-outline',
              role: 'cancel',
              handler: () => {
                console.log('Favorite clicked');
              }
            }, {
              text: 'Show',
              icon: 'chevron-forward-outline',
              role: 'show',
              handler: () => {
                console.log('Cancel clicked');
              }
            }
          ]
        });
      }

      this.toastController.dismiss();
      await this.ordersToast.present();

      const { role } = await this.ordersToast.onDidDismiss();
      if (role === "show") {
        this.nav.navigateForward([`/u/${this.contextProvider.client.routeName}`], {
          queryParams: {
            showActiveOrder: true
          }
        });
      }
      console.log('onDidDismiss resolved with role', role);
    } else {
      if (this.toastController) this.toastController.dismiss();
    }

    // console.dir(window.location)

    // const completedOrders = orders.filter(order => order.completed === 'completed' || order.completed === 'completedAndAccepted');
    // for (const order of completedOrders) {
    //   if (!this.ordersNotifications.has(order.queueId) && (currentPathName.includes('units') || currentPathName.includes('unitselection') || currentPathName.includes('main')  || currentPathName.includes('restaurant'))) {
    //     this.ordersNotifications.add(order.queueId);

    //     if(!window.location.search.includes("showActiveOrder"))
    //       this.checkCompletedOrders(`u/${this.contextProvider.client.routeName}/order/${order.queueId}`);
    //   }
    // }
  }

  async checkCompletedOrders(pathName) {

    const confirm = await this.alertCtrl.create({
      header: this.languagePipe.transform('Din order är färdig!', 'COMPLETE_ORDER_TITLE', this.languageProvider.currentLanguage),
      subHeader: this.languagePipe.transform('Vill du öppna dina aktiva ordrar?',
        'COMPLETE_ORDER_SUBHEADER', this.languageProvider.currentLanguage),
      buttons: [
        {
          text: this.languagePipe.transform('Nej', 'NO', this.languageProvider.currentLanguage),
          handler: () => {
            return null;
          }
        },
        {
          text: this.languagePipe.transform('Ja', 'YES', this.languageProvider.currentLanguage),
          handler: async () => {
            // if (pathName === '/unitselection/all') {
            //   await this.nav.navigateForward(['pathName'], {
            //     queryParams: {
            //       showActiveOrder:true
            //     }
            //   });
            // } else {
            await this.nav.navigateRoot([pathName], {
              queryParams: {
                showActiveOrder: true
              }
            });
            // }
          }
        }
      ],
      backdropDismiss: false
    });
    await confirm.present();
  }

  async setLocalBooking(bookingId, routeName) {
    const data = {
      bookingId,
      routeName,
    };
    const dataString = JSON.stringify(data);
    const storageKey = `booking_${routeName}`;
    try {
      await this.storage.set(storageKey, dataString);
    } catch (err) {
      alert(JSON.stringify(err));
    }
    return data;
  }

  async getLocallySavedBooking(
    routeName
  ): Promise<{ routeName: string; bookingId: string }> {
    const bookingKey = `booking_${routeName}`;
    try {
      const dataString = await this.storage.get(bookingKey);
      return JSON.parse(dataString);
    } catch (error) {
      alert(JSON.stringify(error));
    }
  }

  async deleteLocallySavedBooking(routeName): Promise<boolean> {
    const bookingKey = `booking_${routeName}`;
    try {
      await this.storage.remove(bookingKey);
      return true;
    } catch (error) {
      alert(JSON.stringify(error));
      return true;
    }
  }

  async setupBonusPool() {
    const user = this.currentUserValue.getValue();
    const context = this.contextProvider.clientContext.currentClient.value;
    if (!user || !context) {
      this.bonusPoolValue.next(null);
      return;
    }
    const possibleBonusPool = await this.bonusProvider.getBonus(context.routeName, user.id);
    this.bonusPoolValue.next(possibleBonusPool);
  }

  async setupTableBooking() {
    if (this.tableBookingSubscription) {
      this.tableBookingSubscription.unsubscribe();
    }
    const client = this.contextProvider.clientContext.currentClient.getValue();
    const routeName = client ? client.routeName : '';
    const user = this.currentUserValue.value;
    if (!routeName) {
      this.activeTableBookingValue.next(null);
      return;
    }
    if (!user) {
      const data = await this.getLocallySavedBooking(routeName);
      if (data) {
        this.startActiveTableBookingObservable(data.routeName, data.bookingId);
        return;
      }
      this.activeTableBookingValue.next(null);
      return;
    }
    const activeTableBookingId = user && user.activeTableBooking ? user.activeTableBooking.routeName : '';
    if (!activeTableBookingId) {
      const data = await this.getLocallySavedBooking(routeName);
      if (data) {
        this.setUserTableBooking(user, routeName, data.bookingId);
        this.startActiveTableBookingObservable(routeName, activeTableBookingId);
        this.deleteLocallySavedBooking(routeName);
        return;
      }
      this.activeTableBookingValue.next(null);
      return;
    } else {
      this.startActiveTableBookingObservable(routeName, activeTableBookingId);
    }
  }

  private startActiveTableBookingObservable(routeName, activeTableBookingId) {
    this.tableBookingSubscription = this.db
      .object<any>(`tablebookings/${routeName}/${activeTableBookingId}`)
      .snapshotChanges()
      .pipe(
        map((ev) => {
          return { bookingId: ev.key, ...ev.payload.val() };
        })
      )
      .subscribe((x) => {
        if ((x && x.canceled && x.canceled.value) || !x.bookingId || x.closed) {
          this.activeTableBookingValue.next(null);
          return;
        }
        this.activeTableBookingValue.next(x);
      });
  }

  async removeTableBooking(user: User, routeName) {
    await this.db.database
      .ref(`Users/${user.id}/activeTableBooking/${routeName}`)
      .set(null);
    this.setupTableBooking();
  }

  removeActiveOrder(user: User, activeOrder: ActiveOrder): Promise<void> {
    if (!user || !activeOrder.key) {
      return;
    }
    const itemsRef = this.db.list('Users/' + user.id + '/activeOrders');
    return itemsRef.remove(activeOrder.key);
  }

  removeActiveTab(user: User, activeTab) {
    if (!user || !activeTab.key) {
      return;
    }
    return this.db.database
      .ref(`Users/${user.id}/activeTabs/${activeTab.key}`)
      .set(null);
  }

  updateActiveOrder(user: User, activeOrder: ActiveOrder): Promise<void> {
    if (!user || !activeOrder.key) {
      return;
    }
    return this.db
      .object('Users/' + user.id + '/activeOrders/' + activeOrder.key)
      .update(activeOrder);
  }

  async updateAcceptedFoodOrder(
    user: User,
    activeOrder: ActiveOrder
  ) {
    try {
      await this.db
        .object(
          'Users/' +
          user.id +
          '/activeOrders/' +
          activeOrder.queueId
        )
        .update({ foodCompleted: 'completedAndAccepted' });
    } catch (err) {
      alert(err);
    }

    return
  }

  async updateAcceptedDrinksOrder(
    user: User,
    activeOrder: ActiveOrder
  ) {
    try {
      await this.db
        .object(
          'Users/' +
          user.id +
          '/activeOrders/' +
          activeOrder.queueId
        )
        .update({ drinksCompleted: 'completedAndAccepted' });
    } catch (err) {
      alert(err);
    }
    return;
  }

  updateAcceptedOrderItems(
    user: User,
    activeOrder: ActiveOrder,
    foodItems: CartItem[],
    drinkItems: CartItem[]
  ): Promise<void[]> {
    if (!user || !activeOrder.key) {
      return;
    }
    const foodPromises = foodItems.map((completedItem) => {
      const index = activeOrder.order.order.food.findIndex(
        (x) => x.cartIndex === completedItem.cartIndex
      );
      return this.setItemCompleted(user.id, activeOrder.key, 'food', index);
    });
    const drinkPromises = drinkItems.map((completedItem) => {
      const index = activeOrder.order.order.drinks.findIndex(
        (x) => x.cartIndex === completedItem.cartIndex
      );
      return this.setItemCompleted(user.id, activeOrder.key, 'drinks', index);
    });


    return Promise.all(foodPromises.concat(drinkPromises));
  }
  private setItemCompleted(userId, orderKey, type, index) {
    return this.db
      .object(
        'Users/' +
        userId +
        '/activeOrders/' +
        orderKey +
        '/order/order/' +
        type +
        '/' +
        index
      )
      .update({ completed: 'completedAndAccepted' });
  }

  registerAuthState() {
    firebase.auth().onAuthStateChanged((state) => {
      if (state) {
        this.getUser(state.uid).subscribe((x: User) => {
          // if (x.lastName && x.firstName) {
          x.isAnonymous = state.isAnonymous;
          this.currentUser.next(x);
          this.setupActiveOrders();
          this.setupTableBooking();
          this.setupBonusPool();
          if (!state.isAnonymous) {
            this.analyticsService.trackEvent('USER_SIGNIN', { user: x });
          }
          // }
        });
        // } else {
        //   this.currentUser.next(null);
        //   this.activeOrders.next(null);
      }
    });
  }

  async subscribeToTopic(clientRoute) {
    if (typeof cordova === 'undefined') { return; }
    try {
      // await this.firebaseX.getToken();
      // await this.firebaseX.subscribe(`${clientRoute}General`);
      let topics = await this.storage.get('subscribedTopics');
      if (!topics) {
        topics = [clientRoute];
      } else {
        topics.push(clientRoute);
      }
      await this.storage.set('subscribedTopics', topics);
      return true;
    } catch (error) {
      // alert(`Error when fetching topics ${JSON.stringify(error)}`);
      return error;
    }
  }

  async isSubscribedToTopic(routeName) {
    if (typeof cordova === 'undefined') { return; }
    const topics = await this.storage.get('subscribedTopics');
    if (!topics) { return false; }
    const topic = topics.find(x => x === routeName);
    if (topic) {
      return true;
    }
    return false;
  }

  async unsubscribeFromTopic(routeName) {
    // this.firebaseX.unsubscribe(`${routeName}General`);
    let topics = await this.storage.get('subscribedTopics');
    if (!topics) {
      return true;
    } else {
      topics = topics.filter(x => x !== routeName);
      await this.storage.set('subscribedTopics', topics);
      return true;
    }
  }

  async getSubscriptions() {
    return await this.storage.get('subscribedTopics');
  }

  async requestPushPermission() {
    await this.storage.set('hasSeenPushPermissionRequest', true);
    try {
      // await this.firebaseX.grantPermission();

      return true;
    } catch (error) {
      return false;
    }

  }
  async hasPushPermission() {
    // return await this.firebaseX.hasPermission();
  }

  async hasSeenPushPermissionRequest() {
    return await this.storage.get('hasSeenPushPermissionRequest');
  }

  async receiveMessages() {
    // await this.platform.ready();
    // this.firebaseX.onMessageReceived().subscribe(async message => {
    //   if (message.messageType === 'notification') {
    //     if (message.tap && message.route) {
    //       const loading = await this.loadingController.create({ spinner: 'dots' });
    //       loading.present();
    //       const sub = this.unitProvider.getUnits(message.city).subscribe(x => {
    //         if (!x) { return; }
    //         loading.dismiss();
    //         const restaurant = x.find(x => x.routeName === message.route);
    //         this.contextProvider.init(restaurant);
    //         this.nav.navigateForward(['main/tabs/tab1']);
    //         setTimeout(() => {
    //           sub.unsubscribe();
    //         }, 100);
    //       });
    //     }
    //   }
    // });
  }

  async registerToken() {
    if (typeof cordova === 'undefined') { return; }
    // const hasPermission = await this.firebaseX.hasPermission();
    // if (!hasPermission) {
    //   await this.firebaseX.grantPermission();
    // }
    // this.firebaseX
    //   .getToken()
    //   .then((token) => {
    //     this.deviceToken = token;
    //
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // this.firebaseX.onTokenRefresh().subscribe(async (nothing) => {
    //   this.deviceToken = await this.firebaseX.getToken();
    // });
  }

  registerDevice() {
    if (typeof cordova === 'undefined') { return; }
    this.registerToken();
  }

  signInWithFacebook(token) {
    return firebase
      .auth()
      .signInWithCredential(
        firebase.auth.FacebookAuthProvider.credential(token)
      );
  }

  signInWithEmail(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  signInAnonymously() {
    return firebase.auth().signInAnonymously();
  }

  async createAnonymousUser() {
    try {
      const user = firebase.auth().currentUser;
      if (!user) {
        const res = await this.signInAnonymously();

        console.log("Get data from database");

        // Check if user exists in database
        let sub = this.getUser(res.user.uid).subscribe(async (data) => {
          if (data) {
            console.log("Got user info from database")
            this.currentUser.next(data);
          } else { // If user did not exist 
            console.log("Add empty data to user");
            await this.updateUser({
              id: res.user.uid,
              origin: this.userOrigin,
              createdDate: moment().toISOString(),
              // deviceToken: this.getDeviceToken(),
              firstName: '',
              lastName: '',
              email: ''
            });
          }
          sub.unsubscribe();
        });
      }
    } catch (err) {
      console.log('anon login failed', err);
    }
  }

  signOut() {
    this.currentUser.next(null);
    this.activeOrders.next(null);
    return firebase
      .auth()
      .signOut()
      .catch((error) => { });
  }

  createAccount(user: UserCreate): Promise<firebase.auth.UserCredential> {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password);
  }

  updateAnonymousAccount(user: any): Promise<firebase.auth.UserCredential> {
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, user.password);
    return firebase.auth().currentUser.linkWithCredential(credential);
  }

  updateUser(user: User): Promise<any> {
    if (!user) { throw new Error('User can not be null when updating it'); }
    if (!user.id) { throw new Error('User must have an id when updating it'); }
    const itemRef = this.db.object('Users/' + user.id);
    // if (user.email)
    //   firebase.auth().currentUser.updateEmail(user.email);
    // if (user.phoneNumber)
    //   firebase.auth().currentUser.updatePhoneNumber(user.phoneNumber);
    //firebase.auth().currentUser.updateProfile({displayName: user.firstName + ' ' + user.lastName})
    return itemRef.update(user).then((x) => {
      this.currentUser.next(user);
    });
  }

  updateUserDestructive(user: User): Promise<any> {
    if (!user) { throw new Error('User can not be null when updating it'); }
    if (!user.id) { throw new Error('User must have an id when updating it'); }
    const itemRef = this.db.object('Users/' + user.id);
    return itemRef.set(user);
  }

  getUser(id): Observable<{}> {
    return this.db
      .object<any>('Users/' + id)
      .snapshotChanges()
      .pipe(
        map((x) => {
          return { id: x.payload.key, ...x.payload.val() };
        })
      );
  }

  /**
   * Move user data from old UID to new UID.
   * When anonymous user register via Facebook.
   */
  async moveUserData(oldUserId, newUserId) {
    // Move User data
    const oldRef = this.db.database.ref('Users/' + oldUserId);
    const newRef = this.db.database.ref('Users/' + newUserId);
    await this.moveFirebaseObject({ oldRef, newRef, oldUserId, newUserId });
    // Move orders history
    const oldRefHist = this.db.database.ref('usersPurchaseHistory_v2/' + oldUserId);
    const newRefHist = this.db.database.ref('usersPurchaseHistory_v2/' + newUserId);
    await this.moveFirebaseObject({ oldRef: oldRefHist, newRef: newRefHist, oldUserId, newUserId });
    // Change user UID in active orders queue
    await this.updateOrdersQueue({ newUserId, newRef });
  }

  async updateOrdersQueue({ newUserId, newRef }) {
    try {
      const snapshot = await newRef.once('value');
      const data = snapshot.val();
      if (!data || !data.activeOrders || !Object.keys(data.activeOrders)) {
        return;
      }
      let order: any;
      for (order of Object.values(data.activeOrders)) {
        if (order.completed === 'completed' || order.completed === 'completedAndAccepted') {
          continue;
        }
        await this.db.database.ref(`Clients/${order.order.client.routeName}/orderQueue/${order.queueId}/userId`).set(newUserId);
      }
    } catch (err) {
      console.log('updateOrdersQueue error:', err);
    }
  }

  /**
   * Copy firebase object from old ID to new ID.
   * Delete old ID.
   */
  moveFirebaseObject({ oldRef, newRef, oldUserId, newUserId }) {
    return new Promise<void>((resolve, reject) => {
      oldRef.once('value').then(snapshot => {
        try {
          const data = snapshot.val();
          if (!data) {
            return resolve();
          }
          const dataWithNewId = JSON.parse(JSON.stringify(data).split(oldUserId).join(newUserId));
          newRef.set(dataWithNewId);
          oldRef.remove();
          resolve();
        } catch (e) {
          reject(e);
        }
      }, err => {
        reject(err);
      });
    });
  }

  async getDeviceToken() {
    try {
      // return await this.firebaseX.getToken();
    } catch (error) {
      return null;
    }
  }
  resetPassword(email: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then((x) => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  }
  removePaymentSource(user: User, source: UserPaymentSource) {
    let itemRef;
    itemRef = this.db.list(
      `Users/${user.id}/billingInfo/paymentSources/${AppConfiguration.paymentProvider}`
    );
    itemRef.remove(source.key);
  }
  getUserPaymentSources(id) {
    if (!id) { throw new Error('id can not be null'); }
    return this.db
      .list<any>(
        `Users/${id}/billingInfo/paymentSources/${AppConfiguration.paymentProvider}`
      )
      .snapshotChanges()
      .pipe(
        map((x) => {
          return x.map((c) => ({ key: c.payload.key, ...c.payload.val() }));
        })
      );
  }
  addPaymentSource(user: User, paymentSource: UserPaymentSource) {
    const itemRef = this.db.list(
      `Users/${user.id}/billingInfo/paymentSources/${AppConfiguration.paymentProvider}`
    );
    return itemRef.push(paymentSource);
  }

  async getPreferedLanguage(user: User) {
    const lang = (await firebase
      .database()
      .ref(
        `Users/${user.id}/preferedLanguage`
      ).once('value')).val();
    return lang;
  }

  setPreferedLanguage(user: User, languageCode: string) {
    firebase
      .database()
      .ref((
        `Users/${user.id}/preferedLanguage`
      )).set(languageCode);
  }

  async getUserPaymentSourcesV2(id): Promise<Observable<any>> {
    if (!id) throw "id can not be null";

    return this.db
      .list<any>(
        `Users/${id}/billingInfo/paymentSources/${AppConfiguration.paymentProvider}`
      )
      .snapshotChanges()
      .pipe(
        map((x) => {
          return x.map((c) => ({ key: c.payload.key, data: { ...c.payload.val() } }));
        })
      );
  }
  async getUserPaymentSourcesV2Async(id): Promise<any> {
    if (!id) throw "id can not be null";
    return new Promise(async (resolve, reject) => {
      let obs = await this.getUserPaymentSourcesV2(id)
      var sub = obs.subscribe(x => {
        resolve(x);
        setTimeout(() => {
          sub.unsubscribe();
        }, 400);
      })
    });
  }
  /** returns a thenable reference i.e the key generated in firebase */
  addToPurchaseHistory(user: User, order: Order) {
    const sum = order.totalCost;
    const historyObject = {
      sum,
      order,
      cardToken: 'Kort',
    };
    this.delete_null_properties(historyObject, true);
    const itemsRef = this.db.list('UsersPurchaseHistory/' + user.id);
    return itemsRef.push(historyObject);
  }
  getPurchaseHistory(user: User): Observable<UserPurchaseHistoryV2[]> {
    const observable = Observable.create((observer) => {
      this.db.database
        .ref(`usersPurchaseHistory_v2/${user.id}`)
        .orderByChild('receipt/timestamp')
        .limitToLast(45)
        .once('value')
        .then((x) => {
          const purchases = x.val();
          const purchasesArray = [];
          for (const key in purchases) {
            if (purchases.hasOwnProperty(key)) {
              const element = purchases[key];
              purchasesArray.push({ key, ...element });
            }
          }
          observer.next(purchasesArray);
        });
    });
    return observable;
  }
  async getPurchaseHistoryItem(
    user: User,
    key: string
  ): Promise<UserPurchaseHistoryV2> {
    const receipt = await this.db.database
      .ref(`usersPurchaseHistory_v2/${user.id}/${key}`)
      .once('value');
    return receipt.val();
  }

  async setUserTableBooking(user: User, routeName, bookingId: string) {
    await this.db.database
      .ref(`Users/${user.id}/activeTableBooking/${routeName}`)
      .set(bookingId);
    this.setupTableBooking();
    return bookingId;
  }
  userHasCustomTicket(routeName, userId) {
    return this.firestore.collection('customTickets').doc(routeName).collection('usersWithTickets').doc(userId).get().toPromise();
  }

  /**
   * Delete all null (or undefined) properties from an object.
   * Set 'recurse' to true if you also want to delete properties in nested objects.
   */
  delete_null_properties(test, recurse) {
    for (const i in test) {
      if (test[i] == null) {
        delete test[i];
      } else if (recurse && typeof test[i] === 'object') {
        this.delete_null_properties(test[i], recurse);
      }
    }
  }
}
export class UserPaymentSource {
  recurringReference: string;
  type: 'worldnet' | 'stripe' | 'swish' | 'customerTabKey' | 'Unknown' | any;
  nickname: string;
  expiry?: string;
  key?: string;
}
export class CordovaDeviceRegistration {
  registrationId: string;
}
export class UserPurchaseHistory {
  key: string;
  cardToken: string;
  sum: number;
  order: Order;
}
export class UserPurchaseHistoryV2 {
  key: string;
  order: Order;
  receipt: any;
}
export class User {
  id?;
  origin?;
  createdDate?;
  firstName?: string;
  lastName?: string;
  deviceToken?;
  email?: string;
  isAnonymous?: boolean;
  password?: string;
  phoneNumber?;
  billingInfo?: any;
  activeOrders?: ActiveOrder[];
  activeTabs?: any;
  activeTableBooking?: any;
  customTicket?;
  preferedLanguage?: string;
}
export class UserCreate {
  email = '';
  password = '';
  password2 = '';
}

export class StripeCustomer {
  // account_balance: number;
  created: number;
  // default_source: string;
  delinquent: boolean;
  description: string;
  email: string;
  id: string;
  livemode: boolean;
  object: string;
  sources: StripeSource;
  subscriptions: StripeSource;
  sub;
}
export class StripeSource {
  // has_more: boolean;
  object: string;
  // total_count: number;
  url: string;
  data: StripeStoredCard[];
}
export class StripeStoredCard {
  brand: string;
  country: string;
  customer: string;
  // cvc_check: string;
  // exp_month: number;
  // exp_year: number;
  fingerprint: string;
  funding: string;
  id: string;
  last4: string;
  object: string;
}
