import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { BehaviorSubject, Subscription } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ThemeProvider {

  hasLoaded: boolean = false;
  embedded: boolean = false;
  params: ThemeParams = new ThemeParams();
  theme: BehaviorSubject<ThemeParams> = new BehaviorSubject(null);
  private subscription: Subscription;

  constructor(
    private db: AngularFireDatabase,
  ) {

  }

  async init(routeName: string) {
    await this.loadThemeData(routeName);

  }

  async loadThemeData(routeName: string) {

    console.log("Loading theme for route " + routeName)

    if (this.subscription)
      this.subscription.unsubscribe();

    this.subscription = this.db.object(`Clients/${routeName}/theme/weborder`)
      .valueChanges()
      .subscribe(async (x: ThemeParams) => {
        if (!x) { return }

        this.loadThemeParams(x);
        setTimeout(() => {
          this.hasLoaded = true;
        }, 100);
      });
  }

  ngDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  loadThemeParams(data: ThemeParams) {

    let params = data;

    // Load default values if there is none in db
    if (!params) {
      console.log("No theme data in db, will user default.");
      return
      //params = new ThemeParams();
    }
    // Load primary colors
    if (params.colors) {
      for (const key in params.colors) {
        if (Object.prototype.hasOwnProperty.call(params.colors, key)) {
          const val = params.colors[key];
          this.loadCSSParams(val);
        }
      }
      // if (params.colors.primary)
      //   this.loadCSSParams(params.colors.primary);
      // if (params.colors.mainBackground)
      //   this.loadCSSParams(params.colors.mainBackground);
      // if (params.colors.secondary)
      //   this.loadCSSParams(params.colors.secondary);
      // if (params.colors.success)
      //   this.loadCSSParams(params.colors.success);
      // if (params.colors.warning)
      //   this.loadCSSParams(params.colors.warning);
      // if (params.colors.danger)
      //   this.loadCSSParams(params.colors.danger);
      // if (params.colors.light)
      //   this.loadCSSParams(params.colors.light);
      // if (params.colors.medium)
      //   this.loadCSSParams(params.colors.medium);
      // if (params.colors.dark)
      //   this.loadCSSParams(params.colors.dark);

      this.params.colors = params.colors;
    }

    // Load fonts
    if (params.fonts)
      this.loadCSSParams(params.fonts);
    if (params.font_families) {
      this.loadFontFamilies(params.font_families);
    }
    if (params.images)
      this.params.images = params.images;
    if (this.params.images.kioskStartScreenImage)
      this.loadCSSParams({ base: { key: "--contentBg", value: `url('${this.params.images.kioskStartScreenImage.src}') 0 0/100% 100% no-repeat` } })
    this.theme.next(this.params);
  }
  loadFontFamilies(section: any) {
    if (section) {
      for (const propName in section) {
        const param: CssProperty = section[propName];
        let font = new FontFace(param.key, `url(${param.value})`, {
          style: "normal",
          weight: "400"
        });
        font.load().then(face => {
          document["fonts"]["add"](face);
        });

        document.documentElement.style.setProperty(param.key, param.value);
      }
    }
  }
  loadCSSParams(section: any) {
    if (section) {
      for (const propName in section) {
        const param: CssProperty = section[propName];
        document.documentElement.style.setProperty(param.key, param.value);
      }
    }
  }
}

export class ThemeColors {
  mainBackground: ThemeColor;
  primary: ThemeColor;
  secondary: ThemeColor;
  tertiary: ThemeColor;
  success: ThemeColor;
  warning: ThemeColor;
  danger: ThemeColor;
  dark: ThemeColor;
  medium: ThemeColor;
  light: ThemeColor;
}
export class ThemeImages {
  envirementImage: ImageProperty;
  logo: ImageProperty;
  logoWide: ImageProperty;
  kioskStartScreenImage: ImageProperty;
  kioskStartScreenVideoUrl: string;
  kioskStartScreenVideoUrl_enabled: boolean;

}
export class ThemeColor {
  base: CssProperty;
  baseRgb: CssProperty;
  contrast: CssProperty;
  contrastRgb: CssProperty;
  shade: CssProperty;
  tint: CssProperty;
}
export class ThemeFonts {
  bodyFont: CssProperty;
  ionFontFamily: CssProperty;
  mainFont: CssProperty;
  titlesFont: CssProperty;
}
export class ThemeFontFamilies {
  bodyFont: CssProperty;
}
export class CssProperty {
  key: string;
  value: string;
}

export class ImageProperty {
  src: string;
  alt: string;
}

export class ThemeParams {
  /**
   * Default Theme values
   */

  constructor() {

    this.colors = new ThemeColors();
    this.colors.mainBackground = new ThemeColor();
    this.colors.primary = new ThemeColor();
    this.colors.secondary = new ThemeColor();
    this.colors.tertiary = new ThemeColor();
    this.colors.success = new ThemeColor();
    this.colors.warning = new ThemeColor();
    this.colors.danger = new ThemeColor();
    this.colors.dark = new ThemeColor();
    this.colors.medium = new ThemeColor();
    this.colors.light = new ThemeColor();

    this.fonts = new ThemeFonts();
    this.images = new ThemeImages();

    this.images.envirementImage = {
      src: "assets/img/food_co_envirment.png",
      alt: ""
    }
    this.images.logo = {
      src: "assets/img/food_co_logo.png",
      alt: ""
    };
    this.images.logoWide = {
      src: "assets/img/food_co_logo_wide.png",
      alt: ""
    };
  }

  colors: ThemeColors;
  fonts: ThemeFonts;
  font_families: ThemeFontFamilies;
  images: ThemeImages;

}


