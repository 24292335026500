import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { LanguageProvider } from "src/app/services/language/language.provider";
@Component({
    selector: "app-receipt",
    templateUrl: "receipt.component.html",
    styleUrls: ["receipt.component.scss"]
})
export class ReceiptComponent {
    purchase: any;
    qrCodeData = "";
    constructor(private activatedRoute: ActivatedRoute, private http: HttpClient, public languageProvider: LanguageProvider) {

    }
    async ngOnInit() {
        let snapshot = this.activatedRoute.snapshot;
        if (snapshot.params.id) {
            let url = `${environment.apiUrl}/v1/order/${snapshot.params.id}/receipt`;
            this.purchase = await this.http.get<any>(url).toPromise();
            if (this.purchase.countryCode === "FI")
                this.languageProvider.setLanguage("fi");
            try {
                let data = {
                    id: this.purchase.digitalOrder.data.id,
                    version: "1",
                    type: "PubQ"
                }
                this.qrCodeData = btoa(JSON.stringify(data));
            } catch (error) {

            }
        }
    }

    getDate(timestamp) {
        moment.locale("sv-se");
        moment.unix(timestamp).format("YYYY-MM-DD HH:mm")

        return moment.unix(timestamp).format("YYYY-MM-DD HH:mm")
    }
    getSerialNo(extraData) {
        return JSON.parse(extraData).controlUnit.serialNo
    }
}