import { Component, ViewChild, Input, HostListener } from '@angular/core';
import {
  IonContent,
  NavController,
  ModalController,
  AlertController,
  Platform,
  IonRouterOutlet,
  IonNav,
  ToastController,
  LoadingController,
} from '@ionic/angular';
import { AppInfo, ContextProvider } from '../services/context/context';
import { Subscription, Subject, ReplaySubject } from 'rxjs';
import { CartProvider } from '../services/cart/cart.service';
import { CartItem, CartItemsOutput } from '../cart-items/cart-items';
import { ActivatedRoute } from '@angular/router';
import { AppConfiguration } from '../app.configuration';
import { LanguageProvider } from '../services/language/language.provider';
import { LanguagePipe } from '../pipes/language.pipe';
import { LoginNavComponent } from '../account/login-nav.component';
import { User, UserProvider } from '../services/user/user';
import * as firebase from 'firebase/app';
import { AnalyticsService } from '../services/analytics/analytics';
import { UnitProvider } from '../services/unitProvider';
import { MenuItemDescriptionComponent } from './itemDescription';
import { ThemeProvider } from '../services/theme/theme-provider.service';
import { ClientInfoComponent } from '../client-info/clientInfo.component';
import { ActiveOrder, OrderProvider } from '../services/order/order';
import { CurrencyProvider } from '../services/currency/currency.provider';
import { SideMenuComponent } from '../side-menu/side-menu.component';
import { DeliveryPlaceProvider } from '../services/delivery-place/delivery-place';
import { OrderTicketComponent } from '../active-orders/order-ticket/orderTicket.component';
import { ModalOverseerProvider } from '../services/modaloverseer/modaloverseer';
import { MenuItem } from '../models';
let appSettings = require("../../../cordova-scripts/appsettings");


@Component({
  selector: 'page-menu',
  templateUrl: 'menu.html',
  styleUrls: ['menu.scss'],
  providers: [IonRouterOutlet],
})

export class MenuPage {
  @ViewChild('myContent') content: IonContent;
  @ViewChild('categories') categories: IonContent;
  @ViewChild('sideMenu') sideMenu: SideMenuComponent;
  // @ViewChild('cart') cartElement: any;
  @Input() nav: IonNav;
  @HostListener('window:scroll', ['$event'])

  // Not sure what this should check or the perpuse 
  backdropActive: false;
  sideMenuOpen: false;
  selectedView: string = '';

  subscription: Subscription;
  bottomPaddingHtmlElement: HTMLElement;
  menuSegmentType = AppConfiguration.menuSegmentType;
  itemElementsCache = {};
  toggleDrink = { item: -1 };
  toggleFood = { item: -1 };
  openHours = true;
  slideHeight = 0;
  isLoading = true;
  menu: any;
  shownMenuItems: any;
  categoryNames: string[];
  categoryHtmlElements: HTMLElement[];
  appInfo: AppInfo;
  menuType: 'drinks' | 'food' | 'takeaway' = 'food';
  menuTypeName: 'Mat' | 'Dryck' | 'Takeaway' = 'Mat';
  menuTypeTitle = 'Food';
  slideIndex = 0;
  background = '';
  toggleItemIndex = 999;
  shownCategoryName = '';
  contextSubscription: Subscription;
  menuSubscription: Subscription;
  themeSubscription: Subscription;
  activatedRouteSub: Subscription;
  activeOrdersSub: Subscription;
  loadedImages = {};
  noItemsToShow = false;
  scrollToProduct = { initiated: false, productId: '' };
  user: User;
  logInUser = false;
  userProfileName = '';
  userSubscription: Subscription;
  windowWidth: any;
  mainBackgroundColor = {};
  logo = {};
  mainRestaurantImage = {};
  mainButtonColor = {};
  activeSegmentColor = {};
  secondaryBackgroundColor = {};
  backButtonColor = {};
  hoverItem = null;
  itemsForDishInfo: any;
  updateCartPageData: boolean;
  showDishInfo = false;
  itemIsHover = false;
  iosPlatform = false;
  showContent = true;
  expandOrders = false;
  orders: ActiveOrder[] = [];
  ordersSub: Subscription;
  smallLayout = false;
  showActiveOrderOnLoad = false;
  showProductOnLoad: string;
  showCategoryOnLoad: string;
  activateTabOnLoad: string;
  activateDeliveryOnLoad: string;
  activateTableOnLoad: string;
  presetOfTable: string;
  hasInited = false;
  navigateToHomepageEnabled = false;
  navigateToHomepageUrl = "";
  cartMargins: any = {
    "margin-left": "10px",
    "margin-top": "0px"
  };

  mainWidth: any = {
    "width": "100%",
    "z-index": "25"
  }

  sideBar: any = {
    "width": "0px",
    "max-width": "0px",
    "z-index": "98"
  }
  scrollingActive = false;

  orderToasts: { queueId: number, toast: HTMLIonToastElement, food: boolean, drinks: boolean, all: boolean } = null;

  estimatedWaitingTimeText: string = '';
  showEstimatedWaitingTime: boolean = false;
  showDescription = false;
  routeName?: string = null;
  version = appSettings.version;
  constructor(
    public contextProvider: ContextProvider,
    public navCtrl: NavController,
    private activatedRoute: ActivatedRoute,
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    public cartProvider: CartProvider,
    private platform: Platform,
    public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe,
    private userProvider: UserProvider,
    private analyticsService: AnalyticsService,
    private unitProvider: UnitProvider,
    public themeProvider: ThemeProvider,
    private orderProvider: OrderProvider,
    public currencyProvider: CurrencyProvider,
    private deliveryPlaceProvider: DeliveryPlaceProvider,
    private toastController: ToastController,
    private modaloverseer: ModalOverseerProvider,
    private loaderCtrl: LoadingController

  ) {
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0) {
      this.iosPlatform = true;
    }

    this.routeName = this.activatedRoute.snapshot.params.routeName;

    this.updateSideMenuSize(window.innerWidth);

    this.ordersSub = this.userProvider.activeOrders.subscribe((x: ActiveOrder[]) => {
      if (!x) {
        this.orders = [];
        return;
      }
      this.expandOrders = x.length < 3;
      this.orders = x;

      if (x.length > 0) {
        if (this.hasInited) {
          this.sideMenu.showTab('order');
        }
      }
    });

    this.userSubscription = this.userProvider.currentUser.subscribe(
      (userInfo: User) => {
        if (!userInfo) { return }
        this.user = userInfo;

        if (this.user && this.user.firstName) {
          this.userProfileName = this.user.firstName;
        }
        firebase.auth().onAuthStateChanged(user => {
          if (user) {
            this.logInUser = !user.isAnonymous;
          }
        });


      });

    this.activatedRouteSub = this.activatedRoute.params.subscribe(async (x) => {

      const params = this.activatedRoute.snapshot.queryParams;

      if (params.showActiveOrder) {

        this.showActiveOrderOnLoad = true;
      }

      if (params.product) {
        this.showProductOnLoad = params.product;
      } else this.showProductOnLoad = null;

      if (params.embedded) {
        console.dir(params.embedded)
        this.themeProvider.embedded = JSON.parse(params.embedded);
      }

      if (params.category) {
        this.showCategoryOnLoad = params.category;
      } else this.showCategoryOnLoad = null;

      if (params.tab) {
        this.activateTabOnLoad = params.tab;
      } else this.activateTabOnLoad = null;

      if (params.table) {
        this.presetOfTable = params.table;
        if (this.contextProvider.clientContext.appInfo.value)
          this.presetAsDeliverOption();
      }

      if (params.delivery) {
        this.activateDeliveryOnLoad = params.delivery;
      } else this.activateDeliveryOnLoad = null;
    });
    this.contextProvider.clientContext.appInfo.subscribe(x => {
      if (!x) return;
      let defaultLang = x.Context.defaultLanguage;

      if (defaultLang) {
        this.languageProvider.setLanguage(defaultLang);
      }
    })

  }

  ngAfterViewInit() {
    this.hasInited = true;
    if (this.showActiveOrderOnLoad) {
      this.showActiveOrderOnLoad = false;
      this.sideMenu.showTab('order');
    }
    setTimeout(() => {
      this.showDescription = true;
    }, 5000);

  }

  showGoBack() {
    let contextOk = (this.contextProvider && this.contextProvider.clientId && this.contextProvider.clientsCount > 1);
    let notEmbeeded = !this.themeProvider.embedded;
    let notNavigateToHomepage = !this.navigateToHomepageEnabled;
    let ok = contextOk && notEmbeeded && notNavigateToHomepage;
    return ok;
  }

  showGoToHomepage() {
    return this.navigateToHomepageEnabled && !this.themeProvider.embedded
  }

  async goBackToHomepage() {
    window.open(this.navigateToHomepageUrl, '_self');
  }

  getDescriptionStyle() {
    return { 'two-line-ellipsis': true }
  }
  presetAsDeliverOption() {
    if (this.orderProvider.orderWaitingForApproval && this.presetOfTable) {
      this.deliveryPlaceProvider.tableNumberObservable = new ReplaySubject<number>(1);

      this.orderProvider.preSelectedTableNumber = this.presetOfTable;

      try {
        const min = this.contextProvider.clientContext.appInfo.value.Context.Tables.LowerValue.toString();
        const max = this.contextProvider.clientContext.appInfo.value.Context.Tables.UpperValue.toString();

        if (Number(this.orderProvider.preSelectedTableNumber) >= Number(min) &&
          Number(this.orderProvider.preSelectedTableNumber) <= Number(max)) {
          this.deliveryPlaceProvider.tableNumber = Number(this.orderProvider.preSelectedTableNumber);
          this.orderProvider.orderWaitingForApproval.tableNumber = Number(this.orderProvider.preSelectedTableNumber);
          if (this.deliveryPlaceProvider.tablesEnabled)
            this.deliveryPlaceProvider.checkWhatSelect(this.deliveryPlaceProvider.tablesText, this.deliveryPlaceProvider.tableServeImage, true);
        }
      } catch (err) {
        this.deliveryPlaceProvider.tableNumber = -2;
      }

    }
  }


  async ngOnInit() {

    // Hämta klient data, om den inte finns sen tidigare
    if (!this.contextProvider.initDone) {
      const result = await this.contextProvider.init(this.routeName);
      if (!result) this.goToNotActivated();

      try {
        this.analyticsService.trackEvent("UNIT_SELECTED")
      } catch (error) {
        console.log(error);
      }
    }

    this.updateSideMenuSize(window.innerWidth);

    // Get users active order to use for presenting a toast and navigation to active order tab and showing order ticket
    this.activeOrdersSub = this.userProvider.activeOrders.subscribe(async (activeOrders) => {
      if (!activeOrders) return;

      if (activeOrders.length > 0)
        await this.showNotification(activeOrders)
      else {
        if (this.orderToasts && this.orderToasts.toast)
          this.orderToasts.toast.dismiss();
      }
    })

    // this.isIphone = this.platform.is('iphone');
    this.isLoading = true;
    setTimeout(async () => {
      this.contextSubscription = this.contextProvider.clientContext.appInfo.subscribe(
        (data) => {
          this.appInfo = data;
          if (data && data.Context) {
            this.estimatedWaitingTimeText = data.Context.estimatedWaitingTimeText;
            this.showEstimatedWaitingTime = data.Context.showEstimatedWaitingTime;

            if (data.Context.navigateToHomepage) {
              this.navigateToHomepageEnabled = data.Context.navigateToHomepage.enabled;
              this.navigateToHomepageUrl = data.Context.navigateToHomepage.url;
            }
          }

          // Handle preset of table if active
          if (this.presetOfTable)
            this.presetAsDeliverOption();

        }
      );
      this.menuSubscription = this.contextProvider.clientContext.menu.subscribe(
        (data) => {
          if (data) {
            this.shownMenuItems = null;
            this.itemElementsCache = {};
            this.menu = data;//this.filterCategories(data);
            this.categoryNames = this.getCategoryNames(this.menu);
            setTimeout(() => {
              //   this.categoryHtmlElements = [];
              //   this.categoryNames.forEach((x, i) => {
              //     this.categoryHtmlElements.push(
              //       document.getElementById('' + x + i)
              //     );
              //   });
              //   if (this.scrollToProduct.initiated) {
              //     const element = document.getElementById(this.scrollToProduct.productId);
              //     if (!element) {
              //       return;
              //     }
              //     const rect = element.getBoundingClientRect();
              //     this.content.scrollByPoint(rect.x, rect.y - 200, 0);
              //   }
              this.noItemsToShow = false;
              this.shownMenuItems = this.menu;
            }, 500);
            //this.displayMenu();

            this.isLoading = false;
          }
          // if(this.userProvider && !this.userProvider.currentUser){
          //   this.userProvider.createAnonymousUser();
          // }

        }
      );

      // Changed to other solution should be removed
      //----------------------------------------------
      // this.themeSubscription = this.contextProvider.clientContext.theme.subscribe((data: any) => {
      //   this.mainBackgroundColor = {
      //     'background-color': '#F5F5F5'
      //   };
      //   this.secondaryBackgroundColor = {
      //     'background-color': '#F5F5F5',
      //     color: '#000',
      //     'font-weight': 'bold'
      //   };
      //   this.backButtonColor = {
      //     color: '#000'
      //   };
      //   if (data) {
      //     this.mainButtonColor = {
      //       'background-color': data.mainButtonColor,
      //       color: '#FFFAFA'
      //     };
      //     this.logo = {
      //       'background-image': data.mainLogo,
      //     };
      //     this.mainRestaurantImage = {
      //       'background-image': data.mainImage
      //     };
      //     this.activeSegmentColor = {
      //       color: data.mainButtonColor,
      //       'border-bottom': `2px solid ${data.mainButtonColor}`,
      //       'font-weight': 'bold'
      //     };
      //   } else {
      //     this.logo = {
      //       'background-image': 'assets/img/logo_wide.png',
      //     };
      //     this.mainRestaurantImage = {
      //       // tslint:disable-next-line:max-line-length
      //        'background-image': 'https://www.gordonramsayrestaurants.com/assets/Uploads/_resampled/CroppedFocusedImage160055050-50-GRBG-Sim-Shoot-3-2000px.jpg'
      //     };
      //     this.mainButtonColor = {
      //       'background-color': '#bc9049',
      //       color: '#ffffff'
      //     };
      //     this.activeSegmentColor = {
      //       color: '#bc9049',
      //       'border-bottom': `2px solid #bc9049`,
      //       'font-weight': 'bold'
      //     };
      //   }
      // });

      //Check if any user
      if (!this.userProvider.currentUser.value) {
        await this.userProvider.createAnonymousUser();
        this.user = this.userProvider.currentUser.value;
      }

    }, 300);
    this.bottomPaddingHtmlElement = document.getElementById('reached-end');

    // setTimeout(()=>{
    //   this.showContent = true;
    // },2000);
  }

  ngOnDestroy() {
    if (this.ordersSub)
      this.ordersSub.unsubscribe();

    if (this.contextSubscription)
      this.contextSubscription.unsubscribe();

    if (this.menuSubscription)
      this.menuSubscription.unsubscribe();

    if (this.themeSubscription)
      this.themeSubscription.unsubscribe();

    if (this.subscription)
      this.subscription.unsubscribe();

    if (this.activatedRouteSub)
      this.activatedRouteSub.unsubscribe();

    if (this.activeOrdersSub)
      this.activeOrdersSub.unsubscribe();
  }

  async showNotification(orders: ActiveOrder[]) {

    if (!this.contextProvider || !this.contextProvider.client || !this.contextProvider.client.routeName) {
      return;
    }
    let possibleToastAlreadyShown = await this.toastController.getTop();
    if (possibleToastAlreadyShown)
      possibleToastAlreadyShown.dismiss();

    const completedOrders = orders.filter(order => order.completed === 'completed');
    const completedDrinks = orders.filter(order => this.completedDrinks(order));
    const completedFood = orders.filter(order => this.completedFood(order));

    if (completedOrders.length > 0 || completedDrinks.length > 0 || completedFood.length > 0) {
      let activeOrder = null;
      let message = '';

      if (!this.orderToasts) {
        this.orderToasts = { queueId: null, toast: null, drinks: false, food: false, all: false }
      }

      let showAllToast = this.orderToasts.all;
      let showFoodToast = this.orderToasts.food;
      let showDrinksToast = this.orderToasts.drinks;

      if (completedOrders.length > 0) {

        activeOrder = completedOrders[0];
        if (this.orderToasts.queueId !== activeOrder.queueId) {
          this.orderToasts.drinks = false
          this.orderToasts.food = false
          this.orderToasts.all = false
          showFoodToast = false;
          showDrinksToast = false;
          showAllToast = false;
        }
        showAllToast = true;
        message = this.languagePipe.transform("Din order är klar.", 'ACTIVE_ORDER_READY', this.languageProvider.currentLanguage);
      }
      else if (completedDrinks.length > 0) {
        activeOrder = completedDrinks[0];
        if (this.orderToasts.queueId !== activeOrder.queueId) {
          this.orderToasts.drinks = false
          this.orderToasts.food = false
          this.orderToasts.all = false
          showFoodToast = false;
          showDrinksToast = false;
          showAllToast = false;
        }
        showDrinksToast = true;
        message = this.languagePipe.transform("Din dryck är klar.", 'ACTIVE_ORDER_DRINK_READY', this.languageProvider.currentLanguage);
      }
      else if (completedFood.length > 0) {
        activeOrder = completedFood[0];
        if (this.orderToasts.queueId !== activeOrder.queueId) {
          this.orderToasts.drinks = false
          this.orderToasts.food = false
          this.orderToasts.all = false
          showFoodToast = false;
          showDrinksToast = false;
          showAllToast = false;
        }
        showFoodToast = true;
        message = this.languagePipe.transform("Din mat är klar.", 'ACTIVE_ORDER_FOOD_READY', this.languageProvider.currentLanguage);
      }

      if (!activeOrder) {
        if (this.orderToasts.toast)
          this.orderToasts.toast.dismiss();
        return;
      }

      if (this.orderToasts.queueId !== activeOrder.queueId) {
        this.orderToasts.queueId = activeOrder.queueId
        if (this.orderToasts.toast)
          this.orderToasts.toast.dismiss();
      }

      if (showAllToast != this.orderToasts.all || showDrinksToast != this.orderToasts.drinks || showFoodToast != this.orderToasts.food) {

        if (showAllToast) {
          this.orderToasts.all = true;
          this.orderToasts.drinks = true;
          this.orderToasts.food = true;
        }
        else if (showDrinksToast)
          this.orderToasts.drinks = true;
        else if (showFoodToast)
          this.orderToasts.food = true;

        this.orderToasts.toast = await this.toastController.create({
          header: message + (activeOrder.order ?? activeOrder.order.orderCode ? ' (#' + activeOrder.order.orderCode + ')' : ''),
          message: this.languagePipe.transform("Din biljett", "ACTIVE_ORDER_TICKET", this.languageProvider.currentLanguage),
          position: 'top',
          color: 'success',
          buttons: [
            {
              side: 'start',
              icon: 'close-outline',
              role: 'cancel',
              handler: () => {
                console.log('Favorite clicked');
              }
            }, {
              text: this.languagePipe.transform("Visa", "SHOW", this.languageProvider.currentLanguage),
              icon: 'chevron-forward-outline',
              role: 'show',
              handler: () => {
                console.log('Cancel clicked');
              }
            }
          ]
        });

        await this.orderToasts.toast.present();

        const { role } = await this.orderToasts.toast.onDidDismiss();
        if (role === "show") {
          this.sideMenu.showTab('order');
          // Check that the order still exists
          if (this.userProvider.activeOrdersValue.value && this.userProvider.activeOrdersValue.value.length > 0) {
            if (this.userProvider.activeOrdersValue.value.find((a) => a.queueId === activeOrder.queueId))
              this.partialCompletedOrder(activeOrder);
          }
        }
        console.log('onDidDismiss resolved with role', role);
      }
    } else {
      if (this.orderToasts && this.orderToasts.toast) this.orderToasts.toast.dismiss();
    }
  }

  async showPartiallyCompletedModal(foodItems: CartItem[], drinkItems: CartItem[], activeOrder) {
    const modal = await this.modalCtrl.create({
      component: OrderTicketComponent,
      backdropDismiss: false,
      componentProps:
      {
        order: activeOrder,
        completedFood: foodItems,
        completedDrinks: drinkItems
      }
    });
    modal.present();
    const result = await modal.onDidDismiss();
    if (!result.data) { return; } else if (result.data === 'ok') {
      const loader = await this.loaderCtrl.create({ spinner: 'circular', duration: 5000 });
      loader.present();
      if (foodItems) foodItems.forEach(x => x.completed = 'completedAndAccepted');
      if (drinkItems) drinkItems.forEach(x => x.completed = 'completedAndAccepted');

      let hasDrinks = drinkItems.length > 0;
      let hasFood = foodItems.length > 0;

      this.userProvider
        .updateAcceptedOrderItems(this.userProvider.currentUser.getValue(), activeOrder, foodItems, drinkItems)
        .then(async x => {
          const orderContents = activeOrder.order.order;
          const food = orderContents.food != null ? orderContents.food : [];
          const drinks = orderContents.drinks != null ? orderContents.drinks : [];
          const allItemsAccepted = food.concat(drinks).every(x => x.completed === 'completedAndAccepted');
          const index = this.modaloverseer.currentlyOpenModals.findIndex(x => x === activeOrder.key);
          if (index !== -1) {
            this.modaloverseer.currentlyOpenModals.splice(index, 1);
          }
          if (allItemsAccepted) {
            try {
              await this.userProvider.removeActiveOrder(this.userProvider.currentUser.getValue(), activeOrder);
              loader.dismiss();
            } catch (error) {
              alert(JSON.stringify(error));
              loader.dismiss();
            }
          } else {
            if (hasFood) await this.userProvider.updateAcceptedFoodOrder(this.userProvider.currentUser.getValue(), activeOrder)
            if (hasDrinks) await this.userProvider.updateAcceptedDrinksOrder(this.userProvider.currentUser.getValue(), activeOrder)
            loader.dismiss();
          }
        });
    }
  }
  async partialCompletedOrder(activeOrder) {
    if (activeOrder.order) {
      let food = []
      let drinks = []
      if (activeOrder.order.order && activeOrder.order.order.food)
        food = activeOrder.order.order.food.filter((item: CartItem) => item.completed === 'completed')
      if (activeOrder.order.order && activeOrder.order.order.drinks)
        drinks = activeOrder.order.order.drinks.filter((item: CartItem) => item.completed === 'completed')

      this.showPartiallyCompletedModal(food, drinks, activeOrder);
    }
  }

  private completedFood(activeOrder) {
    if (activeOrder.order && activeOrder.order.order && activeOrder.order.order.food && activeOrder.order.order.food.length > 0)
      return activeOrder.order.order.food.filter((item: CartItem) => item.completed === 'completed').length > 0

    return false
  }

  private completedDrinks(activeOrder) {
    if (activeOrder.order && activeOrder.order.order && activeOrder.order.order.drinks && activeOrder.order.order.drinks.length > 0)
      return activeOrder.order.order.drinks.filter((item: CartItem) => item.completed === 'completed').length > 0

    return false;
  }


  updateSideMenuSize(width) {
    // this.smallLayout = this.windowWidth <= 600;
    this.windowWidth = width;

    this.sideMenuHasChanged(this.sideMenu && this.sideMenu.sideMenuOpen ? { open: true } : { open: false });
  }

  ionViewWillEnter() {
    this.updateSideMenuSize(window.innerWidth);

    // this.content.ionScrollStart.subscribe((ev) => {
    //   this.scrollingActive = true;
    // });

    // this.content.ionScrollEnd.subscribe((ev) => {
    //   this.scrollingActive = false;
    // });
  }

  ionViewDidEnter() {

    this.updateSideMenuSize(window.innerWidth);

    if (this.content) this.content.scrollToTop();
    this.updateCartPageData = !this.updateCartPageData;

    setTimeout(() => {

    }, 2000);
  }


  filterCategories(categories: any) {
    const filtered = [];
    categories.forEach((item) => {
      if (this.menuType === 'takeaway') {
        const takeAwayItems = item.items.filter(
          (x) => x.tags && x.tags.find((p) => p === this.menuType)
        );
        if (takeAwayItems.length > 0) {
          item.items = takeAwayItems;
          filtered.push(item);
          return;
        }
      }
      filtered.push(item);
    });
    return filtered;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  // selectMenuItemsToShow(categories: any[]) {
  //   if (!this.appInfo.style.menuCards) { return categories; }
  //   const menuCategories = [];
  //   for (let i = 0; i < categories.length; i++) {
  //     const element = categories[i];
  //     menuCategories.push(element);
  //   }

  //   return menuCategories;
  // }

  showOpeninghours() {
    this.sideMenu.showTab('info');
  }

  getOnlineInfo() {
    if (this.appInfo) {
      if (!this.appInfo.Context.food.isOpen) {
        return 'Går inte att beställa online - ' + this.appInfo.Context.food.closedComment;
      } else {
        return 'Går att beställa online.'
      }
    }
  }

  getCategoryNames(categories: any[]): string[] {
    const categoryNames = [];
    categories.forEach((category) => {
      categoryNames.push(category.name);
    });
    return categoryNames;
  }

  isLoaded() {
    return this.appInfo != null;
  }

  isOpen() {
    if (!this.isLoaded()) { return false; }

    if (!this.appInfo.Context[this.menuType]) {
      console.log("Menu type " + this.menuType + " do not exist in the context for this merchant!!");
    }

    return (
      (this.appInfo.Context['drinks'] &&
        this.appInfo.Context['drinks'].isOpen) ||
      (this.appInfo.Context['food'] &&
        this.appInfo.Context['food'].isOpen)
    );
  }

  getOpenString() {
    if (this.isOpen()) return this.languagePipe.transform('Öppet nu', 'OPEN', this.languageProvider.currentLanguage);
    else return this.languagePipe.transform('Stängt', 'CLOSED', this.languageProvider.currentLanguage);
  }

  isEnabled(item: MenuItem) {
    if (item.enabled === undefined) { return true; } else { return item.enabled; }
  }

  formatAddress(address, part) {
    if (address) {
      let parts = address.split(',');
      if (parts && parts.length > 0) {
        return parts[part];
      } else {
        return address;
      }
    }

    return '';
  }

  getClosedComment() {
    if (!this.isLoaded()) return "";
    return this.appInfo.Context[this.menuType]
      ? this.appInfo.Context[this.menuType].closedComment
      : "";
  }

  displayMenu() {
    this.noItemsToShow = false;
    this.shownMenuItems = this.menu;
    if (this.shownMenuItems.length <= 0) {
      this.noItemsToShow = true;
      return;
    }
    this.shownMenuItems.forEach((element) => {
      element.items.forEach((x) => {
        this.loadAndApplyImage(x);
      });
    });
    // Load the first images from the start
    const nrOfImagesToPreLoad = 6;
    for (let index = 0; index < nrOfImagesToPreLoad; index++) {
      const category = this.shownMenuItems[0];
      const element = category.items[index];
      if (element) { element.event.next('load_image'); }
    }
    this.shownCategoryName = this.categoryNames[0];
  }

  loadAndApplyImage(product) {
    product.event = new Subject<string>();
    const subscription = product.event.subscribe((x) => {
      // Product is in view so load its image;
      const image = new Image();
      image.src = product.ImageUrl;
      image.onload = (x) => {
        product.hdStyling = {
          'background-image': `url('${product.ImageUrl}')`,
          animation: 'hdproduct 0.3s',
          'animation-delay': '0.5s',
          'animation-fill-mode': 'forwards'
        };
      };
      subscription.unsubscribe();
      product.event.complete();
    });
  }

  getImageUrl(product) {
    return `url('${product.ImageUrl}')`;
  }

  async showRestaurantInfo() {
    let modal = await this.modalCtrl.create({
      component: ClientInfoComponent,
      componentProps: {
        appInfo: this.contextProvider.clientContext.appInfo.value,
        clientInfo: this.contextProvider.clientContext.currentClient.value,
      },
      cssClass: "modal-fullscreen-limited-width",
      swipeToClose: true,
    });
    modal.present();
  }

  changeColor(index, state) {
    if (!state) {
      this.hoverItem = index;
      this.itemIsHover = true;
    } else {
      this.hoverItem = null;
      this.itemIsHover = false;
    }
  }



  isInView(element) {
    if (element) {
      const height = this.platform.height();
      const width = this.platform.width();
      const bounding = element.getBoundingClientRect();
      if (
        Math.floor(bounding.top) >= 0 &&
        Math.floor(bounding.left) >= 0 &&
        Math.floor(bounding.right) <= width &&
        Math.floor(bounding.bottom) <= height
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  isKindOfInView(element) {
    if (element) {
      const height = this.platform.height();
      const width = this.platform.width();
      const bounding = element.getBoundingClientRect();
      if (
        Math.floor(bounding.top) >= -400 &&
        Math.floor(bounding.left) >= 0 &&
        Math.floor(bounding.right) <= width &&
        Math.floor(bounding.bottom) - 400 <= height
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  slideTo(index) {
    const element = document.getElementById(`category_${index}`)
    const y = element.offsetTop;
    const contentEl: any = this.content;
    //this.content.scrollToPoint(0, index == 0 ? 0 : y - (contentEl.el.offsetHeight + contentEl.el.offsetTop + 70), 300);
    this.content.scrollToPoint(0, index == 0 ? 0 : y - 140, 300);
    this.itemIsHover = false;
  }

  scrollIsHappening(ev) {
    //this.scrolling();
    // if (this.categoryNames && this.categoryHtmlElements) {
    //   this.categoryNames.forEach((x, i) => {
    //     if (this.isInView(this.categoryHtmlElements[i])) {
    //       this.slideIndex = i;
    //       this.shownCategoryName = x;
    //     }
    //   });
    // }
    // this.offsetCartFromTopIfVisable(ev);
  }

  scrolling() {
    this.shownMenuItems.forEach((x) => {
      x.items.forEach((item, i) => {
        let element = this.itemElementsCache[item.key];
        if (!element) {
          element = document.getElementById(item.key);
          this.itemElementsCache[item.key] = element;
        }
        if (this.isKindOfInView(element)) {
          if (!item.event.isStopped) { item.event.next('load_image'); }
        }
      });
    });
  }

  scrollUp() {
    this.content.scrollByPoint(0, -800, 400);
  }

  scrollDown() {
    this.content.scrollByPoint(0, 800, 400);
  }
  // offsetCartFromTopIfVisable(ev){
  //   if(this.cartElement){
  //     if(!this.cartElement.hidden){
  //       //let marginTop = '' + (ev.detail.scrollTop > 80 ? ev.detail.scrollTop-(this.themeProvider.params.menu.options.showEnvironmentImage ? 200 : 0) : ev.detail.scrollTop) + 'px';
  //       let marginTop = '' + ev.detail.scrollTop+ 'px';

  //       if(this.themeProvider.params.menu.options.showShrinkingHeader)
  //       {
  //         marginTop = '' + (ev.detail.scrollTop > 80 ? ev.detail.scrollTop-(this.themeProvider.params.menu.options.showEnvironmentImage ? 200 : 80) : ev.detail.scrollTop) + 'px';
  //       }

  //       this.cartMargins = {
  //         'margin-left': '10px',
  //         'margin-top': marginTop
  //       };
  //     }else{
  //       //console.log("Cart is hidden");
  //     }
  //   }
  // }

  // toggleSelectedDrinkItem($index) {
  //   this.toggleDrink.item = $index;
  //   setTimeout(() => {
  //     this.toggleDrink.item = -1;
  //   }, 0);
  // }

  // toggleSelectedFoodItem($index) {
  //   this.toggleFood.item = $index;
  //   setTimeout(() => {
  //     this.toggleFood.item = -1;
  //   }, 0);
  // }

  async showItemPopup(item: CartItem) {
    
      const hasOptions = item.options && item.options.length > 0;
      const modal = await this.modalCtrl.create({
        component: MenuItemDescriptionComponent,
        cssClass: 'modal-fullscreen-limited-width',
        swipeToClose: true,
        componentProps: { item, isReadonly: !this.isOpen(), smallLayout: this.smallLayout },
        backdropDismiss: true,
        showBackdrop: true,
      });
      modal.present();
      const result = await modal.onDidDismiss();
      if (!result.data) { return; }
      this.addToOrder(result.data);
      //if(!this.smallLayout) this.sideMenu.showCart();
    
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.updateSideMenuSize(event.target.innerWidth);
  }

  ifUserClickOnCart(item, event, i) {
    if ((item.isCombinedProduct === undefined || item.isCombinedProduct === false) && item.options != null && item.options.length > 0) {
      this.showDishInfo = !this.showDishInfo;
      this.itemsForDishInfo = item;
    }
  }

  isThemeLoaded() {
    return (this.themeProvider.hasLoaded && this.showContent);
  }

  addOrShowPopup(item, event, i) {
    event && event.preventDefault();
    this.showDishInfo = false;
    if ((item.isCombinedProduct === undefined || item.isCombinedProduct === false) && item.options != null && item.options.length > 0) {
      this.showDishInfo = true;
      this.itemsForDishInfo = item;
      //this.showDishInfoModal(item);
      this.showItemPopup(this.itemsForDishInfo);
    } else if (this.isOpen()) {
      this.addToOrder(item);
      //if(!this.smallLayout) this.sideMenu.showCart();
      // Re render the cart component
      this.updateCartPageData = !this.updateCartPageData;
      //this.startAnimation(i);
    }
  }

  hasOptions(item) {
    return item.options != null && item.options.length > 0;
  }

  hasOrder() {
    return this.orderProvider.orderWaitingForApproval;
  }

  isCartEmpty() {
    return !this.isCartNotEmpty()
  }

  isCartNotEmpty() {
    return this.cartProvider && this.cartProvider.cart && this.cartProvider.cart.items && this.cartProvider.cart.items.length > 0
  }

  async showDishInfoModal(item) {
    const modal = await this.modalCtrl.create({
      component: MenuItemDescriptionComponent,
      cssClass: this.smallLayout ? 'modal-drawer' : 'modal-fullscreen',
      swipeToClose: true,
      componentProps: { item, isReadonly: !this.isOpen(), smallLayout: this.smallLayout },
      backdropDismiss: true,
      showBackdrop: false,
    });

    modal.present();
    const result = await modal.onDidDismiss();

    if (!result.data) { return; }

    this.addToOrder(result.data);
  }

  valueToCart(event) {
    if (!event) {
      this.showDishInfo = false;
    } else {
      this.addToOrder(event.data);
      this.showDishInfo = false;
    }
  }

  removeItem(item, event, index) {
    event && event.preventDefault();
    this.cartProvider.removeFirstItemOfName(item.Name);
    // Re render the cart component
    this.updateCartPageData = !this.updateCartPageData;
    //if(!this.smallLayout) this.sideMenu.showCart();
  }

  gotoCart() {
    this.navCtrl.navigateForward(`u/${this.routeName}/cart`);
  }

  goToNotActivated() {
    this.navCtrl.navigateForward(`not-activated`);
  }

  // gotoAccount(){
  //   this.navCtrl.navigateForward(`account/${this.client}/${this.city}/${this.routeName}`);
  // } 

  // goToActiveOrders() {
  //   this.navCtrl.navigateForward(`active-orders/${this.client}/${this.city}/${this.routeName}`);
  // }

  async loginOrRegistration() {
    const modal = await this.modalCtrl.create({
      component: LoginNavComponent,
    });
    modal.present();
    const result = await modal.onDidDismiss();
    if (!result.data) { return; }
    this.user = result.data;
    this.userProfileName = this.user.firstName;
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.logInUser = !user.isAnonymous;
      }
    });
  }

  // async showProfileUserInfo() {
  //   this.navCtrl.navigateForward(`account/${this.client}/${this.city}/${this.routeName}`);
  // }

  async onPaymentInitiation(event?: CartItemsOutput) {


    // this.orderFromCart = this.createOrder(event);
    // this.orderProvider.orderWaitingForApproval = this.orderFromCart;
    // console.dir(this.orderFromCart);
    // if (this.orderFromCart.totalCost === 0) {
    //   await this.navCtrl.navigateBack(`main/${this.client}/${this.city}/${this.routeName}`);
    // }
    // await this.setDeviceTokenForOrder(this.orderFromCart);
  }


  startAnimation(index) {
    this.toggleItemIndex = index;
    setTimeout(() => {
      this.toggleItemIndex = 999;
    }, 700);
  }

  hasActiveOrders() {
    let ok = this.user && this.user.activeOrders && typeof this.user.activeOrders === 'object' && Object.keys(this.user.activeOrders).length > 0
    return ok;
  }

  async addToOrder(menuItem) {
    // Re render the cart component
    this.updateCartPageData = !this.updateCartPageData;

    this.cartProvider.fromTakeawayMenu = this.menuType === 'takeaway';
    const result = this.cartProvider.addItem(menuItem);
    if (result === 'DRINKS_CLOSED') {
      const alert = await this.alertCtrl.create({
        header: this.languagePipe.transform('Fel', 'ERROR', this.languageProvider.currentLanguage),
        message: this.languagePipe.transform('Det tas inte emot några dryckesbeställningar för tillfället', 'MENU_DRINKS_CLOSED', this.languageProvider.currentLanguage),
        buttons: [{ text: this.languagePipe.transform('OK', 'OK', this.languageProvider.currentLanguage) }],
      });
      alert.present();
    }
    if (result === 'CART_FULL') {
      const alert = await this.alertCtrl.create({
        header: this.languagePipe.transform('Du kan inte beställa mer på samma order', 'MENU_ORDER_FULL', this.languageProvider.currentLanguage),
        buttons: [{ text: this.languagePipe.transform('OK', 'OK', this.languageProvider.currentLanguage) }],
      });
      alert.present();
    }
    if (result === 'TOO_MUCH_ALCOHOL') {
      const alert = await this.alertCtrl.create({
        header: this.languagePipe.transform('You can not add any more alcohol items to this order.', 'MENU_TOO_MUCH_ALCOHOL', this.languageProvider.currentLanguage),
        buttons: [{ text: this.languagePipe.transform('OK', 'OK', this.languageProvider.currentLanguage) }],
      });
      alert.present();
    }
  }

  sideMenuHasChanged(state: any) {

    if (!this.smallLayout) {
      if (!state.open) {
        let widthStr = `${this.windowWidth - 80}px`;
        this.mainWidth = {
          "width": widthStr,
          "z-index": '25'
        }
        this.sideBar = {
          "width": "0px",
          "max-width": "0px",
          "z-index": "98"
        }
      }
      else {
        let widthStr = `${this.windowWidth - 600}px`;
        this.mainWidth = {
          "width": widthStr,
          "z-index": '25'
        }
        this.sideBar = {
          "width": "500px",
          // "max-width": "500px",
          "z-index": "98"
        }
      }
    } else {
      if (!state.open) {
        let widthStr = `${this.windowWidth}px`;
        this.mainWidth = {
          "width": widthStr,
          "z-index": '25'
        }
        this.sideBar = {
          "width": "0px",
          "max-width": "0px",
          "z-index": "98"
        }
      }
      else {

        let widthStr = `${this.windowWidth}px`;
        this.mainWidth = {
          "width": "0",
          "z-index": '25'
        }
        this.sideBar = {
          "width": widthStr,
          "max-width": widthStr,
          "z-index": "98"
        }
      }
    }
  }

  scrollCategoriesRight() {
    this.categories.scrollByPoint(200, 0, 300);
  }

  scrollCategoriesLeft() {
    this.categories.scrollByPoint(-200, 0, 300);
  }

  goBack() {
    if (this.contextProvider.clientId) {
      /* Clear cart if user leaves the unit */
      this.cartProvider.empty();

      this.navCtrl.navigateBack(`units/${this.contextProvider.clientId}/all`);
    }
  }

  onLanguageChanged(ev) {
    this.languageProvider.setLanguage(ev.detail.value);
  }

  
  openSocialMediaLink(link) {
    window.open(link, '_blank');
  }

}