
export const lang = {
    get: (string, parameters?) => {
        switch (string) {
            case 'BONUS_USE': return `Bruk ${parameters[0]} bonuspoeng`;
            case 'BONUS_USE': `Brug ${parameters[0]} bonuspoint'`;
            case 'STOCK_ALERT': `Dessverre er ${parameters[0]} utsolgt. Handlekurven din er justert til det som var tilgjengelig, slik at du kan fortsette å handle.`;
            case 'BOOKING_NO_AVAILABLE_TIMES_1': `Dessverre er det ingen tilgjengelig tidsluke for ${parameters[0]} personer.`;
            case 'BOOKING_SMS_TEX': `Hei, dette gjelder reservasjonen din ${parameters[0]} \n ${parameters[1]}\n ${parameters[2]} personer.`;
            case 'OFFERS_ACTIVATED': `Aktivert ${parameters[0]}`;
            case 'CART_ITEMS_REMOVE': `Vil du fjerne ${parameters[0]}?`;
            case 'CART_ALLERGY_CONTACT': `Mellom ${parameters[0]}-${parameters[1]} vil vi gjerne svare på dine spørsmål om innhold`;
            case 'EMAIL_CORRECT': `Er ${parameters[0]} riktig?`;
            case 'LOGIN_SEND_SMS': `Sendte tekst til ${parameters[0]}?`;
            case 'LOGIN_ERROR': `Oops! Noe gikk galt. Prøv en annen måte å logge på. \n ${parameters[0]}`;
            case 'OPEN_SWISH_APP_WITH_PHONENUMBER': return `Visas ingen betalning i Swish, kontrollera att det är rätt nummer (${parameters[0]}).`;
            case 'SINGLE_ORDER': return `Du har ${parameters[0]} bestilling på gang`;
            case 'MULTIPLE_ORDER': return `Du har${parameters[0]} ventende bestillinger`;
        }
    },
    ABOUT_US: 'Om oss',
    ACCOUNT: 'Konto',
    ACCOUNT_REMOVE_CARD: 'Fjern kort',
    ACCOUNT_REMOVE_CARD_2: 'Vil du fjerne kortet?',
    ACTIVATE: 'Aktivere',
    ACTIVE_ORDER_CLOSE_WINDOW: 'Dette vinduet forsvinner når du velger Ja',
    ACTIVE_ORDER_DEL_CUSTOMER: 'Kjøkkenet har mottatt bestillingen din, og du vil bli varslet når bestillingen blir levert til deg',
    ACTIVE_ORDER_DRINK_READY: 'Drikken din er klar',
    ACTIVE_ORDER_EATIN_COMPLETE: 'Vis dette skjermbildet ved disken',
    ACTIVE_ORDER_FOOD_READY: 'Måltidet ditt er klart',
    ACTIVE_ORDER_HOME_DELIVERY_OTW: 'Din ordre forlater nå restauranten og er på vei til deg!',
    ACTIVE_ORDER_ONGOING: 'Løpende bestillinger',
    ACTIVE_ORDER_READY: 'Bestillingen din er klar',
    ACTIVE_ORDER_RECIEVED_Q: 'Har du mottatt bestillingen din? ',
    ACTIVE_ORDER_SHOW_SCREEN: 'Vis dette skjermbildet ved disken',
    ACTIVE_ORDER_TABLE: 'Takk! Vi forbereder nå bestillingen din og vil levere den til deg så snart vi kan!',
    ACTIVE_ORDER_TABLE_DELIVERY_OTW: 'Bestillingen din leveres til bordet ditt',
    ACTIVE_ORDER_TAKEAWAY: 'Kjøkkenet har mottatt bestillingen din, og du vil bli varslet med en hentetid så snart som mulig',
    ACTIVE_ORDER_TAKEAWAY_COMPLETE: 'Vis dette skjermbildet ved disken',
    ACTIVE_ORDER_TICKET: 'Her er bestillingsbilletten din',
    ACTIVE_ORDER_WAITING: 'Takk! Venter på godkjenning',
    ACCEPT_USER_TERMS: 'Jeg godtar vilkårene for bruk',
    ADD: 'Legge til',
    ADD_CARD: 'Legg til kort',
    ADD_CARD_ADDING: 'Legger til kort',
    ADD_CARD_ERROR_E1: 'Noe gikk galt. Prøv på nytt.',
    ADD_CARD_ERROR_E10: 'Feil kortnummer. Kontroller at du har skrevet inn riktig.',
    ADD_CARD_ERROR_E11: 'Feil måned/år. Kontroller at du har skrevet inn riktig.',
    ADD_CARD_ERROR_E12: 'Feil navn på kortet. Ta kontakt med personell.',
    ADD_CARD_ERROR_E13: 'Restauranten har feil innstilling. Ta kontakt med personalet.',
    ADD_CARD_ERROR_E14: 'Feil CVV-kode. Kontroller at du har skrevet inn riktig.',
    ADD_CARD_ERROR_E3: 'Restauranten kan ikke legge til kort. Ta kontakt med personalet.',
    ADD_CARD_ERROR_E4: 'Ugyldig informasjon',
    ADD_CARD_ERROR_E5: 'Feil korttype',
    ADD_CARD_ERROR_E6: 'Feil terminal-ID. Ta kontakt med personalet.',
    ADD_CARD_ERROR_E7: 'Ikke tillatt! Ta kontakt med personalet.',
    ADD_CARD_ERROR_E8: 'Feil forhandler-ID. Ta kontakt med personalet.',
    ADD_CARD_ERROR_E9: 'Feil dato. Ta kontakt med personalet.',
    ADD_CARD_NEW_CARD: 'Legg til nytt kort',
    ADD_CARD_NICK_MSG: 'Kortet vil bli trygt kryptert og lagret for fremtidig bruk',
    ADD_CARD_NICK_PLACEHOLDER: 'Korts navn',
    ADD_CARD_NICK_TITLE: 'Hvilket navn vil du gi kortet?',
    ADD_CARD_SECURITY_INFO: 'Vi behandler kortinformasjonen din på en trygg måte. All sensitiv informasjon lagres hos en sertifisert betalingsleverandør.',
    ADD_TO_CART: 'Legg i handlekurv',
    BAB_ADD: 'Legg til ',
    BAB_BURGER_NAME: 'Hva skal burgeren din hete?',
    BAB_DRESSING: 'Dressing',
    BAB_EXTRAS: 'Velg Tillegg',
    BAB_MANY: 'Velg hvor mange du vil ha',
    BAB_NAME: 'Hva skal retten din hete?',
    BAB_SIDEORDER: 'Velg tilbehør',
    BAB_TOPPINGS: 'Pålegg',
    BAB_TYPE_SIZE: 'Velg type og størrelse',
    BACK: 'Tilbake',
    BONUS_POINTS: 'Bonuspoeng',
    BONUSPOINTS: 'Bonuspoeng',
    BOOK: 'Booke',
    BOOKING_ALLOW_SMS: 'Tillatelsen gjør det mulig for appen å åpne en ferdigfylt SMS til restauranten for deg',
    BOOKING_APPCODE: 'Koden din er',
    BOOKING_CANCEL: 'Er du sikker på at du vil kansellere bestillingen din?',
    BOOKING_CANCELED: 'Din bestilling er kansellert',
    BOOKING_CONFIRM: 'Ordrebekreftelse',
    BOOKING_CONFIRM_CANCEL: 'Bekreft kansellering',
    BOOKING_CONTACT_US_1: 'Kontakt oss på',
    BOOKING_CONTACT_US_2: 'eller klikk her for å sende en tekstmelding',
    BOOKING_ICONS: 'Bestill ved hjelp av ikonene nedenfor',
    BOOKING_LARGER_GROUPS: 'Bestilling for større grupper',
    BOOKING_LARGER_GROUPS_CONTACT: 'Hvis du vil bestille for en større gruppe, vennligst kontakt',
    BOOKING_NO_AVAILABLE_TIMES_1: 'Ingen ledige tider',
    BOOKING_NUMBER_GUESTS: 'Antall gjester',
    BOOKING_QUESTIONS: 'Spørsmål om din bestilling?',
    BOOKING_THANKS_1: 'Takk for besøket!',
    BOOKING_THANKS_2: 'Vi sees snart igjen',
    BOOKING_TITE: 'Book bord',
    CANCEL: 'Avbryt',
    CARD_NO: 'Kortnummer',
    CANCEL_ORDER: 'Vil du kansellere bestillingen din og tømme handlekurven?',
    CART: 'Handlekurv',
    CART_ALLERGIC_CONTACT: 'Allergisk? Kontakt personalet på',
    CART_BOOK_TABLE: 'Bestille bord',
    CART_CHECK_CARD: 'Pass på at kortet ditt kan brukes til nettkjøp og at det er et tilstrekkelig beløp tilgjengelig',
    CART_CREATING_ORDER: 'Opprette rekkefølge og legge til i bestillingen',
    CART_GO_TO_MENY: 'Gå til menyen og legg inn din første bestilling',
    CART_ITEM_18Y: '18+ år og gyldig ID kreves ved kjøp av alkohol',
    CART_ITEM_ALCOHOL: 'Ingen rabatt på alkohol',
    CART_ITEM_COMMENT: 'Skriv din kommentar her',
    CART_ITEM_COMMENT_EXAMPLE: '"F.eks. ingen koriander"',
    CART_ITEM_OFFER_NOT_VALID: 'Kampanjekoden er ikke gyldig',
    CART_ITEM_REMOVE_CODE: 'Fjerne kode?',
    CART_ITEMS_APPLYING_OFFER: 'Bruke kampanjekode',
    CART_MY_BOOKING: 'Mine bestillinger',
    CART_NO_ACTIVE_TAB: 'Du har ingen aktiv fane',
    CART_ORDER_TAKEWAY: 'Bestill takeaway',
    CART_PAYMENT_ABORTED: 'Betalingen ble avbrutt',
    CART_PERSONNEL_CARD: 'Personalkort',
    CART_PREPARNG_SWISH: 'Klargjøre Vipps-betaling',
    CART_SERVE_NOW: 'Server nå',
    CART_SOME_ERROR: 'Noe gikk galt. Prøv på nytt.',
    CART_WEEK_MENU: 'Denne ukens meny',
    CART_WELCOME_OPENING_HOURS: 'Åpningstider og kontaktinformasjon',
    CART_WELCOME_READ: 'Les mer',
    CHANGE_UNIT: 'Endre enhet',
    CHARGE_ERROR: 'Noe gikk galt med betalingen din. Ta kontakt med restauranten.',
    CHARGE_SWISH_INIT: 'Be Vipps om betaling',
    CHARGE_TAB_TOAST: 'Regningen er betalt og det er opprettet en kvittering på kontoen din',
    CHARGE_VERIFY: 'Bekrefter',
    CHARGE_WAIT: 'Vennligst vent...',
    CHOOSE_RESTAURANT: 'Velg kantine',
    CHOOSE_DELIVERY_OPTION: 'Velg serveringsmetode',
    CLIENT_OPEN_HOURS: 'Åpningstider i appen',
    CLOSE: "Steng",
    CLOSED: 'Lukket',
    COMMENT: 'Kommentar',
    CONFIRM: 'Bekrefte',
    CONTINUE_SHOPPING: 'Legg til flere produkter',
    CONTACT_INFORMATION: 'Kontaktinformasjon',
    CREATE_LOGIN: 'Opprett konto / Logg inn',
    CURRENT: 'Aktuelt ',
    CUSTOM_TICKET_VALID: 'Gyldig til',
    CUSTOMER_CODE_TYPE_4_DIGITS: 'Kode',
    CUSTOMER_TAB: 'Regning',
    CUSTOMER_TAB_KEY_KOD: 'Bordnummer',
    CUSTOMER_TAB_KEY_SAME_CODE: 'Samme kode igjen? Klikk her',
    CUSTOMER_TAB_KEY_WRONG_CODE: 'Feil kod',
    CUSTOMER_TABS_ATTENTION_MSG: 'Vi hjelper deg straks ',
    CUSTOMER_TABS_CALL_WAITER: 'Ringe servitør',
    CUSTOMER_TABS_CUSTOM_AM1: 'Valgfritt beløp...',
    CUSTOMER_TABS_CUSTOM_AM2: 'Valgfritt beløp...',
    CUSTOMER_TABS_PAY_IN_APP: 'Betal i appen',
    CUSTOMER_TABS_TIPS: 'Vil du tipse våre ansatte?',
    DATE: 'Dato',
    DATE_TIME: 'Dato og klokkeslett',
    DELIVERY_ALCOHOL_DELIVERY: 'Du kan ikke bestille alkohol for levering',
    DELIVERY_ALCOHOL_TAKE_AWAY: 'Du kan ikke bestille alkohol som take away',
    DELIVERY_FETCH_BAR: 'Spis her',
    DELIVERY_INFORMATION: 'Leveringsinformasjon',
    DELIVERY_HOME_DELIVERY: 'Utlevering',
    DELIVERY_MY_TABLE: 'Server ved bordet mitt',
    DELIVERY_OFFER_ERROR: 'Rabattkoden er ikke gyldig for denne typen servering',
    DELIVERY_QUESTION: 'Hvordan vil du motta bestillingen din?',
    DELIVERY_SERVING: 'Servering',
    DELIVERY_OPTION: 'Leveringsmåte',
    DELIVERY_TO_GO: 'Ta med',
    DELIVERY_CHANGE_OPTION: 'Endre leveringsmåte',
    DISCOUNT: 'Rabatt',
    DONE: 'Klar',
    EMAIL: 'E-post',
    EMAIL_FOLLOW_INSTRUCTIONS: 'Åpne innboksen, og følg instruksjonene',
    EMAIL_SENT: 'E-post sendt',
    ENTER_CARD_NUMBER_AT_START_OF_PAYMENT: 'Kortnummer fylles ut ved start av betaling.',
    ERROR: 'Feil',
    FORWARD: 'Frem',
    FREE_CHARGE: 'Det er ikke nødvendig å betale for dette kjøpet.',
    FRI: 'Fre',
    FRIDAY: 'Fredag',
    GO_TO_CART: 'Gå til handlekurven',
    GO_TO_PAYMENT_PAY: 'Gå til betaling',
    HANDLE_NOTIFICATIONS: 'Administrere varslinger',
    HOUR: 'Time',
    HOW_TO_PAY:'hvordan vil du betale??',
    HOW_TO_PAY_SWISH:'Hvordan vil du betale med Swish?',
    INIT_SWISH_APP: 'Vi forbereder din swish betaling, et øyeblikk...',
    ITEM_DESCRIPTION_OUT_OF_STOCK: 'Utsolgt',
    ITEM_DESCRIPTION_SCROLL: 'Rull ned',
    LOCATION_ADRESS: 'Adressen din',
    LOCATION_CHOSE: 'Velg sted',
    LOCATION_COMMENT: 'Kommentar (etasje, postnummer etc.)',
    LOCATION_FEE: 'Leveringsgebyr',
    LOCATION_OTHER_ADRESS: 'Prøv en annen adresse',
    LOCATION_QUESTION: 'Levering hit?',
    LOCATION_UNFORTUNATELY_OTHER_ADRESS: 'Dessverre kan vi ennå ikke levere til denne adressen. Vennligst prøv en annen adresse. ',
    LOGGING_IN: 'Logg inn',
    LOGGING_IN_2: 'Logger inn',
    LOGIN: 'Logg inn',
    LOGIN_APPLE: 'Logg inn med Apple',
    LOGIN_CONTACTING_FACEBOOK: 'Kontakte Facebook',
    LOGIN_CREATE: 'Opprette',
    LOGIN_EMAIL: 'Logg inn med e-post',
    LOGIN_EMAIL_FORMAT: 'E-posten din er feil. Kontroller at du har skrevet inn riktig, og prøv på nytt. ',
    LOGIN_EMAIL_IN_USE: 'Denne e-posten er allerede i bruk',
    LOGIN_FACEBOOK: 'Logg inn med Facebook',
    LOGIN_FINALIZING: 'Fullfører',
    LOGIN_HI: 'Hei',
    LOGIN_NAME_NEEDED: 'Navnet ditt er nødvendig for å matche deg med bestillingene dine',
    LOGIN_NEW_USER: 'Ny bruker',
    LOGIN_NO_ACCOUNT_Q: 'Ingen konto?',
    LOGIN_PHONE: 'Logg inn med telefonnummer',
    LOGIN_PW_6: 'Passordet må inneholde minst 6 sifre',
    LOGIN_RESET: 'Tilbakestill',
    LOGIN_TERMS: 'Vilkår og betingelser',
    LOGIN_VERIFYI: 'Bekrefter',
    LOGIN_WAITING_CODE: 'Venter på kode...',
    LOGIN_WRITE_EMAIL: 'Skriv inn e-posten din',
    LOGOUT: 'Logg ut',
    ME: 'Min konto',
    MENU_DRINKS_CLOSED: 'Drinke er for øyeblikket ikke tilgjengelig',
    MENU_NO_PRODUCTS: 'Ingen produkter å vise',
    MENU_NOT_AVAILABLE: 'for øyeblikket ikke tilgjengelig',
    MENU_NOT_UPDATED: 'Det ser ut til at restauranten ennå ikke har oppdatert menyen',
    MENU_ORDER_FULL: 'Du kan ikke legge til ytterligere i din bestilling',
    MINUTE: 'Minutter',
    MON: 'Man',
    MONDAY: 'Mandag',
    MOVE:'Gå videre',
    MY_BOOKING: 'Min bestilling',
    MY_RECIEPTS: 'Mine kvitteringer',
    NAME: 'Navn',
    NEW_ORDER: 'Ny ordre',
    NO: 'Nei',
    NO_DELIVERY_OPTIONS_SELECTED: 'Du må velge leveringsmåte',
    NO_ACTIVE_ORDERS: 'Du har ingen løpende bestillinger',
    OFFERS: 'Tilbud',
    OFFERS_NONE: 'Det er for tiden ingen tilbud om å vise',
    OK: 'OK',
    OPEN: "Åpne nå",
    OPT_OUT: 'Nei takk',
    ORDER: 'Bestill',
    OR:'eller',
    ORDER_CONFIRMED: 'Din bestilling er gjennomført',
    ORDER_READY_ESTIMATION: 'Bestillingen din forventes å være klar innen:',
    ORDER_NOT_VALID: 'Ordrebeløpet er ikke gyldig',
    ORDER_TICKET: 'Orderbiljett',
    PASSWORD: "Passord",
    PAY: 'Betale',
    PAY_NOW:'Betal nå',
    PAY_WITH_CARD: 'Betal med kort',
    PAYED: 'Betalt',
    PAYMENT_ADD_CARD: 'Legg til kort',
    PAYMENT_APPLEPAY: 'Betal med Apple Pay',
    PAYMENT_BONUS: 'Bruk bonuspoeng',
    PAYMENT_DELIVERY: 'Leveringskostnader',
    PAYMENT_DEVICE_NOT_SUPPORTED: 'Enheten støttes ikke!',
    PAYMENT_DISCOUNT: 'Rabatt',
    PAYMENT_ERROR: 'Sørg for at kortet ditt kan brukes til nettkjøp og at det er et tilstrekkelig beløp tilgjengelig',
    PAYMENT_ERROR_MAINTENANCE: 'En oppdatering er i gang, og kjøpet kan ikke fullføres',
    PAYMENT_EXTRA: 'Ekstra',
    PAYMENT_FIRST_DISCOUNT: 'på vei til din første rabatt!',
    PAYMENT_INIT:'Forbereder betaling',
    PAYMENT_OFFER_ERROR_HEADER: 'Disse tilbudene kunne ikke aktiveres',
    PAYMENT_OFFER_ERROR_TEXT: 'De gjelder ikke for den serverings typen du har valgt',
    PAYMENT_PAY: 'Betale',
    PAYMENT_PAYMENTS: 'Betalingsmåte',
    PAYMENT_SAVED_CARDS: 'Lagret kort',
    PAYMENT_SCROLL: 'Rull ned for å betale',
    PAYMENT_SWISH: 'Betal med Swish',
    PAYMENT_SWISH_COMPLETE: "Fullfør betalingen i Swish-appen",
    PAYMENT_SWISH_OPEN_APP: "Åpne Swish",
    PAYMENT_SWISH_NO_APP: "Vi kan ikke finne Swish på denne enheten. <br> Bytt til å betale på en annen enhet.",
    PAYMENT_TABLETAB: 'Legg til regningen',
    PAYMENT_TITLE: 'Betal for',
    PAYMENT_TOTAL: 'Totalt',
    PAYMENT_VIPPS: 'Betal med Vipps',
    PAYMENT_VIPPS_START: "Starte betaling med Vipps?",
    PAYMENT_VIPPS_COMPLETE: "Fullfør betalingen i Vipps-appen",
    PAYMENT_VIPPS_OPEN_APP: "Åpne Vipps",
    PAYMENT_VIPPS_SHOW_ORDER: "Klikk på vis ordre.",
    PAYMENT_VIPPS_SHOW_ORDER_WHEN_PAYED: "Klikk på vis bestilling etter fullført betaling.",
    PAYMENT_VIPPS_NO_APP: "Vi kan ikke finne Vipps på denne enheten. <br> Bytt til å betale på en annen enhet.",
    PAYMENT_SWISH_INIT: "Forbereder Swish",
    PAYMENT_VERYFING: "Bekrefter ...",
    PAYMENT_GO_TO_ORDER: "Videre til bestillinger",
    PAYMENT_RETRY: "Prøv igjen",
    PAYMENT_OTHER_DEVICE: "Annen enhet",
    PAYMENT_THIS_DEVICE: "Denne enheten",
    PAYMENT_VIPPS_INIT: "Forbereder Vipps",
    PAYMENT_VIPPS_NO_DATA: "Mangler data for å kunne foreta en Vipps-betaling.",
    PAYMENT_DONE: "Klar!",
    PAYMENT_ERROR_HEADER: "Betalingsfeil",
    PAYMENT_WAIT_CONFIRM: "Venter på bekreftelse",
    PAYMENT_CONFIRMED: "Din betaling er registrert",
    PAYMENT_NOT_CONFIRMED: "Betalingen for bestillingen din er ennå ikke registrert.",
    PHONE: 'Telefon',
    PLEASE_WAIT:'Vennligst vent...',
    PREVIOUS_PURCHASES: 'Se dine tidligere kjøp',
    PURCHASE_HISTORY_Brutto: 'Brutto',
    PURCHASE_HISTORY_BUY_AT: 'Kjøp i',
    PURCHASE_HISTORY_COLLECTED_DISCOUNT: 'Samlede rabatter',
    PURCHASE_HISTORY_CONTROL_UNIT: 'K.Enhet',
    PURCHASE_HISTORY_DISCOUNT: 'Rabatt',
    PURCHASE_HISTORY_FILL_MAIL: 'Skriv inn e-postadressen din',
    PURCHASE_HISTORY_MAIL_RECIEPT: 'Send kvittering',
    PURCHASE_HISTORY_NETTO: 'Netto',
    PURCHASE_HISTORY_ORDER: 'Ordre',
    PURCHASE_HISTORY_PHONE: 'Telefon',
    PURCHASE_HISTORY_RECIEPT_NO: 'Kvitteringsnummer',
    PURCHASE_HISTORY_ROUNDING: 'Avrunding',
    PURCHASE_HISTORY_SENDING_RECIEPT: 'Sende kvittering til',
    PURCHASE_HISTORY_SENDING_SENT: 'Kvittering sendt til',
    PURCHASE_HISTORY_SUM: 'Sum',
    PURCHASE_HISTORY_TOTAL: 'Totalt',
    PURCHASE_HISTORY_VAT: 'Mva',
    PURCHASE_HISTORY_VATID: 'Mva-ID',
    PURCHASE_COMPLETE:'Du fullførte et kjøp kl',
    PURCHASE_CANCEL:'Du kansellerte et kjøp kl',
    PUSH_ALERT_BTN: 'Klikk her',
    PUSH_ALERT_TXT: 'For å se bestillingsstatusen din må du tillate push-varslinger',
    PUSH_CHANGE_SELECTION: 'Du kan alltid endre valget ditt under Konto => Push-varslinger',
    PUSH_INFO: 'Velg lokasjonene du vil få tilbud fra.',
    PUSH_PERMISSION: 'For å gjøre denne endringen, vennligst gå til telefoninnstillingene og tillat push-varslinger for appen.',
    PUSH_TITLE: 'Varsler',
    QUANTITY: 'Antall',
    RECIEPTS: 'Kvitteringer',
    RECEIVED_ORDER: 'Jeg har mottatt bestillingen min',
    RESTAURANT_INFO: 'Restaurantinformasjon',
    SAT: 'Lør',
    SATURDAY: 'Lørdag',
    SCAN_QR_WITH_SWISH:'Skann QR-koden med Swish-appen',
    SAVED_CARDS: 'Lagrede kort',
    SEARCH: 'Søk...',
    SELECT_LANGUAGE: 'Velg ditt språk',
    SEND_SMS_CODE: 'Send sms-kode',
    SENT: 'Sendt',
    STOCK_TITLE: 'Utsolgt',
    SUM: 'Sum',
    SUN: 'Søn',
    SUNDAY: 'Søndag',
    TABLENUMBER: 'Bord nummer',
    THU: 'Tors',
    THURSDAY: 'Torsdag',
    TIPS: 'Tips',
    TODAY: 'I dag',
    TUE: 'Tir',
    TUESDAY: 'Tirsdag',
    WED: 'Ons',
    WEDNESDAY: 'Onsdag',
    WEEK: 'Uke',
    WEEKLY_LUNCH: "Ukens Meny",
    WELCOME: 'Velkommen!',
    WRITE_TABLENUMBER: 'Skriv bordnummeret ditt',
    YES: 'Ja',
    YOUR_CART: 'Din handlekurv',
    HOME: 'Hjem',
    DRINKS: 'Drikke',
    FOOD: 'Mat',
    DISCOUNT_CODE:'Rabattkode',
    FOLLOW:'Følg oss',
    SAVE_CARD_QUESTION:'Legg til kort for fremtidige kjøp.',
    SAVE_CARD_QUESTION_HEADER:'Vil du lagre kortet ditt?',
    YOUR_ORDER: 'Din bestilling',
    // Reepay charge errors
    ACQUIRER_ERROR: 'Noe gikk galt med betalingen din. Ta kontakt med restauranten.',
    INSUFFICIENT_FOUNDS: 'Noe gikk galt med betalingen din. Ta kontakt med restauranten.',
    PAYMENT_NOT_AUTHORIZED: 'Noe gikk galt med betalingen din. Ta kontakt med restauranten.',
    CREDIT_CARD_EXPIRED: 'Noe gikk galt med betalingen din. Ta kontakt med restauranten.',
    DECLINED_BY_ACQUIRER: 'Noe gikk galt med betalingen din. Ta kontakt med restauranten.',
    CREDIT_CARD_LOST_OR_STOLEN: 'Noe gikk galt med betalingen din. Ta kontakt med restauranten.',
    CREDIT_CARD_SUSPECTED_FRAUD: 'Noe gikk galt med betalingen din. Ta kontakt med restauranten.',
    REFOUND_AMOUNT_TOO_HIGH: 'Noe gikk galt med betalingen din. Ta kontakt med restauranten.',
    AUTHORIZATION_EXPIRED: 'Noe gikk galt med betalingen din. Ta kontakt med restauranten.',
    AUTHORIZATION_AMOUNT_EXCEEDED: 'Noe gikk galt med betalingen din. Ta kontakt med restauranten.',
    AUTHORIZATION_VOIDED: 'Noe gikk galt med betalingen din. Ta kontakt med restauranten.',
    SCA_REQUIRED: 'Noe gikk galt med betalingen din. Ta kontakt med restauranten.',
    RISK_FILTER_BLOCK: 'Noe gikk galt med betalingen din. Ta kontakt med restauranten.',
    PAYMENT_AND_ORDER_IS_PLACED: "Din ordre er betalt og bestilt",
    DONT_CLOSE_WEB_BROWSER: "BEMÆRK! Luk ikke browseren!",
    ORDER_INFORMATION: "<p> Når ordren er gennemført, vises en dialog i browseren. </p> <p> Din kvittering findes under <b> Mine kvitteringer </b> og kan sendes til den ønskede e-mail-adresse. </p> <p> Din ordre vises under aktive ordrer, og du vil automatisk blive sendt derhen, når du bekræfter denne dialog. </p>",

    // VIPPS
    VIPPS_ERROR: "Vipps nekade din betalning.",
    VIPPS_CANCELLED: "Användare avbröt betalningen",
    VIPPS_PAYMENT_41 : "The user does not have a valid card",
    VIPPS_PAYMENT_42 : "Refused by issuer bank",
    VIPPS_PAYMENT_43 : "Refused by issuer bank because of invalid a amount",
    VIPPS_PAYMENT_44 : "Refused by issuer because of expired card",
    VIPPS_PAYMENT_45 : "Reservation failed. Either because the user cancelled, did not act, or for some unknown reason.",
    VIPPS_PAYMENT_51 : "Cannot cancel an already captured order",
    VIPPS_PAYMENT_52 : "Cancellation failed",
    VIPPS_PAYMENT_53 : "Cannot cancel an order that is not reserved",
    VIPPS_PAYMENT_61 : "Captured amount exceeds the reserved amount",
    VIPPS_PAYMENT_62 : "The amount you tried to capture is not reserved",
    VIPPS_PAYMENT_63 : "Capture failed for some unknown reason, please use /details endpoint to know the exact status",
    VIPPS_PAYMENT_71 : "Cannot refund more than captured amount",
    VIPPS_PAYMENT_72 : "Cannot refund a reserved order (only captured orders), please use the /cancel endpoint",
    VIPPS_PAYMENT_73 : "Cannot refund a cancelled order",
    VIPPS_PAYMENT_93 : "Captured amount must be the same in an Idempotent retry",
    VIPPS_PAYMENT_95 : "Payments can only be refunded up to 365 days after reservation",
    VIPPS_PAYMENT_96 : "Payments can only be captured up to 180 days after reservation",
    VIPPS_PAYMENT_1501 : "This person can not pay to companies. Used for Vipps users under the age of 15.",
    VIPPS_PAYMENT_1082 : "This person is not BankID verified. Only applies for test users.",
    VIPPS_VIPPSERROR_91: "Transaction is not allowed",
    VIPPS_VIPPSERROR_92: "Transaction already processed",
    VIPPS_VIPPSERROR_94: "Order locked and is already processing. This can occur if a bank has problems, and Vipps needs to wait and/or clean up.",
    VIPPS_VIPPSERROR_98: "Too many concurrent requests. Used only to prevent incorrect API use.",
    VIPPS_VIPPSERROR_99: "Description of the internal error",
    VIPPS_USER_81: "User not registered with Vipps",
    VIPPS_USER_82: "User app version not supported",
    VIPPS_MERCHANT_31: "Merchant is blocked because of",
    VIPPS_MERCHANT_32: "Receiving limit of merchant is exceeded",
    VIPPS_MERCHANT_33: "Number of payment requests has been exceeded",
    VIPPS_MERCHANT_34: "Unique constraint violation of the orderId",
    VIPPS_MERCHANT_35: "Registered order not found",
    VIPPS_MERCHANT_36: "Merchant agreement not signed",
    VIPPS_MERCHANT_37: "Merchant not available, deactivated or blocked. See the FAQ.",
    VIPPS_MERCHANT_38: "Sale unit is not allowed to skip the landing page",
    VIPPS_MERCHANT_21: "Reference orderId is not valid",
    VIPPS_MERCHANT_22: "Reference orderId is not in valid state",
    VIPPS_MERCHANT_97: "The merchant is not approved by Vipps to receive payments",

    // Swish
    SWISH_ERROR_FF08: 'Swish nektet betalingen.', // PaymentReference is invalid.
    SWISH_ERROR_RP03: 'Swish nektet betalingen.', // Callback URL is missing or does not use HTTPS.
    SWISH_ERROR_BE18: 'Swish nektet nummeret ditt, sørg for at du skrev inn riktig nummer.', // Payer alias is invalid.
    SWISH_ERROR_RP01: 'Swish nektet betalingen.', // Missing Merchant Swish Number.
    SWISH_ERROR_PA02: 'Swish nektet betalingen fordi beløpet ikke er gyldig.', // Amount value is missing or not a valid number.
    SWISH_ERROR_AM02: 'Swish nektet betalingen fordi beløpet er for stort.', // Amount value is too large..
    SWISH_ERROR_AM03: 'Swish avviste betalingen fordi valutaen mangler eller ikke støttes.', // Invalid or missing Currency.
    SWISH_ERROR_AM06: 'Swish nektet betalingen, fordi beløpet er mindre enn den avtalte grensen.', // Specified transaction amount is less than agreed minimum.
    SWISH_ERROR_AM21: 'Swish nektet betalingen, fordi beløpet er større enn hva banken din tillater.', // Transaction amount exceeds Swish limit agreed between bank and payer for given period. Please inform the payer to contact their bank for more information on how to adjust the Swish limits.
    SWISH_ERROR_RP02: 'Swish nektet betalingen.', // Wrong formatted message..
    SWISH_ERROR_RP06: 'Swish avviste betalingen, fordi du allerede har en annen aktiv betaling, kan du avbryte og prøve på nytt.', // A payment request already exists for that payer. Only applicable for Swish e-commerce.

    SWISH_ERROR_RF07: 'Swish nektet betalingen, sørg for at du har penger tilgjengelig for Swish.', // Transaction declined.
    SWISH_ERROR_ACMT03: 'Swish avviste betalingen fordi nummeret ditt ikke ser ut til å være aktivert for Swish.', // Payer not Enrolled.
    SWISH_ERROR_ACMT01: 'Swish nektet betalingen.', // Counterpart is not activated.
    SWISH_ERROR_ACMT07: 'Swish avviste betalingen fordi nummeret ditt ikke ser ut til å være aktivert for Swish.', // Payee not Enrolled.
    SWISH_ERROR_VR01: 'Swish avviste betalingen, fordi denne Swish-kontoen ikke oppfyller alderskravet for dette kjøpet.', // Payer does not meet age limit.
    SWISH_ERROR_VR02: 'Swish nektet betalingen', // The payer alias in the request is not enrolled in swish with the supplied ssn.
    SWISH_ERROR_TM01: 'Swish nektet betalingen, fordi du ikke fullførte betalingen i Swish innen den angitte fristen.',// Swish timed out before the payment was started
    SWISH_ERROR_BANKIDCL: 'Swish nektet betalingen, fordi du kansellerte BankID-signeringen.', // Payer cancelled BankID signing
    SWISH_ERROR_BANKIDONGOING: 'Swish nektet betalingen, fordi du allerede har en annen BankID-signering aktiv, avslutt den og prøv igjen ..', // BankID already in use.
    SWISH_ERROR_BANKIDUNKN: 'Swish nektet betalingen, fordi BankID ikke kunne godkjenne betalingen din.', // BankID is not able to authorize the payment..


    SWISH_ERROR_CONNECTION: 'Det var problemer i kontakten under betaling. Kontroller at bestillingen er bekreftet, ellers kan du bestille på nytt.',

    SWISH_CANCELLED: 'Betalingen ble kansellert.', // Unhandled error in PubQ backend
    SWISH_DECLINED: 'Betalingen ble kansellert.', // Unhandled error in PubQ backend

    SWISH_ERROR: 'Swish nektet betalingen.', // Unhandled error in PubQ backend

    SWISH_DEMAND_NUMBER: '<p> Du må oppgi et gyldig nummer for å kunne betale. </p> <p> Bestillingen din blir varslet på SMS når den er klar. </p>',

    WAITING_ON_SWISH_PAYMENT: 'Betal for bestillingen din i Swish-appen"',

    SHOW_ORDERS:'Vis bestillinger',
    // Payment V2

    VERIFY_TIMEOUT: '<p> Betalingen din ble kansellert fordi du ikke fullførte betalingen innen den angitte fristen. </p>',

    //NOT ACTIVATED COMPONENT
    UNIT_NOT_ACTIVATED: 'Denne enheten er ennå ikke aktivert for nettbestilling!',
    CONTACT_ADMIN_UNIT:'Ta kontakt med administrator for å aktivere enheten.',
    NO_CLIENT_DATA:'Ingen klientdata eller definerte enheter for klient.',

    ABLE_ORDER_ONLINE:"Kan bestilles på nett.",
    ALLOW_LOCATION:"'Vennligst gi plasseringstilgang og oppdater siden!'",
    CONTACT_SUPPORT_SWISH:"Mangler data for å kunne foreta en Swish-betaling, kontakt support.",
    CONTACT_ADMONISTRATOR:"Ta kontakt med administrator for å aktivere enheten.",
    CONTACT_SUPPORT_PAYMENT:"Mangel på data for å kunne foreta en betaling, kontakt support",
    CANCEL_SWISH_VERIFY:'Ikke avbestill hvis du har fullført betalingen. Vil du avbryte?',
    INSERT_CARD_:"Legg til kort på nytt",
    LOGIN_MOBILE_NEEDED:'Nummeret ditt er nødvendig i tilfelle vi trenger å kontakte deg',
    NEED_TO_INSERT_CARD_AGAIN:"Du må sette inn kortet på nytt for å fortsette betalingen",
    NOTE:"Merk",
    NOT_ACTICATED_FOR_WEB_ORDERING:"Denne enheten er ennå ikke aktivert for nettbestilling!",
    ORDERING_NOT_POSSIBLE:'Kan ikke bestille nå',
    PENDING_ORDER:'Ventende bestillinger',
    TO_HOMEPAGE:'Nettsted',
    UNABLE_TO_ORDER:"Kan ikke bestille online -",
    OUR_POLICY:'Vår politikk',
    PAYMENT_PLEASE_SELECT_PAYMENT_METHOD: 'Velg betalingsmetode'
}
