import { Injectable } from '@angular/core';
import * as swedish from "./languages/language.se";
import * as danish from "./languages/language.dk";
import * as english from "./languages/language.en";
import * as norwegian from "./languages/language.no";
import * as finnish from "./languages/language.fi"
import * as test from "./language.test";
@Injectable({
    providedIn: "root"
})
export class LanguageProvider {
    currentLanguage = "en";
    availableLanguages = [
        { lang: "Svenska", code: "se" },
        { lang: "English", code: "en" },
        { lang: "Dansk", code: "dk" },
        { lang: "Norsk", code: "no" },
        { lang: 'Suomi', code: "fi" }];
    constructor() {

    }
    getAvailableLanguages() {
        return this.availableLanguages;
    }
    setLanguage(languageCode: string) {
        console.log("Language set" + languageCode)
        this.currentLanguage = languageCode;
    }
    getString(translationProperty: string, languageCode?: string, parameters?: string[]) {
        if (!languageCode)
            languageCode = this.currentLanguage;
        switch (languageCode) {
            case "se": {
                if (parameters) return swedish.lang.get(translationProperty, parameters);
                else return swedish.lang[translationProperty];
            }
            case "dk": {
                if (parameters) return danish.lang.get(translationProperty, parameters);
                else return danish.lang[translationProperty];
            }
            case "en": {
                if (parameters) return english.lang.get(translationProperty, parameters);
                return english.lang[translationProperty];
            }
            case "no": {
                if (parameters) return norwegian.lang.get(translationProperty, parameters);
                else return norwegian.lang[translationProperty];
            }
            case "fi": {
                if (parameters) return finnish.lang.get(translationProperty, parameters);
                else return finnish.lang[translationProperty]
            }
            case "test": {
                if (parameters) return test.lang.get(translationProperty, parameters);
                else return test.lang[translationProperty];
            }
            default: {
                return null;
            }
        }
    }
}
