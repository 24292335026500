import { Component, Input } from "@angular/core";
import { AlertController, LoadingController, ModalController, Platform } from "@ionic/angular";
import { LanguagePipe } from "src/app/pipes/language.pipe";
import { CartProvider } from "src/app/services/cart/cart.service";
import { EventsProvider } from "src/app/services/events/events.provider";
import { LanguageProvider } from "src/app/services/language/language.provider";
import { Order } from "src/app/services/order/order";
import { PaymentV2Response } from "src/app/services/payment/payment";
import { PaymentUtils } from "src/app/services/payment/paymentUtils";
import { CardPaymentProvider } from "src/app/services/payment/cardPaymentProvider";
import { User, UserPaymentSource, UserProvider } from "src/app/services/user/user";
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { environment } from "src/environments/environment";

let clientSettings = require("../../../../cordova-scripts/clientsettings");

@Component({
    selector: "pay-card",
    styleUrls: ["card-dialog.component.scss"],
    templateUrl: "card-dialog.component.html",
})
export class CardDialogComponent {
    @Input() order: Order
    @Input() paymentOptions: { user: any, paymentSource: any, order: Order }
    @Input() phone: string
    @Input() user: User
    @Input() routeName: string = ''
    @Input() provider: string;
    @Input() options: any;
    idempotencyKey: string = ''
    openedPaymentProvider = false;
    paymentRequestToken
    verifying = false;
    preparing = false;
    selectedPaymentMethod: any;
    processingPayment = false;
    lastResponse: PaymentV2Response;

    payOnThisDevice = true;
    payOnOtherDevice = false;
    paying = false;
    paymentStarted = false;
    showOpenApp = false;
    paymentInitDone = false;
    stripe: Stripe;
    elements: StripeElements;
    completed = false;
    verifyUrl: '';
    reverify = false;

    constructor(
        private modalController: ModalController,
        private platform: Platform,
        private cardPaymentProvider: CardPaymentProvider,
        private paymentUtils: PaymentUtils,
        private alertCtrl: AlertController,
        public languageProvider: LanguageProvider,
        public languagePipe: LanguagePipe,
        private cartProvider: CartProvider,
        private eventsProvider: EventsProvider,
        private userProvider: UserProvider,
        private loadingController: LoadingController
    ) {

        this.idempotencyKey = this.cartProvider.idempotencyKey;
    }

    getStripeLocalization() {
        switch (this.languageProvider.currentLanguage) {
            case "se": return "sv";
            case "en": return "en";
            case "dk": return "da";
            case "no": return "no";
            case "fi": return "fi";
            default: return "en";
        }
    }

    async ngOnInit() {
        this.idempotencyKey = this.cartProvider.idempotencyKey;

        if (environment.production) {
            if (this.routeName.toLowerCase().startsWith('compassno')) {
                this.stripe = await loadStripe('pk_live_51JVc4pGCW5xBSesb1vIptWD5htA6e9I2BAy8MH1f9xp71pDGFJi6jr4EqHd5EXEpyaFUm1Su6bZYS8LJRKOQnIRI00Or7zKCGe', { locale: this.getStripeLocalization() });
            } else if (this.routeName.toLowerCase().startsWith('compassfi')) {
                this.stripe = await loadStripe('pk_live_51JY8wBDteMRPi6HzHwDKoQpwO8yNq0IdrD8WKt6rFr1aAGxmUiPNaeDXJnv7KxzTob0Yz2p9GSA1NXDKmq1TyYqE00hWWH5dc2', { locale: this.getStripeLocalization() });
            }
            else {
                this.stripe = await loadStripe('pk_live_51JSHabGlIvNp65oWDURruQBuvyg53cYGAdq02GypnJxCGhYWcy9JOY3WBcqZOXSmuXLjfEy0iPR7lcwPRGkU2Rw700XbGQLWv6', { locale: this.getStripeLocalization() });
            }
        } else {
            this.stripe = await loadStripe('pk_test_51JPpNiIgbQXthjA09Ya1IOGxw53XcjyXSEv4fA7vIHxXgP3yRv8MN7bmchZqzmgx8ZnBDYhOvEXu7S5ZJwmaPyH300vcjDuFWQ', { locale: this.getStripeLocalization() });
        }

        if (!this.order || !this.user || !this.routeName || !this.idempotencyKey) {
            alert(this.languagePipe.transform("Saknar data för att kunna göra en betalning, kontakta supporten.", 'CONTACT_SUPPORT_PAYMENT',
                this.languageProvider.currentLanguage));
            this.cancel();
        }


    }
    ngAfterViewInit() {
        this.selectedPaymentMethod = this.options;
        this.pay();
    }

    getCardSource(option: UserPaymentSource) {
        let brand = option.type.split("+")[0];
        if (brand) {
            brand = brand.toLowerCase();
            if (brand.indexOf("visa") > -1) return "./assets/icons/visa.svg";
            if (brand.indexOf("master") > -1) return "./assets/icons/mastercard.svg";
            return "./assets/icons/genericcard.svg";
        }
    }

    pay() {
        if (this.selectedPaymentMethod.type === 'newcard' || this.selectedPaymentMethod.type === "mobilepay") {
            this.payWithNewCard('stripe');
        } else {
            this.payWithExistingCard('stripe', this.selectedPaymentMethod)
        }
    }


    async payWithExistingCard(paymentProvider: "stripe", option) {
        this.eventsProvider.logEvent("USER_PAYING_WITH_STORED_CARD", { user: this.user, paymentProvider, orderId: this.cartProvider.orderId });

        this.preparing = true;
        this.paying = true;
        this.lastResponse = null;
        this.paymentStarted = true;

        let clientContextData = {
            user: {
                id: this.order.userId,
                isAnonymous: true,
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                deviceToken: null,
                email: this.user.email,
                preferredLocale: "se",
                origin: this.routeName,
            },
            origin: window.location.origin,
            locale: "se",
            appVersion: "web_booking@2.0.0",
            appProtocol: ``
        }

        let _options: any = {
            paymentSource: option,
            order: this.order,
            clientContextData: clientContextData,
            user: this.user
        }

        let response = await this.cardPaymentProvider.initiate(_options, this.idempotencyKey, this.order.id);
        this.preparing = false;
        this.completed = true;
        // Charge payment if init was a success
        if (response && response.success) {
            if (response.providerData.status == 'requires_action') {
                this.preparing = false;
                if (response.providerData.next_action.type === 'redirect_to_url') {
                    // this.reverify = true;
                    // setTimeout(() => {
                    //     this.verifyUrl = response.providerData.next_action.redirect_to_url.url;
                    // },20)

                    const alert = await this.alertCtrl.create({
                        header: this.languagePipe.transform('Lägga in kort på nytt', 'INSERT_CARD', this.languageProvider.currentLanguage),
                        message: this.languagePipe.transform('Du behöver lägga in kort på nytt för att fortsätta betalningen',
                            'NEED_TO_INSERT_CARD_AGAIN', this.languageProvider.currentLanguage),
                        buttons: [
                            {
                                text: this.languagePipe.transform("Ok", 'OK', this.languageProvider.currentLanguage),
                                handler: () => {
                                    this.userProvider.removePaymentSource(this.user, option);
                                    this.clearSelection();
                                    this.selectedPaymentMethod = 'newcard';
                                    this.payWithNewCard("stripe");
                                }
                            }
                        ]
                    });
                    await alert.present();

                }
            } else {
                this.lastResponse = response;
                this.verify();
            }
        } else {
            this.handleResponse(response);
        }
    }



    async payWithNewCard(paymentProvider: "stripe") {
        this.eventsProvider.logEvent("USER_STARTING_TO_ADD_CARD", { user: this.user, paymentProvider, orderId: this.cartProvider.orderId });

        var cardIntent: UserPaymentSource = this.selectedPaymentMethod;

        this.preparing = true;
        this.openedPaymentProvider = false;
        this.completed = false;
        this.lastResponse = null;
        this.paymentStarted = true;

        let clientContextData = {
            user: {
                id: this.order.userId,
                isAnonymous: true,
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                deviceToken: null,
                email: this.user.email,
                preferredLocale: "se",
                origin: this.routeName,
            },
            origin: window.location.origin,
            locale: "se",
            appVersion: "web_booking@2.0.0",
            appProtocol: ``
        }

        let _options: any = {
            paymentSource: cardIntent,
            order: this.order,
            clientContextData: clientContextData,
            user: this.user
        }

        let loading = await this.loadingController.create({
            spinner: "crescent",
            duration: 10000
        });
        loading.present();
        let response = await this.cardPaymentProvider.initiate(_options, this.idempotencyKey, this.order.id);
        loading.dismiss();
        // Charge payment if init was a success
        if (response && response.success) {
            this.preparing = false;

            setTimeout(() => {
                this.elements = this.stripe.elements({
                    appearance: { theme: 'stripe' }, clientSecret: response.providerData.client_secret
                });
                const paymentElement = this.elements.create("payment", { layout: { type: "tabs", defaultCollapsed: false } });
                paymentElement.mount("#payment-element");
                paymentElement.once('ready', () => {
                    this.openedPaymentProvider = true;
                }
                );
            }, 10);

        } else {
            this.handleResponse(response);
        }
    }

    getIconFromResponse(response: PaymentV2Response) {
        if (!response) return ""

        if (response.success) {
            return "checkmark-circle-outline"
        } else if (this.hasError(response)) {
            return "warning-outline"
        } else { // Not verified yet
            return "hourglass-outline"
        }
    }

    getHeaderFromResponse(response: PaymentV2Response) {
        if (!response) return ""

        if (response.success) {
            return this.languagePipe.transform("Klart!", 'PAYMENT_DONE', this.languageProvider.currentLanguage)
        }
        else if (this.hasError(response)) {
            return this.languagePipe.transform("Betalningsfel", 'PAYMENT_ERROR_HEADER', this.languageProvider.currentLanguage)
        }
        else { // Not verified yet
            return this.languagePipe.transform("Väntar på bekräftelse", 'PAYMENT_WAIT_CONFIRM', this.languageProvider.currentLanguage)
        }
    }

    getColorFromResponse(response: PaymentV2Response) {
        if (!response) return "primary"

        if (response.success) {
            return "success"
        } else if (response.error && response.error.responseText) {
            return "danger"
        } else { // Not verified yet
            return "primary"
        }
    }

    getTextFromResponse(response: PaymentV2Response) {
        if (!response) return ""

        if (response.success) {
            return this.languagePipe.transform("Din betalning är registrerad", 'PAYMENT_CONFIRMED', this.languageProvider.currentLanguage)
        } else if (response.error && response.error.responseText) {
            return this.languagePipe.transform(response.error.responseText, response.error.languageCode, this.languageProvider.currentLanguage)
        } else { // Not verified yet
            return this.languagePipe.transform("Betalningen för din order har inte registrerats ännu.", 'PAYMENT_NOT_CONFIRMED', this.languageProvider.currentLanguage)
        }
    }

    retry() {
        this.clearSelection();
        this.cartProvider.updateIdempotencyKey();
        this.idempotencyKey = this.cartProvider.idempotencyKey;
        this.paymentUtils.cancelRetrying();
        this.pay();
    }


    async verify() {
        this.paymentUtils.cancelRetrying();
        this.verifying = true;
        let response = await this.cardPaymentProvider.verify(this.idempotencyKey, this.routeName);
        this.reverify = false;
        this.verifyUrl = '';
        this.lastResponse = response;
        console.dir(response)
        this.verifying = false;
        if (response.success) {
            setTimeout(() => {
                this.modalController.dismiss(response, "success")
            }, 1500);
        } else if (response.error) {
            this.handleResponse(response);
        }
    }


    hasError(lastResponse: PaymentV2Response) {
        if (lastResponse && lastResponse.error) {
            // Chaeck that the error is not that verify has timed out or lost connection
            if (lastResponse.error.responseText && lastResponse.error.languageCode !== 'SWISH_CANCELLED')
                return true;
        }

        return false;
    }


    handleResponse(response: PaymentV2Response) {
        if (response.success) {
            this.modalController.dismiss(response, "success")
        } else if (response.error && response.error.responseText) {
            this.clearSelection();
            this.lastResponse = response;
            //alert(response.error.responseText)
        } else {
            this.clearSelection();
        }
    }

    clearSelection() {
        this.paymentStarted = false;
        this.showOpenApp = false;
        this.openedPaymentProvider = false;
        this.paymentRequestToken = null;
        this.verifying = false
        this.preparing = false;
    }

    async verifyAndCancel() {
        const alert = await this.alertCtrl.create({
            header: this.languagePipe.transform("Avbryt", 'CANCEL', this.languageProvider.currentLanguage),
            message: this.languagePipe.transform('Avbryt inte om du genomfört betalningen. Vill du avbryta?', 'CANCEL_SWISH_VERIFY', this.languageProvider.currentLanguage),
            buttons: [
                {
                    text: this.languagePipe.transform("Nej", 'NO', this.languageProvider.currentLanguage),
                    role: 'cancel'
                },
                {
                    text: this.languagePipe.transform("Ja", 'YES', this.languageProvider.currentLanguage),
                    handler: () => {
                        this.cancel();
                    }
                }
            ]
        });

        await alert.present();
    }


    async submit() {
        this.processingPayment = true;
        let returnUrl = environment.production ? `https://web.pubq.se/u/${this.routeName}?showActiveOrder=true` : `https://test.pubq.se/u/${this.routeName}?showActiveOrder=true`;
        let result = await this.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: {
                return_url: returnUrl
            },
            redirect: 'if_required'
        });
        this.processingPayment = false;
        console.dir(result);
        if (!result.error) {
            this.completed = true;
            this.lastResponse = {
                idempotencyKey: '',
                success: true
            };
            setTimeout(() => {
                this.modalController.dismiss(result, "success")
            }, 1500);
        }
    }


    select(option) {
        this.selectedPaymentMethod = option;
    }

    cancel() {
        this.clearSelection();
        this.paymentUtils.cancelRetrying();
        this.modalController.dismiss(null, "cancel")
    }
}
