import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { database, AppConfiguration } from './app.configuration';

// import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { HttpClientModule } from '@angular/common/http';
import { DeliveryPlacePage } from './delivery-place/delivery-place';
import { ChargeCustomerComponent } from './payment/chargeCustomer.component';
import { PaymentPage } from './payment/payment';
import { CustomerTabKeyPage } from './cart/customer-tab-key/customer-tab-key';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// import { PurchaseHistoryListViewPage } from './account/purchaseHistory/purchaseHistoryListView';
// import { PurchaseHistoryDetailsViewComponent } from './account/purchaseHistory/purchaseHistoryListDetails';
import 'gl-ionic-background-video';
import { SplashScreenComponent } from './splash-screen/splashScreen';
import { FilterPipe } from './pipes/filter.pipe';
import { UnitSelectionComponent } from './unit-selection/unitSelection.component';
import { LocationSorter } from './services/locationSorter';
import { AddCardComponent } from './payment/add-card/addCard.component';
import * as firebase from 'firebase/app';
import 'firebase/functions';
import { AgmCoreModule } from '@agm/core';
import { BonusProvider } from './services/bonus/bonus';
import { IonicStorageModule } from '@ionic/storage';
import { DeliverToLocationComponent } from './delivery-place/deliver-to-location/deliverToLocation.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { SharedComponentsModule } from './sharedComponents.module';
import { PaymentConfirmationComponent } from './payment/paymentConfirmation/paymentConfirmation.component';
import { CartPage } from './cart/cart';

import { MenuPage } from './menu/menu';
import { MenuItemDescriptionComponent } from './menu/itemDescription';
import { ClientInfoComponent } from './client-info/clientInfo.component';
import { AngularFireFunctions } from '@angular/fire/functions';
import { NoRoutePage } from './no-route/no-route.page';
import { ShrinkingSegmentHeader } from './menu/shrinkingSegmentHeader/shrinking-segment-header';
import { ParallaxHeaderDirective } from './directives/parallax-header.directives';
import { PurchaseHistoryListViewPage } from './account/purchaseHistory/purchaseHistoryListView';
import { PurchaseHistoryDetailsViewComponent } from './account/purchaseHistory/purchaseHistoryListDetails';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { NotActivated } from './notActivated/notActivated.component';
import { PhoneNumberInput } from './inputs/phone-number-input/phoneNumberInput.component';
import { SelectDeliveryPlaceModal } from './side-menu/select-delivery-place/selectDeliveryPlace';
import { PaymentStatusComponent } from './payment/paymentStatus/paymentStatus.component';

import { CardDialogComponent } from './payment/card-dialog/card-dialog.component';
import { VippsDialogComponent } from './payment/vipps-dialog/vipps-dialog.component';
import { SelectPaymentOptionComponent } from './payment/selectPaymentOption/selectPaymentOption.component';


@NgModule({
    declarations: [
        AppComponent,
        PurchaseHistoryListViewPage,
        PurchaseHistoryDetailsViewComponent,
        DeliveryPlacePage,
        DeliverToLocationComponent,
        ChargeCustomerComponent,
        PaymentConfirmationComponent,
        PaymentPage,
        SelectPaymentOptionComponent,
        CustomerTabKeyPage,
        SplashScreenComponent,
        FilterPipe,
        UnitSelectionComponent,
        NoRoutePage,
        AddCardComponent,
        CartPage,
        MenuPage,
        MenuItemDescriptionComponent,
        ClientInfoComponent,
        ShrinkingSegmentHeader,
        SideMenuComponent,
        ParallaxHeaderDirective,
        NotActivated,
        PhoneNumberInput,
        SelectDeliveryPlaceModal,
        PaymentStatusComponent,
        VippsDialogComponent,
        CardDialogComponent        
    ],
    entryComponents: [
        SplashScreenComponent,
        AddCardComponent,
        MenuPage,
        MenuItemDescriptionComponent,
        ClientInfoComponent,
        PurchaseHistoryListViewPage,
        SelectPaymentOptionComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios',
            swipeBackEnabled: false
        }),
        AppRoutingModule,
        AngularFireModule.initializeApp(database),
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDI4oQzwwLesou5iVzyT95Gu6WyEaww06E',
            libraries: ['places'],
            language: 'sv',
            region: 'SE'
        }),
        IonicStorageModule.forRoot(),
        SharedComponentsModule,
        HammerModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        LocationSorter,
        FilterPipe,
        AngularFireFunctions,
        { provide: BonusProvider, useClass: BonusProvider }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],

    bootstrap: [AppComponent]
})
export class AppModule { }
if (AppConfiguration.firebaseProject) {
    firebase.initializeApp(AppConfiguration.firebaseProject, 'clientSpecificProject');
}
